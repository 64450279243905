import {
  ConfigProvider,
  Input,
  Button,
  Select,
  Form,
  Typography,
  Modal,
  notification,
  Checkbox,
  Tooltip,
  DatePicker,
  Radio,
  InputNumber,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { PlusCircleOutlined } from "@ant-design/icons";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "react-phone-number-input/style.css";
import moment from "moment";
import dayjs from "dayjs";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import {
  getPhoneError,
  validateEmailOnBlur,
  validateFirstNameOnBlur,
  validateFirstNameOnChange,
  validateLastNameOnBlur,
  validateLastNameOnChange,
  validateProjectNameOnBlur,
  validateProjectNameOnChange,
} from "../../../utils/validation.js";
import { ImageUpload } from "../../../components/image-upload/ImageUpload";
import { disabledDate } from "../../../utils/convertSlotToString.jsx";
import { PageContent } from "../../../components/page-content/PageContent";
import { Header } from "../../../components/header/Header";
import { isMaxCharCrossed } from "../../../utils/validations.js";
import { useGetApi } from "../../../hooks/useGetApi.jsx";
import { usePostApi } from "../../../hooks/usePostApi.jsx";
import { usePutApi } from "../../../hooks/usePutApi.jsx";
import { LOCATION, VALIDATE_TRIGGERS } from "../../../utils/constants";

const validityCurrency = (_, value) => {
  if (!value || value?.length === 0) {
    return Promise.reject("Please select the currency!");
  }

  return Promise.resolve();
};
const validateNumber = (rule, value, callback) => {
  if (value === undefined) {
    // No value entered yet, no validation needed.
    callback();
  } else if (isNaN(value)) {
    callback("Input should be a Valid Number");
  } else if (!/^\d{1,3}$/.test(value)) {
    callback("Please enter a number between 1 and 999");
  } else {
    callback();
  }
};
const validityTenatativeDate = (_, value) => {
  if (!value || value?.length === 0) {
    return Promise.reject("Please select the Start Date!");
  }

  return Promise.resolve();
};

// const validateProjectNameOnBlur = (_, value) => {
//   if (!value?.trim()) {
//     return Promise.reject("Please enter project name");
//   }
//   if (value.trim().length < 3) {
//     return Promise.reject("Project name must be at least 3 characters long");
//   }
//   if (isMaxCharCrossed(value, 20)) {
//     return Promise.reject("Characters can not be more than 20");
//   }
//   return Promise.resolve();
// };

const validateOrganizationNameOnBlur = (_, value) => {
  if (!value) {
    return Promise.reject(
      "Please select organisation or add new organisation by clicking on +"
    );
  }
  if (value.length < 3) {
    return Promise.reject(
      "Organization name must be at least 3 characters long"
    );
  }
  if (isMaxCharCrossed(value, 30)) {
    return Promise.reject("Characters can not be more than 30");
  }
  return Promise.resolve();
};

const validateOrganizationNameOnChange = (_, value) => {
  if (value && isMaxCharCrossed(value, 30)) {
    return Promise.reject("Characters can not be more than 30");
  }

  return Promise.resolve();
};

const validateHrNameOnChange = (_, value) => {
  if (value && isMaxCharCrossed(value, 30)) {
    return Promise.reject("Characters can not be more than 30");
  }
  return Promise.resolve();
};

const validateOrganisationOnChange = (_, value) => {
  // if (!value) {
  //   return Promise.reject("Please enter organisation name");
  // }
  // if (value && value.length < 3) {
  //   return Promise.reject("Organisation name must be at least 3 characters long");
  // }
  if (value && isMaxCharCrossed(value, 30)) {
    return Promise.reject("Characters can not be more than 30");
  }

  const regex = /^[\w.&,+\- ]+$/;

  if (value && !regex.test(value)) {
    return Promise.reject(
      "Organisation name can only contain  -, +, &, dot and comma."
    );
  }

  return Promise.resolve();
};

const validateOrganisationOnBlur = (_, value) => {
  if (!value) {
    return Promise.reject("Please enter organisation name");
  }
  if (value.trim().length < 3) {
    return Promise.reject(
      "Organisation name must be at least 3 characters long"
    );
  }
  if (isMaxCharCrossed(value, 30)) {
    return Promise.reject("Characters can not be more than 30");
  }
  return Promise.resolve();
};

const validateHrFirstNameOnBlur = (_, value) => {
  if (!value) {
    return Promise.reject("Please enter first name");
  }
  if (value.length < 3) {
    return Promise.reject("First name must be at least 3 characters long");
  }
  if (isMaxCharCrossed(value, 30)) {
    return Promise.reject("Characters can not be more than 30");
  }
  return Promise.resolve();
};
const validateHrLastNameOnBlur = (_, value) => {
  if (!value) {
    return Promise.reject("Please enter last name");
  }
  if (value.length < 3) {
    return Promise.reject("Last name must be at least 3 characters long");
  }
  if (isMaxCharCrossed(value, 30)) {
    return Promise.reject("Characters can not be more than 30");
  }
  return Promise.resolve();
};

export const ProjectCreate = () => {
  const [form] = Form.useForm();
  const [addOrganisationForm] = Form.useForm();
  const [addHrForm] = Form.useForm();
  const [isModalOpenOrganisation, setIsModalOpenOrganisation] = useState(false);
  const [isModalOpenHr, setIsModalOpenHr] = useState(false);
  const [isModalOpenConfirmation, setIsModalOpenConfirmation] = useState(false);
  const [OrganisationImage, setOrganisationImage] = useState(false);
  const [hrOptions, setHrOptions] = useState([]);

  const [mode, setMode] = useState("face to face");
  const [isEmpanelment, setIsEmpanelment] = useState(false);
  const [isInterviewAllowed, setIsInterviewAllowed] = useState(false);
  const [isSpecificCoach, setIsSpecificCoach] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const project = location.state?.project;
  const [startDate, setStartDate] = useState(
    moment("2023-09-27", "YYYY-MM-DD")
  );
  useEffect(() => {
    if (project) {
      form.setFieldsValue({
        projectName: project?.projectName,
        organizationName: project?.organisation.id,
        hr: project?.hr.map((hrObj) => hrObj.id), // Assuming hr is an array of HR emails
        start_date: dayjs(project?.tentative_start_date),
        // , "YYYY-MM-DD")._d, // Assuming startDate is a date
        location: mode !== "online" ? project?.location : "",
        // location: project?.location, // Assuming location is an array of locations
        mode: project?.mode,
        approx_coachee: project?.approx_coachee,
        frequency_of_session: project?.frequency_of_session,
        projectDescription: project?.project_description,
      });
      setMode(project?.mode);
    }
  }, []);
  // Loop through the properties of the project and add them to initialValues

  const handleCheckboxChange = (checked, name) => {
    switch (name) {
      case "isEmpanelment":
        setIsEmpanelment(checked);
        if (checked) {
          setIsInterviewAllowed(true);
          setIsSpecificCoach(false);
        } else {
          form.setFieldValue(false);
        }
        break;
      case "isInterviewAllowed":
        setIsInterviewAllowed(checked);
        if (checked) {
          setIsSpecificCoach(false);
          setIsEmpanelment(true);
        } else {
          form.setFieldValue(false);
        }
        break;
      case "isSpecificCoach":
        setIsSpecificCoach(checked);
        if (checked) {
          setIsEmpanelment(false);
          setIsInterviewAllowed(false);

          form.setFieldValue("isInterviewAllowed", false);
          form.setFieldValue("isEmpanelment", false);
        } else {
          form.setFieldValue(false);
        }
        break;
      default:
        break;
    }
  };
  const projectTypeOptions = [
    { value: "caas", label: "Create Coaching As A Service (CAAS) Project" },
  ];
  // const checkBlur () => {
  //   (isSpecificCoach== checked ? isEmpanelment== disabled && isInterviewAllowed== disabled : " " )
  // };

  // const validateProjectNameOnChange = (_, value) => {
  //   if (value && isMaxCharCrossed(value, 20)) {
  //     return Promise.reject("Characters can not be more than 20");
  //   }
  //   return Promise.resolve();
  // };

  const validateHrSelectOnBlur = (_, value) => {
    if (!value || value.length === 0) {
      return Promise.reject("Please select HR or add new HR by clicking on +");
    }
    // if (value.length === 0) {
    //   return Promise.reject("Please select HR or add new HR by clicking on +");
    // }
    return Promise.resolve();
  };

  const validateHrSelectOnChange = (_, value) => {
    // if (value && isMaxCharCrossed(value, 20)) {
    //   return Promise.reject("Characters can not be more than 20");
    // }
    return Promise.resolve();
  };

  const navigate = useNavigate();

  const {
    data: createProjectData,
    isLoading: createProjectLoading,
    postData: createProject,
    resetState: createProjectResetState,
  } = usePostApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/create-project-schedular/`
  );

  const {
    data: editProjectData,
    isLoading: editProjectLoading,
    putData: editProject,
    resetState: editProjectResetState,
  } = usePutApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/edit-project-caas/${project?.id}/`
  );

  const {
    data: addHrData,
    isLoading: addHrLoading,
    postData: addHR,
    resetState: addHrResetState,
  } = usePostApi(`${process.env.REACT_APP_BASE_URL}/api/add_hr/`);

  const {
    data: getHrData,
    isloading: getHrLoading,
    error: getHrError,
    getData: getHr,
  } = useGetApi(`${process.env.REACT_APP_BASE_URL}/api/hr/all/`);

  const {
    data: getOrganisationData,
    isloading: getOrganisationLoading,
    error: getOrganisationError,
    getData: getOrganisations,
  } = useGetApi(`${process.env.REACT_APP_BASE_URL}/api/get_organisation/`);

  const handleAddProject = () => {
    const values = form.getFieldsValue();

    createProject({
      project_name: values.projectName,
      organisation_name: values.organizationName,
      hr: values.hr,
      interview_allowed: values.isInterviewAllowed
        ? values.isInterviewAllowed
        : false,
      specific_coach: values.isSpecificCoach ? values.isSpecificCoach : false,
      empanelment: values.isEmpanelment ? values.isEmpanelment : false,
      project_type: "caas",
      // tentative_start_date: moment(values.start_date.$d).format("YYYY-MM-DD"),
      mode: values.mode,
      // location: values.location ? values.location : "",
      location: JSON.stringify(values.location ? values.location : []),
      sold: true,
      approx_coachee: values.approx_coachee ? values.approx_coachee : "",
      frequency_of_session: values.frequency_of_session
        ? values.frequency_of_session
        : "",
      project_description: values.projectDescription
        ? values.projectDescription
        : "",
    });
  };

  const handleEditProject = () => {
    const values = form.getFieldsValue();

    editProject({
      project_name: values.projectName,
      organisation_id: values.organizationName,
      hr: values.hr,
      interview_allowed: values.isInterviewAllowed
        ? values.isInterviewAllowed
        : false,
      specific_coach: values.isSpecificCoach ? values.isSpecificCoach : false,
      empanelment: values.isEmpanelment ? values.isEmpanelment : false,
      project_type: "caas",
      // tentative_start_date: moment(values.start_date.$d).format("YYYY-MM-DD"),
      mode: values.mode,
      // location: values.location ? values.location : "",
      location: JSON.stringify(values.location ? values.location : []),
      sold: true,
      approx_coachee: values.approx_coachee ? values.approx_coachee : "",
      frequency_of_session: values.frequency_of_session
        ? values.frequency_of_session
        : "",
      project_description: values.projectDescription,
    });
  };
  useEffect(() => {
    if (editProjectData) {
      navigate("/projects");
    }
  }, [editProjectData]);

  const handleAddHr = (values) => {
    addHR({
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email.trim(),
      phone: phone,
      organisation: values.organisation,
    });
  };

  const handlecreateOrganisation = (values) => {
    const existingOrg = getOrganisationData.filter(
      (item) =>
        item.name.toLowerCase().trim() ===
        values.organisationName.toLowerCase().trim()
    );
    if (existingOrg?.length > 0) {
      return notification.error({
        message: "Organisation with this name already exists.",
      });
    }

    let formData = new FormData();
    formData.append("name", values.organisationName);
    // only updating pictures if new profile pic is added
    if (OrganisationImage && typeof OrganisationImage !== "string") {
      formData.append("image_url", OrganisationImage);
    }
    let url = `${process.env.REACT_APP_BASE_URL}/api/add_organisation/`;
    axios
      .post(url, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        notification.success({ message: "Organisation added successfully." });
        setIsModalOpenOrganisation(false);
        addOrganisationForm.resetFields();
        getOrganisations();
      })
      .catch((err) => err);
  };

  let organisationOptions = [];
  if (getOrganisationData) {
    organisationOptions = getOrganisationData.map((organisation) => ({
      value: organisation.id,
      label: organisation.name,
      // + " " + organisation.image_url,
    }));
  }

  const showModalOrganisation = () => {
    setIsModalOpenOrganisation(true);
  };

  const handleOkOrganisation = () => {
    setIsModalOpenOrganisation(false);
  };

  const handleCancelOrganisation = () => {
    setIsModalOpenOrganisation(false);
    addOrganisationForm.resetFields();
  };

  const showModalHr = () => {
    setIsModalOpenHr(true);
  };

  const handleOkHr = () => {
    setIsModalOpenHr(false);
  };

  const handleCancelHr = useCallback(() => {
    setIsModalOpenHr(false);
    setPhone(null);
    setPhoneError(null);
    addHrForm.resetFields();
  }, [addHrForm]);

  const showModalConfirmation = () => {
    setIsModalOpenConfirmation(true);
  };
  const handleOkConfirmation = () => {
    // setCurrent3((prev) => prev + 1)
    setIsModalOpenConfirmation(false);
    if (project) {
      handleEditProject(true);
    } else {
      handleAddProject(true);
    }
  };
  const handleCancelConfirmation = () => {
    setIsModalOpenConfirmation(false);
  };

  useEffect(() => {
    if (createProjectData) {
      // notification.success({
      //   message: "Project created succesfully!",
      // });
      form.resetFields();
      createProjectResetState();
      navigate(`/projects`);
    }
  }, [createProjectData, form, createProjectResetState, navigate]);

  useEffect(() => {
    if (addHrData) {
      // notification.success({ message: "HR added succesfully" });
      addHrResetState();
      getHr();
      handleCancelHr();
    }
  }, [addHrData, handleCancelHr, getHr, addHrResetState]);

  const handleOrganisationChange = (value) => {
    const hrId = form.getFieldValue("hr");
    if (hrId || hrId === 0) {
      form.setFieldValue("hr", []);
    }

    let filteredHrOptions = [];
    getHrData?.forEach((hr) => {
      if (hr.organisation.id === value) {
        filteredHrOptions.push({
          value: hr.id,
          label: hr.email,
        });
      }
    });
    setHrOptions(filteredHrOptions);
  };

  const approxCoacheeOptions = [
    {
      label: "10",
      value: "10 ",
    },
    {
      label: "20",
      value: "20",
    },
    {
      label: "30",
      value: "30",
    },
    {
      label: "40",
      value: "40",
    },
    {
      label: "50",
      value: "50",
    },
    {
      label: "> 50",
      value: "> 50",
    },
    {
      label: "> 100",
      value: "> 100",
    },
  ];

  const Frequency_Of_Sessions = [
    {
      label: "Once in a week",
      value: "Once in a week",
    },
    {
      label: "Once in two weeks",
      value: "Once in two weeks",
    },
    {
      label: "Once in a month",
      value: "Once in a month",
    },
    {
      label: "Once in two months",
      value: "Once in two months",
    },
  ];

  const [phone, setPhone] = useState();
  const [phoneError, setPhoneError] = useState();

  const validatePhone = () => {
    setPhoneError(getPhoneError(phone));
  };

  useEffect(() => {
    if (getHrData) {
      const organisation = form.getFieldValue("organizationName");
      let filteredHrOptions = [];
      getHrData?.forEach((hr) => {
        if (hr.organisation.id === organisation) {
          filteredHrOptions.push({
            value: hr.id,
            label: hr.email,
          });
        }
      });
      setHrOptions(filteredHrOptions);
    }
  }, [getHrData]);

  return (
    <>
      <Header>Create Skill Training Project</Header>
      <PageContent>
        <div className="w-[50%] mt-[2rem] p-5 bg-gray-100 rounded-lg">
          <Form
            form={form}
            className="max-w-[54rem] mt-8"
            layout="vertical"
            onFinish={showModalConfirmation}
            initialValues={{ isChemistryAllowed: true, mode: "face to face" }}
          >
            <div>
              <Form.Item
                name="projectName"
                label="Project Name"
                validateTrigger={VALIDATE_TRIGGERS}
                rules={[
                  {
                    validator: validateProjectNameOnChange,
                    validateTrigger: "onChange",
                  },
                  {
                    validator: validateProjectNameOnBlur,
                    validateTrigger: "onBlur",
                  },
                ]}
                required
              >
                <Input maxLength={51} placeholder="Project Name" />
              </Form.Item>

              <Form.Item
                name="organizationName"
                label={
                  <>
                    Organization Name{" "}
                    <Tooltip title="Click here to add organisation!">
                      <PlusCircleOutlined
                        onClick={showModalOrganisation}
                        className="ml-[.3rem]"
                      />
                    </Tooltip>
                  </>
                }
                validateTrigger={VALIDATE_TRIGGERS}
                rules={[
                  {
                    validator: validateOrganizationNameOnChange,
                    validateTrigger: "onChange",
                  },
                  {
                    validator: validateOrganizationNameOnBlur,
                    validateTrigger: "onBlur",
                  },
                ]}
                required
              >
                <Select
                  onChange={handleOrganisationChange}
                  showSearch
                  filterOption={(input, option) => {
                    return option.label
                      .toLowerCase()
                      .includes(input?.toLowerCase());
                  }}
                  options={organisationOptions}
                  placeholder="Organization Name"
                />
              </Form.Item>
              <ConfigProvider
                renderEmpty={() => (
                  <>
                    <div className="my-8 justify-center text-center">
                      No HR added in selected organisation
                    </div>
                  </>
                )}
              >
                <Form.Item
                  // rules={[{ validator: assignedHr }]}
                  validateTrigger={VALIDATE_TRIGGERS}
                  rules={[
                    {
                      validator: validateHrSelectOnChange,
                      validateTrigger: "onChange",
                    },
                    {
                      validator: validateHrSelectOnBlur,
                      validateTrigger: "onBlur",
                    },
                  ]}
                  name="hr"
                  label={
                    <>
                      HR Email{" "}
                      <Tooltip title="Click here to add HR!">
                        <PlusCircleOutlined
                          onClick={showModalHr}
                          className="ml-[.3rem]"
                        />
                      </Tooltip>
                    </>
                  }
                  required
                >
                  <Select
                    showSearch
                    mode="multiple"
                    className="min-w-[11.5rem]"
                    placeholder="Select HR managers"
                    options={hrOptions}
                    filterOption={(input, option) => {
                      return option.label
                        .toLowerCase()
                        .includes(input?.toLowerCase());
                    }}
                  >
                    {/* <Input /> */}
                  </Select>
                </Form.Item>
                {/* <Form.It  em name="price_per_hour" label="Price/Hour ">
                    <Input placeholder="Input session price per hour." />
                  </Form.Item> */}
                {/* <Form.Item name="coach_fees_per_hour" label="Coach Fees/Hour">
                    <Input placeholder="Input coach price per hour." />
                  </Form.Item> */}
              </ConfigProvider>
              {/* <Form.Item
                  rules={[{ validator: validityCurrency }]}
                  name="currency"
                  label="Currency "
                  required
                >
                  <Select
                    className="min-w-[11.5rem]"
                    placeholder="Select Currency"
                    options={CURRENCY_LIST}
                  />
                </Form.Item> */}
            </div>

            <div className="mt-[2rem] flex flex-row justify-center gap-7">
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Save & Continue
                </Button>
              </Form.Item>
              <Modal
                title="Confirm"
                open={isModalOpenConfirmation}
                onOk={handleOkConfirmation}
                onCancel={handleCancelConfirmation}
              >
                <div>Are you sure you want to save the project?</div>
              </Modal>
              {/* <Button>Delete</Button> */}
            </div>
          </Form>
        </div>

        <Modal
          className="text-center"
          title="Add New Organisation"
          footer={null}
          open={isModalOpenOrganisation}
          onOk={handleOkOrganisation}
          onCancel={handleCancelOrganisation}
        >
          <Form form={addOrganisationForm} onFinish={handlecreateOrganisation}>
            <ImageUpload
              image={OrganisationImage}
              setImage={setOrganisationImage}
              buttonName={"Image"}
            />
            <Form.Item
              required
              name="organisationName"
              validateTrigger={VALIDATE_TRIGGERS}
              rules={[
                {
                  validator: validateOrganisationOnChange,
                  validateTrigger: "onChange",
                },
                {
                  validator: validateOrganisationOnBlur,
                  validateTrigger: "onBlur",
                },
              ]}
            >
              <Input
                maxLength={31}
                className="w-[50%] mt-[4rem]"
                placeholder="Enter New Organisation"
              />
            </Form.Item>
            <div className="flex justify-center gap-2 mt-[2rem]">
              <Button type="primary" htmlType="submit" disabled={isLoading}>
                {isLoading ? "Adding..." : "Add"}
              </Button>
              <Button onClick={handleCancelOrganisation}>Cancel</Button>
            </div>
          </Form>
        </Modal>
        {isModalOpenHr && (
          <Modal
            title="Add HR"
            footer={null}
            open={isModalOpenHr}
            onOk={handleOkHr}
            onCancel={handleCancelHr}
          >
            <Form form={addHrForm} onFinish={handleAddHr}>
              <Form.Item
                name="firstName"
                label="First Name"
                labelCol={{ span: "6" }}
                labelAlign="left"
                className=""
                required
                validateTrigger={VALIDATE_TRIGGERS}
                rules={[
                  {
                    validator: validateFirstNameOnChange,
                    validateTrigger: "onChange",
                  },
                  {
                    validator: validateFirstNameOnBlur,
                    validateTrigger: "onBlur",
                  },
                ]}
              >
                <Input
                  maxLength={21}
                  className="w-[50%]"
                  placeholder="First Name"
                />
              </Form.Item>

              <Form.Item
                name="lastName"
                label="Last Name"
                required
                labelCol={{ span: "6" }}
                labelAlign="left"
                validateTrigger={VALIDATE_TRIGGERS}
                rules={[
                  {
                    validator: validateLastNameOnChange,
                    validateTrigger: "onChange",
                  },
                  {
                    validator: validateLastNameOnBlur,
                    validateTrigger: "onBlur",
                  },
                ]}
              >
                <Input
                  maxLength={21}
                  className="w-[50%]"
                  placeholder="last Name"
                />
              </Form.Item>

              <Form.Item
                name="email"
                label="HR Email"
                validateTrigger={VALIDATE_TRIGGERS}
                rules={[
                  {
                    validator: () => Promise.resolve(),
                    validateTrigger: "onChange",
                  },
                  {
                    validator: validateEmailOnBlur,
                    validateTrigger: "onBlur",
                  },
                ]}
                required
                labelCol={{ span: "6" }}
                labelAlign="left"
              >
                <Input className="w-[50%]" placeholder="Enter Email" />
              </Form.Item>

              {/* <Form.Item
              // rules={[{ validator: validateContact }]}
              name="phone"
              label="Phone Number"
              labelCol={{ span: "6" }}
              labelAlign="left"
              required
              validateTrigger={VALIDATE_TRIGGERS}
              rules={[
                {
                  validator: contactValidationOnChange,
                  validateTrigger: "onChange",
                },
                {
                  validator: contactValidationOnBlur,
                  validateTrigger: "onBlur",
                },
              ]}
            >
              <Input className="w-[50%]" placeholder="Enter Number" />
            </Form.Item> */}

              <div className="phone-input-wrapper flex  gap-3 mb-6">
                <p className="phone-input-label py-[0.2px]">Phone Number:</p>
                <div className="grow">
                  <PhoneInput
                    className={
                      "border p-1 rounded-lg w-[50%]" +
                      (phoneError ? " border-red-500" : "")
                    }
                    defaultCountry={"IN"}
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={(value) => {
                      setPhone(value);
                      setPhoneError(undefined);
                      if (value?.length > 14) {
                        setPhoneError("Max length exceeded.");
                      }
                    }}
                    maxLength={13}
                    onBlur={validatePhone}
                    error={
                      phone
                        ? isValidPhoneNumber(phone)
                          ? undefined
                          : "Invalid phone number"
                        : "Phone number required"
                    }
                  />
                  <p className="text-red-500">{phoneError}</p>
                </div>
              </div>

              <Form.Item
                name="organisation"
                label="Organisation"
                required
                labelCol={{ span: "6" }}
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Please select Organisation!",
                  },
                ]}
              >
                <Select
                  options={organisationOptions}
                  className="size-small"
                  placeholder="Enter Organisation"
                />
              </Form.Item>

              <div className="flex justify-center gap-2 mt-[.5rem]">
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Add
                  </Button>
                </Form.Item>
                <Button htmlType="button" onClick={handleCancelHr}>
                  Cancel
                </Button>
              </div>
            </Form>
          </Modal>
        )}
      </PageContent>
    </>
  );
};
