import { createSlice } from "@reduxjs/toolkit";
import {
  addCoachSlot,
  deleteCoachSlot,
  getCoachSlots,
} from "./viewCoachSlotsServices";

const initialState = {
  viewCoachSlotsStatus: "pending",
  allCoachSlots: [],
  coachSlotError: null,
  coachSlotIsEdit: false,
  coachDetails: null,
  dateRange: null,
};

export const viewCoachSlots = createSlice({
  name: "viewCoachSlots",
  initialState,
  reducers: {
    setIsEditCoachSlot: (state, { payload }) => {
      state.coachSlotIsEdit = payload.isEdit;
      state.coachDetails = payload.coachDetails;
      state.dateRange = payload.dateRange;
    },
  },
  extraReducers: {
    [getCoachSlots.pending]: (state) => {
      state.viewCoachSlotsStatus = "pending";
    },
    [getCoachSlots.fulfilled]: (state, action) => {
      state.allCoachSlots = action.payload;
      state.viewCoachSlotsStatus = "fulfilled";
    },
    [getCoachSlots.rejected]: (state, action) => {
      state.viewCoachSlotsStatus = "rejected";
      state.coachSlotError = action.payload;
    },
    [addCoachSlot.pending]: (state) => {
      state.viewCoachSlotsStatus = "pending";
    },
    [addCoachSlot.fulfilled]: (state, action) => {
      state.viewCoachSlotsStatus = "fulfilled";
    },
    [addCoachSlot.rejected]: (state, action) => {
      state.viewCoachSlotsStatus = "rejected";
      state.coachSlotError = action.payload;
    },
    [deleteCoachSlot.pending]: (state) => {
      state.viewCoachSlotsStatus = "pending";
    },
    [deleteCoachSlot.fulfilled]: (state, action) => {
      state.viewCoachSlotsStatus = "fulfilled";
    },
    [deleteCoachSlot.rejected]: (state, action) => {
      state.viewCoachSlotsStatus = "rejected";
      state.coachSlotError = action.payload;
    },
  },
});

export const { setIsEditCoachSlot } = viewCoachSlots.actions;
export default viewCoachSlots.reducer;
