import { createSlice } from "@reduxjs/toolkit";
import {
  confirmLearnerSlot,
  deleteLearnerBookedSlot,
  deleteLearnerSlot,
  getAvailableSlots,
  getLearnerBookedSlots,
} from "./viewLearnerSlotsServices";

const initialState = {
  viewLearnerSlotsStatus: "pending",
  editLearnerSlotsStatus: "pending",
  allLearnerSlots: [],
  learnerSlotError: null,
  availableSlots: null,
  availableSlotsStatus: null,
  learnerDetails: null,
  datesList: null,
};

export const viewLearnerSlots = createSlice({
  name: "viewLearnerSlots",
  initialState,
  reducers: {
    setEditLearnerSlots: (state, action) => {
      state.learnerDetails = action.payload.learner;
      state.datesList = action.payload.datesList;
    },
  },
  extraReducers: {
    [getLearnerBookedSlots.pending]: (state) => {
      state.viewLearnerSlotsStatus = "pending";
    },
    [getLearnerBookedSlots.fulfilled]: (state, action) => {
      state.allLearnerSlots = action.payload;
      state.viewLearnerSlotsStatus = "fulfilled";
    },
    [getLearnerBookedSlots.rejected]: (state, action) => {
      state.viewLearnerSlotsStatus = "rejected";
      state.learnerSlotError = action.payload;
    },
    [confirmLearnerSlot.pending]: (state) => {
      state.editLearnerSlotsStatus = "pending";
    },
    [confirmLearnerSlot.fulfilled]: (state, action) => {
      state.editLearnerSlotsStatus = "fulfilled";
    },
    [confirmLearnerSlot.rejected]: (state, action) => {
      state.editLearnerSlotsStatus = "rejected";
      state.learnerSlotError = action.payload;
    },
    [getAvailableSlots.pending]: (state) => {
      state.editLearnerSlotsStatus = "pending";
    },
    [getAvailableSlots.fulfilled]: (state, action) => {
      state.availableSlots = action.payload;
      state.editLearnerSlotsStatus = "fulfilled";
    },
    [getAvailableSlots.rejected]: (state, action) => {
      state.editLearnerSlotsStatus = "rejected";
      state.learnerSlotError = action.payload;
    },
    [deleteLearnerSlot.pending]: (state) => {
      state.editLearnerSlotsStatus = "pending";
    },
    [deleteLearnerSlot.fulfilled]: (state, action) => {
      state.editLearnerSlotsStatus = "fulfilled";
    },
    [deleteLearnerSlot.rejected]: (state, action) => {
      state.editLearnerSlotsStatus = "rejected";
      state.learnerSlotError = action.payload;
    },
    [deleteLearnerBookedSlot.pending]: (state) => {
      state.editLearnerSlotsStatus = "pending";
    },
    [deleteLearnerBookedSlot.fulfilled]: (state, action) => {
      state.editLearnerSlotsStatus = "fulfilled";
    },
    [deleteLearnerBookedSlot.rejected]: (state, action) => {
      state.editLearnerSlotsStatus = "rejected";
      state.learnerSlotError = action.payload;
    },
  },
});

export const { setEditLearnerSlots } = viewLearnerSlots.actions;
export default viewLearnerSlots.reducer;
