import { Button, Form, Modal, Select, Table } from "antd";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useGetApi } from "../../../components/hooks/useGetApi";
import { LoadingAndError } from "../../../components/loading-and-error/LoadingAndError";
import { usePutApi } from "../../../hooks/usePutApi";

export const CoachInProject = ({ getBatches, batches }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBatchId, setSelectedBatchId] = useState(batches[0]?.id);

  const {
    data: batchData,
    isLoading: batchDataLoading,
    error: batchDataError,
    getData: getBatchData,
  } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/batch-details/${selectedBatchId}/`
  );

  const batchOptions = batches.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const batch = useMemo(() => {
    if (selectedBatchId) {
      return batches.find((item) => item.id === selectedBatchId);
    }
    return null;
  }, [batches, selectedBatchId]);

  // const coachOptions = data?.map((coach) => ({
  //   value: coach?.id,
  //   label: `${coach?.first_name} ${coach?.last_name}`,
  // }));

  const coachColumn = [
    {
      title: "Coach Name",
      dataIndex: "coachName",
      key: "coachName",
      render: (_, coach) => `${coach?.first_name} ${coach?.last_name}`,
    },
    {
      title: "Coach Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
  ];

  return (
    <div>
      <div className="flex mb-[1rem]">
        <div className="flex gap-2 items-center">
          Batch:
          <Select
            placeholder={"Please select batch"}
            options={batchOptions}
            value={selectedBatchId}
            onChange={(value) => setSelectedBatchId(value)}
          ></Select>
        </div>

        <div className="ml-auto">
          <Button onClick={() => setIsModalOpen(true)}>Add Coach</Button>
        </div>
      </div>
      <LoadingAndError
        loading={batchDataLoading}
        error={batchDataError}
        data={Boolean(batchData)}
      >
        {batchData && !batchDataLoading && (
          <Table columns={coachColumn} dataSource={batchData.coaches}></Table>
        )}
      </LoadingAndError>
      {/* {dataModal} */}
      {isModalOpen && (
        <Modal
          onCancel={() => setIsModalOpen(false)}
          title="Add coaches to batch"
          footer={false}
          open={true}
        >
          <AddCoachModalContent
            getBatchData={getBatchData}
            setIsModalOpen={setIsModalOpen}
            batch={batch}
            selectedCoaches={batchData?.coaches}
          />
        </Modal>
      )}
    </div>
  );
};

function AddCoachModalContent({
  setIsModalOpen,
  batch,
  getBatchData,
  selectedCoaches,
}) {
  const [form] = Form.useForm();

  const {
    data,
    isLoading,
    error,
    // getData: getCoachData,
  } = useGetApi(`${process.env.REACT_APP_BASE_URL}/schedular/coaches/`);

  const {
    data: addCoachesData,
    isLoading: addCoachesLoading,
    putData: addCoaches,
  } = usePutApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/batch/${batch.id}/`
  );

  const coachesOptions = useMemo(() => {
    if (data) {
      return data.map((coach) => ({
        value: coach?.id,
        label: `${coach?.first_name} ${coach?.last_name}`,
      }));
    }
    return [];
  }, [data]);

  const handleFinish = (values) => {
    addCoaches(values);
  };

  useEffect(() => {
    if (selectedCoaches) {
      console.log(selectedCoaches);
      form.setFieldsValue({
        coaches: selectedCoaches.map((item) => item.id),
      });
    }
  }, [selectedCoaches, form]);

  useEffect(() => {
    if (addCoachesData) {
      getBatchData();
      setIsModalOpen(false);
    }
  }, [addCoachesData, getBatchData, setIsModalOpen]);

  return (
    <>
      <div>Batch : {batch?.name}</div>
      <LoadingAndError loading={isLoading} error={error} data={Boolean(data)}>
        {data && !isLoading && (
          <Form form={form} className="mt-4" onFinish={handleFinish}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please select the coaches.",
                },
              ]}
              name="coaches"
            >
              <Select
                placeholder={"Select coaches."}
                mode={"multiple"}
                options={coachesOptions}
              />
            </Form.Item>
            <div className="flex gap-2">
              <Form.Item>
                <Button
                  loading={addCoachesLoading}
                  type="primary"
                  htmlType="submit"
                >
                  Add coaches
                </Button>
              </Form.Item>
              <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
            </div>
          </Form>
        )}
      </LoadingAndError>
    </>
  );
}
