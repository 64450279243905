import React, { useEffect, useState } from "react";
import SESSION_ENDING_ILLUSTRATION from "../../assets/session_ending_illustration.png";
import {
  selectIsConnectedToRoom,
  selectLocalPeer,
  useHMSActions,
  useHMSNotifications,
  useHMSStore,
} from "@100mslive/react-sdk";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form, notification as antDesignNotify, Select } from "antd";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import { MeetingRoom } from "./MeetingRoom";
import { MeetingJoinForm } from "./MeetingJoinForm";
import { usePutApi } from "../../hooks/usePutApi";
import { SESSION_STATUS_OPTIONS } from "../../utils/constants";

function MarkAsCompleteSession({ sessionDetails }) {
  const navigate = useNavigate();
  const { data, isLoading, putData } = usePutApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/update-session-status/${sessionDetails?.id}/`
  );

  const updateSessionStatusHandler = useCallback(
    (values) => {
      putData({ status: values.status });
    },
    [putData]
  );

  useEffect(() => {
    if (data) {
      navigate("/sessions");
    }
  }, [data, navigate]);

  return (
    <div className="flex flex-col justify-center items-center sm:grid grid-cols-2 sm:gap-8 max-w-[50rem]">
      <div className="cols-span-1 sm:my-auto sm:w-full w-[15rem]">
        <img
          src={SESSION_ENDING_ILLUSTRATION}
          alt="Meeting Ended"
          className="object-contain mb-6 rounded-xl"
        />
      </div>
      <div className="sm:my-auto flex items-center flex-col ">
        <h1 className="text-4xl font-bold text-gray-500">Meeting Ended</h1>
        <p className="mt-4 text-gray-500">
          Thank you for attending the session!
        </p>
        <p className="text-gray-500">Please update the session status.</p>
        <div>
          <Form
            className="mt-4 flex flex-col"
            labelAlign="left"
            onFinish={updateSessionStatusHandler}
          >
            <Form.Item
              name="status"
              label="Status"
              rules={[
                {
                  required: true,
                  message: "Please select a status.",
                },
              ]}
            >
              <Select
                placeholder="Select a status"
                options={SESSION_STATUS_OPTIONS}
              />
            </Form.Item>
            <Form.Item className="w-full">
              <Button
                className="w-full"
                htmlType="submit"
                loading={isLoading}
                type="primary"
              >
                Update Status
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

// email will only come in tripartite or review sessions when url is /e/call/:id
export const VideoCall = ({ unauthenticatedJoinee }) => {
  const hmsActions = useHMSActions();
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const [roomEnded, setRoomEnded] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { state } = useLocation();
  const notification = useHMSNotifications();
  const navigate = useNavigate();
  const localPeer = useHMSStore(selectLocalPeer);

  useEffect(() => {
    if (!notification) {
      return;
    }
    switch (notification.type) {
      case "ROOM_ENDED":
        setRoomEnded(true);
        break;
      case "NEW_MESSAGE":
        if (notification.data.message === "TIME_EXTENDED") {
          antDesignNotify.info({
            message: "Session duration extended by 5 minutes",
          });
        }
        if (notification.data.message === "SESSION_GOING_TO_CLOSED") {
          antDesignNotify.info({
            message: "Session will be closed in next 2 minutes",
          });
        }
        break;
      default:
        break;
    }
  }, [notification]);

  useEffect(() => {
    window.onunload = async () => {
      if (isConnected || localPeer) {
        await hmsActions.leave();
      }
    };

    return async () => {
      if (isConnected) {
        hmsActions.leave();
      }
    };
  }, [hmsActions, isConnected, localPeer]);

  useEffect(() => {
    return async () => {
      await hmsActions.setLocalAudioEnabled(false);
      await hmsActions.setLocalVideoEnabled(false);
    };
  }, [hmsActions]);

  if (roomEnded) {
    return (
      <div className="bg-white flex justify-center h-[calc(100vh-3.56rem)]">
        {user?.user?.type === "coach" && state?.sessionDetails ? (
          <MarkAsCompleteSession sessionDetails={state?.sessionDetails} />
        ) : (
          <div className="flex flex-col justify-center items-center sm:grid grid-cols-2 sm:gap-8 max-w-[50rem]">
            <div className="cols-span-1 sm:my-auto sm:w-full w-[15rem]">
              <img
                src={SESSION_ENDING_ILLUSTRATION}
                alt="Meeting Ended"
                className="object-contain mb-6 rounded-xl"
              />
            </div>
            <div className="sm:my-auto flex items-center flex-col ">
              <h1 className="text-4xl font-bold text-gray-500">
                Meeting Ended
              </h1>
              <p className="text-lg mt-4 text-gray-500">
                Thank you for attending the session!
              </p>
              {user?.user?.type === "coach" && (
                <div className="flex flex-col ">
                  <p className="text-lg my-4 text-gray-500">
                    Please update the session status.
                  </p>
                  <Button
                    className=""
                    type="primary"
                    onClick={() => navigate("/sessions")}
                  >
                    Go to Sessions
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return isConnected ? (
      <MeetingRoom setRoomEnded={setRoomEnded} />
    ) : (
      <div className="bg-white">
        <MeetingJoinForm />
      </div>
    );
  }
};
