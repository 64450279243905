import { Search } from "@mui/icons-material";
import { Input, Table } from "antd";
import React, { useState } from "react";
import { Header } from "../../components/header/Header";
import { PageContent } from "../../components/page-content/PageContent";
import { useGetApi } from "../../hooks/useGetApi";

export const Participant = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { data: participants, isLoading: participantsLoading } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/participants/`
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
  ];

  const filteredParticipants = participants?.filter(
    (participant) =>
      participant?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      participant?.email?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      participant?.phone?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  return (
    <>
      <Header>Participants</Header>
      <PageContent>
        <Input
          className="mb-4 w-auto"
          prefix={<Search />}
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Table
          loading={participantsLoading}
          columns={columns}
          dataSource={filteredParticipants || []}
        />
      </PageContent>
    </>
  );
};
