import { Button, Modal } from "antd";
import React, { useState } from "react";
import { writeFile, utils } from "xlsx";
import { DownloadOutlined } from "@ant-design/icons";

export const CoachSampleUpload = ({
  coaches,
  title,
  disabled,
  showModal = false,
}) => {
  const formattedData = coaches.map((item) => ({
    first_name: item.first_name,
    last_name: item.last_name,
    age: item.age,
    gender: item.gender,
    level: item.level?.join(","),
    corporate_yoe: item.years_of_corporate_experience,
    functional_domain: item.domain?.join(","),
    email: item.email,
    // phone_country_code: item.phone_country_code,
    mobile: item.phone,
    job_roles: item.job_roles?.join(","),
    companies_worked_in: item.companies_worked_in?.join(","),
    language: item.language?.join(","),
    industries: item.area_of_expertise?.join(","),
    city: item.city?.join(","),
    country: item.country?.join(","),
    topic: item.topic?.join(","),
    linkedin_profile: item.linkedin_profile_link,
    client_companies: item.client_companies,
    educational_qualification: item.educational_qualification,
    education: item.education,
    fees_per_hour: item.fees_per_hour,
    fees_per_day: item.fees_per_day,
  }));
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);

  const handleClick = () => {
    setIsDownloadModalVisible(true);
  };

  const handleConfirmDownload = () => {
    // create sample data
    const data = formattedData;
    // create workbook and worksheet
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(data);
    // set column headers
    ws["A1"] = { t: "s", v: "first_name" };
    ws["B1"] = { t: "s", v: "last_name" };
    ws["C1"] = { t: "s", v: "age" };
    ws["D1"] = { t: "s", v: "gender" };
    ws["E1"] = { t: "s", v: "level" };
    ws["F1"] = { t: "s", v: "corporate_yoe" };
    ws["G1"] = { t: "s", v: "functional_domain" };
    ws["H1"] = { t: "s", v: "email" };
    ws["I1"] = { t: "s", v: "mobile" };
    ws["J1"] = { t: "s", v: "job_roles" };
    ws["K1"] = { t: "s", v: "companies_worked_in" };
    ws["L1"] = { t: "s", v: "language" };
    ws["M1"] = { t: "s", v: "industries" };
    ws["N1"] = { t: "s", v: "city" };
    ws["O1"] = { t: "s", v: "country" };
    ws["P1"] = { t: "s", v: "topic" };
    ws["Q1"] = { t: "s", v: "linkedin_profile" };
    ws["R1"] = { t: "s", v: "client_companies" };
    ws["S1"] = { t: "s", v: "educational_qualification" };
    ws["T1"] = { t: "s", v: "education" };
    ws["U1"] = { t: "s", v: "fees_per_hour" };
    ws["V1"] = { t: "s", v: "fees_per_day" };

    // add worksheet to workbook
    utils.book_append_sheet(wb, ws, "Sheet1");
    // save file
    writeFile(wb, "sample.xlsx");

    setIsDownloadModalVisible(false);
  };

  return (
    <div>
      <Button
        disabled={disabled}
        icon={<DownloadOutlined />}
        onClick={showModal ? handleClick : handleConfirmDownload}
        type="text"
      >
        <span className="">{title}</span>
      </Button>
      {showModal && (
        <Modal
          title="Confirm Download"
          open={isDownloadModalVisible}
          onCancel={() => setIsDownloadModalVisible(false)}
          onOk={handleConfirmDownload}
          okText="Download"
          cancelText="Cancel"
        >
          Are you sure you want to download{" "}
          <strong>
            {coaches?.length} {coaches?.length === 1 ? "Coach" : "Coaches"}
          </strong>{" "}
          details?
        </Modal>
      )}
    </div>
  );
};
