import { CalendarOutlined, ClockCircleFilled } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Result } from "antd";
import moment from "moment";
import React from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useParams } from "react-router";
import { LoadingAndError } from "../../components/loading-and-error/LoadingAndError";
import { useGetApi } from "../../hooks/useGetApi";
import { usePostApi } from "../../hooks/usePostApi";
import { SmileOutlined } from "@ant-design/icons";

const formatSlotsByDate = (data) => {
  // Create an object to store the slots based on dates
  const slotsByDate = {};
  // Get the current date
  const currentDate = new Date();
  // Iterate through the data array
  data.forEach((slot) => {
    // Extract date from the 'start_time' property
    const slotDate = new Date(parseInt(slot.start_time));
    // Check if the slot is upcoming
    if (slotDate > currentDate) {
      // Format the date as YYYY-MM-DD
      const formattedDate = slotDate.toISOString().split("T")[0];
      // Create an array for the date if it doesn't exist
      slotsByDate[formattedDate] = slotsByDate[formattedDate] || [];
      // Add the  slot to the array
      slotsByDate[formattedDate].push(slot);
    }
  });

  return slotsByDate;
};

const getUniqueSlots = (slots) => {
  const groupedSlots = {};

  // Group slots by start_time
  slots.forEach((slot) => {
    const startTime = slot.start_time;
    if (!groupedSlots[startTime]) {
      groupedSlots[startTime] = [];
    }
    groupedSlots[startTime].push(slot);
  });

  // Randomly select one slot for each start_time
  const uniqueSlots = Object.values(groupedSlots).map((slotGroup) => {
    const randomIndex = Math.floor(Math.random() * slotGroup.length);
    return slotGroup[randomIndex];
  });

  return uniqueSlots;
};

export const ParticipantBookingSession = () => {
  const { bookingLinkId } = useParams();

  const {
    data: availabilities,
    isLoading: availabilitiesLoading,
    error: availabilitiesError,
  } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/coach-availability/?booking_link_id=${bookingLinkId}`
  );

  return (
    <LoadingAndError
      loading={availabilitiesLoading}
      error={availabilitiesError}
      data={Boolean(availabilities)}
    >
      {availabilities &&
        !availabilitiesLoading &&
        ("error" in availabilities ? (
          <Result status="404" title={availabilities["error"]} />
        ) : (
          <CalendarAndBooking
            slots={availabilities?.slots}
          ></CalendarAndBooking>
        ))}
    </LoadingAndError>
  );
};

function CalendarAndBooking({ slots }) {
  const slotsByDate = useMemo(() => {
    if (slots) {
      return formatSlotsByDate(slots);
    }
    return {};
  }, [slots]);
  const dates = Object.keys(slotsByDate);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [isSlotConfirm, setIsSlotConfirm] = useState(false);

  const uniqueSlots = useMemo(() => {
    if (selectedDate) {
      return getUniqueSlots(slotsByDate[selectedDate?.format("YYYY-MM-DD")]);
    } else return [];
  }, [selectedDate]);

  const disabledDate = (current) => {
    return !dates.includes(current.format("YYYY-MM-DD"));
  };

  return dates.length > 0 ? (
    <div className="flex justify-center">
      <div className="m-8 bg-white p-6 rounded-lg grow flex gap-8 max-w-[60rem] shadow-md ">
        <div className="min-w-[16rem] border-0 border-r flex flex-col gap-2">
          <p className="text-xl font-semibold">Coaching Session</p>
          <p className="mt-4 flex items-center gap-4 text-lg">
            <ClockCircleFilled className="text-gray-500" />
            30 Minutes
          </p>
          {selectedSlot && isSlotConfirm && (
            <p className="gap-4 flex">
              <CalendarOutlined className="text-gray-500 text-lg" />
              <p>
                {moment(new Date(+selectedSlot.start_time)).format("hh:mm A")} -
                {moment(new Date(+selectedSlot.end_time)).format("hh:mm A")}
                <p>{selectedDate.format("dddd, DD MMMM YYYY")}</p>
              </p>
            </p>
          )}
          <p></p>
        </div>
        {isSlotConfirm ? (
          <BookingForm
            selectedSlot={selectedSlot}
            setIsSlotConfirm={setIsSlotConfirm}
          ></BookingForm>
        ) : (
          <SlotPicker
            disabledDate={disabledDate}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setIsSlotConfirm={setIsSlotConfirm}
            setSelectedSlot={setSelectedSlot}
            uniqueSlots={uniqueSlots}
            selectedSlot={selectedSlot}
          ></SlotPicker>
        )}
      </div>
    </div>
  ) : (
    <Result
      status="404"
      title="No Slots Available"
      subTitle="Sorry, there are no slots available at the moment."
    />
  );
}

function BookingForm({ selectedSlot, setIsSlotConfirm }) {
  const { bookingLinkId } = useParams();

  const {
    isLoading: scheduleSessionLoading,
    postData: scheduleSession,
    data: scheduleSessionData,
  } = usePostApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/schedule-session/`
  );

  const onFinish = (values) => {
    scheduleSession({
      participant_email: values.email,
      availability_id: selectedSlot?.id,
      booking_link_id: bookingLinkId,
      timestamp: selectedSlot.start_time,
    });
    // You can perform your scheduling logic here
  };

  return scheduleSessionData ? (
    <Result icon={<SmileOutlined />} title="Session Scheduled successfully!" />
  ) : (
    <Form onFinish={onFinish} layout="vertical">
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            type: "email",
            message: "Please enter a valid email!",
          },
          {
            required: true,
            message: "Email is required!",
          },
        ]}
      >
        <Input placeholder="Enter your email." />
      </Form.Item>
      <Form.Item>
        <Button
          loading={scheduleSessionLoading}
          type="primary"
          htmlType="submit"
          style={{ marginRight: "8px" }}
        >
          Schedule Session
        </Button>
        <Button onClick={() => setIsSlotConfirm(false)} type="danger">
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
}

function SlotPicker({
  disabledDate,
  selectedDate,
  setSelectedDate,
  setIsSlotConfirm,
  setSelectedSlot,
  uniqueSlots,
  selectedSlot,
}) {
  return (
    <div className="grow">
      <p className="mb-4 font-semibold text-lg">Select date and time.</p>
      <div className="flex">
        <DatePicker
          className="h-0 p-0 border-none overflow-hidden shrink-0 w-[19rem] mb-[26rem]"
          open={true}
          placement="bottom"
          disabledDate={disabledDate}
          value={selectedDate}
          onChange={(date) => {
            setSelectedSlot(null);
            setSelectedDate(date);
            setIsSlotConfirm(false);
          }}
          cellRender={(date) => (
            <div
              className={`w-full h-full rounded-md py-1 mx-1  ${
                date.format() === selectedDate?.format() &&
                "!bg-primary-1 text-white"
              }`}
            >
              {date.format("DD")}
            </div>
          )}
        />
        {selectedDate ? (
          <div className="grow min-w-[18rem]">
            <div className="p-2 font-semibold">
              {selectedDate.format("DD MMMM YYYY")}
            </div>
            {uniqueSlots?.length > 0 ? (
              <div className="flex flex-col gap-2">
                {uniqueSlots?.map((slot) => {
                  return slot?.id === selectedSlot?.id ? (
                    <div key={slot.id} className="flex gap-2">
                      <p className="bg-primary-1 text-center border-2 border-primary-1 grow text-white font-semibold p-1 rounded">
                        {moment(new Date(+slot.start_time)).format("hh:mm A")}
                      </p>
                      <Button
                        onClick={() => setIsSlotConfirm(true)}
                        className="font-semibold grow rounded !p-1 !h-[auto]"
                      >
                        Confirm
                      </Button>
                    </div>
                  ) : (
                    <p
                      key={slot.id}
                      onClick={() => setSelectedSlot(slot)}
                      className="border-2 border-primary-1 cursor-pointer hover:transition-all duration-700 ease-in-out hover:bg-primary-4 rounded text-center py-1 font-semibold"
                    >
                      {moment(new Date(+slot.start_time)).format("hh:mm A")}
                    </p>
                  );
                })}
              </div>
            ) : (
              <>No slots available</>
            )}
          </div>
        ) : (
          <div className="flex items-center flex-col p-4 grow">
            <CalendarOutlined />
            <p>Select the date</p>
          </div>
        )}
      </div>
    </div>
  );
}
