import moment from "moment";
import React, { useMemo, useState } from "react";
import { useGetApi } from "../hooks/useGetApi";
import { PageContent } from "../page-content/PageContent";
import { Avatar, Button, Collapse, Modal, Tag, Tooltip } from "antd";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { LoadingAndError } from "../loading-and-error/LoadingAndError";
import {
  formatDate,
  formatDateDDMMYYYY,
  formateDate,
} from "../../utils/convertSlotToString";

export const ActiveRequests = () => {
  const {
    data,
    isLoading,
    error,
    getData: getDataRequestAvailibilty,
  } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/schedular-availabilities/`
  );

  const currentDate = moment();
  const filteredData = data?.filter((item) =>
    moment(item.expiry_date).isAfter(currentDate, "day")
  );

  return (
    <div>
      {filteredData?.length > 0 ? (
        filteredData?.map((item) => (
          <Collapse
            key={item.id}
            className="mb-4"
            size="large"
            items={[
              {
                key: "1",
                label: (
                  <div className="grid grid-cols-4 gap-5">
                    <div>
                      <div>Request Name:</div>
                      <Tooltip title={item?.request_name}>
                        <div className="truncate w-[15rem]">
                          {item?.request_name}
                        </div>
                      </Tooltip>
                    </div>
                    <div>
                      <div>Assigned Coaches: </div>
                      <div>{item.coach.length}</div>
                    </div>
                    <div>
                      <div>Action by: </div>
                      <div>{item?.provided_by?.length}</div>
                    </div>
                    <div>
                      <div>Expiry Date:</div>
                      <div>{formatDate(item?.expiry_date)}</div>
                    </div>
                    {/* <div>
                      <DeleteOutlined />
                    </div>
                    <div>
                      <DownloadOutlined />
                    </div> */}
                  </div>
                ),
                children: <PmoRequestContent request={item} />,
              },
            ]}
          />
        ))
      ) : (
        <p className="mt-4 text-gray-500">No requests available</p>
      )}
    </div>
  );
};

function formatSlots(slots) {
  const formattedSlots = {};

  slots?.forEach((slot) => {
    const startTime = new Date(+slot.start_time);
    const date = moment(startTime).format("DD-MM-YYYY");

    if (!formattedSlots[date]) {
      formattedSlots[date] = [];
    }

    formattedSlots[date].push(slot);
  });

  return formattedSlots;
}

export function PmoRequestContent({ request }) {
  const { user } = useSelector((state) => state.auth);
  const {
    data: slots,
    isLoading: slotsLoading,
    error: slotsError,
  } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/request/${request?.id}/slots/`
  );

  const [isModalOpenProvidedSlots, setIsModalOpenProvidedSlots] =
    useState(false);

  const showProvidedSlotsModal = (id) => {
    setIsModalOpenProvidedSlots(id);
  };

  const handleOkProvidedSlots = () => {
    setIsModalOpenProvidedSlots(false);
  };
  const handleCancelProvidedSlots = () => {
    setIsModalOpenProvidedSlots(false);
  };

  const slotsByCoach = useMemo(() => {
    if (slots) {
      const coachWiseSlots = {};
      slots?.forEach((slot) => {
        if (slot.coach in coachWiseSlots) {
          coachWiseSlots[slot.coach] = [...coachWiseSlots[slot.coach], slot];
        } else {
          coachWiseSlots[slot.coach] = [slot];
        }
      });
      return coachWiseSlots;
    } else return {};
  }, [slots]);

  const formattedSlots = useMemo(() => {
    if (slotsByCoach && isModalOpenProvidedSlots) {
      return formatSlots(slotsByCoach[isModalOpenProvidedSlots]);
    } else return {};
  }, [slotsByCoach, isModalOpenProvidedSlots]);

  const providedSlotModal = (
    <Modal
      // title="Basic Modal"
      footer={false}
      open={isModalOpenProvidedSlots}
      onOk={handleOkProvidedSlots}
      onCancel={handleCancelProvidedSlots}
    >
      <>
        <div className="p-2">
          {Object.keys(formattedSlots).map((date) => {
            return (
              <div className="mb-8">
                {date}
                <div className="flex flex-wrap justify-center">
                  {formattedSlots[date].map((slot) => {
                    return (
                      <div
                        key={slot.id}
                        className={`mt-[1rem] border rounded-2xl p-1 text-center w-[12rem]  cursor-pointer mx-1 bg-primary-4`}
                      >
                        {moment(new Date(+slot.start_time)).format("hh:mm A")} -
                        {moment(new Date(+slot.end_time)).format("hh:mm A")}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </>
    </Modal>
  );

  return (
    <LoadingAndError
      error={slotsError}
      data={Boolean(slots)}
      loading={slotsLoading}
    >
      {slots && !slotsLoading && (
        <>
          <div className="mb-[1rem]">
            Requested Date:{" "}
            {Object?.keys(request.availability)?.map((date) => {
              return <Tag>{formatDate(date)}</Tag>;
            })}
          </div>
          <div>
            {request.coach.map((coach, index) => (
              <div className="grid grid-cols-3 gap-5">
                <div className="flex gap-5 mb-[1rem]">
                  <div>
                    <Avatar size={50} />
                  </div>
                  <div>
                    <div key={coach.id}>
                      {coach.first_name + " " + coach.last_name}
                    </div>
                    <div>{coach.email}</div>
                  </div>
                </div>
                <div>
                  <Tag>
                    {" "}
                    {(slotsByCoach && slotsByCoach[coach.id]?.length) || 0}{" "}
                    Slots Provided
                  </Tag>
                </div>
                <div>
                  <Button
                    disabled={!slotsByCoach[coach.id]?.length}
                    onClick={() => showProvidedSlotsModal(coach?.id)}
                  >
                    View Provided Slots
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {providedSlotModal}
    </LoadingAndError>
  );
}
