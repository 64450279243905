import React from "react";
import { SmileOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

export const SuccessTestPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlState = location.state || {};

  const handleButtonClick = () => {
    navigate("/successpreview", { state: urlState });
  };

  return (
    <main className="xl:col-start-2 pt-4 xl:col-end-7 w-[98%] mx-auto">
      <div>
        {" "}
        <Result
          icon={<SmileOutlined />}
          title={<div>Test email send successfully</div>}
          extra={
            <Button onClick={handleButtonClick} type="primary">
              Next
            </Button>
          }
        />
      </div>
    </main>
  );
};
