import { createSlice } from "@reduxjs/toolkit";
import {
  addFaculty,
  getFaculties,
  updateFaculty,
} from "./manageFacultiesServices";

const initialState = {
  manageFacultiesStatus: "pending",
  facultyList: [],
  facultyError: null,
};

export const manageFaculties = createSlice({
  name: "manageFaculties",
  initialState,
  reducers: {},
  extraReducers: {
    [getFaculties.pending]: (state) => {
      state.manageFacultiesStatus = "pending";
    },
    [getFaculties.fulfilled]: (state, action) => {
      state.facultyList = action.payload;
      state.manageFacultiesStatus = "fulfilled";
    },
    [getFaculties.rejected]: (state, action) => {
      state.manageFacultiesStatus = "rejected";
      state.facultyError = action.payload;
    },
    [addFaculty.pending]: (state) => {
      state.manageFacultiesStatus = "pending";
    },
    [addFaculty.fulfilled]: (state) => {
      state.manageFacultiesStatus = "fulfilled";
    },
    [addFaculty.rejected]: (state, action) => {
      state.manageFacultiesStatus = "rejected";
      state.facultyError = action.payload;
    },
    [updateFaculty.pending]: (state) => {
      state.manageFacultiesStatus = "pending";
    },
    [updateFaculty.fulfilled]: (state, action) => {
      state.manageFacultiesStatus = "fulfilled";
    },
    [updateFaculty.rejected]: (state, action) => {
      state.manageFacultiesStatus = "rejected";
      state.facultyError = action.payload;
    },
  },
});

export default manageFaculties.reducer;
