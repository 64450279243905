// Email Validation

import { isValidPhoneNumber } from "react-phone-number-input";

const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const validateEmailOnBlur = (_, value) => {
  if (!value) {
    return Promise.reject("Please input email.");
  }
  if (!validateEmail(value.trim())) {
    return Promise.reject("Please enter valid email address.");
  }
  return Promise.resolve();
};

// Validation on Price
export const price = (_, value) => {
  if (!value?.trim()) {
    return Promise.reject("Please enter price.");
  }
  if (!isWholeNumber(value?.trim())) {
    return Promise.reject("Please enter valid price.");
  }
  if (value && isMaxCharCrossed(value.trim(), 6)) {
    return Promise.reject("Price cannot be more than 10,00,000.");
  }
  return Promise.resolve();
};
// Validation on Project Name

// function isAlphabetsAndSpaceAndDotProjectName(str) {
//     // Regular expression to match only alphabets, space, and dot
//     const regex = /^[A-Za-z\s]+$/;
//     // Test if the string matches the regular expression
//     return regex.test(str);
// }

export const validateProjectNameOnBlur = (_, value) => {
  if (!value) {
    return Promise.reject("Please enter project name.");
  }
  if (value.trim().length < 3) {
    return Promise.reject("Project name should be more that 3 characters.");
  }

  if (value && isMaxCharCrossed(value, 50)) {
    return Promise.reject("Characters can not be more than 50.");
  }
  return Promise.resolve();
};

export const validityCurrency = (_, value) => {
  if (!value || value?.length === 0) {
    return Promise.reject("Please select the currency.");
  }

  return Promise.resolve();
};

export const validateRequestNameOnChange = (_, value) => {
  if (!value) {
    return Promise.reject("Please enter request name");
  }
  // if (!isAlphabetsAndSpaceAndDotProjectName(value)) {
  //     return Promise.reject("First name can only contain alphabets!");
  // }
  if (value && isMaxCharCrossed(value, 100)) {
    return Promise.reject("Characters cannot be more than 100.");
  }

  return Promise.resolve();
};

export const validateProjectNameOnChange = (_, value) => {
  // if (!value) {
  //     return Promise.reject("Please enter project name");
  // }
  // if (!isAlphabetsAndSpaceAndDotProjectName(value)) {
  //     return Promise.reject("First name can only contain alphabets!");
  // }
  if (value && isMaxCharCrossed(value, 50)) {
    return Promise.reject("Characters can not be more than 50.");
  }

  return Promise.resolve();
};

// Validation of Name

function isAlphabetsAndSpaceAndDotName(str) {
  // Regular expression to match only alphabets, space, and dot
  const regex = /^[A-Za-z\s]+$/;
  // Test if the string matches the regular expression
  return regex.test(str);
}

export const validateNameOnBlur = (_, value) => {
  if (!value) {
    return Promise.reject("Please enter coach full name.");
  }
  if (value.trim().length < 3) {
    return Promise.reject("First name must be at least 3 characters long.");
  }
  return Promise.resolve();
};

export const validateNameOnChange = (_, value) => {
  // if (!value) {
  //     return Promise.reject("Please enter coach full name");
  // }
  if (value && !isAlphabetsAndSpaceAndDotName(value)) {
    return Promise.reject("Name can only contain alphabets.");
  }
  if (value && isMaxCharCrossed(value, 30)) {
    return Promise.reject("Characters can not be more than 30.");
  }

  return Promise.resolve();
};

// Validation of first name

function isAlphabetsAndSpaceAndDotFirstName(str) {
  // Regular expression to match only alphabets, space, and dot
  const regex = /^[A-Za-z\s]+$/;
  // Test if the string matches the regular expression
  return regex.test(str);
}

export const validateFirstNameOnBlur = (_, value) => {
  if (!value) {
    return Promise.reject("Please enter first name.");
  }
  if (value.trim().length < 3) {
    return Promise.reject("First name must be at least 3 characters long.");
  }
  return Promise.resolve();
};

export const validateFirstNameOnChange = (_, value) => {
  // if (!value) {
  //     return Promise.reject("Please enter first name");
  // }
  if (value && !isAlphabetsAndSpaceAndDotFirstName(value)) {
    return Promise.reject("First name can only contain alphabets.");
  }
  if (value && isMaxCharCrossed(value, 20)) {
    return Promise.reject("Characters can not be more than 20.");
  }

  return Promise.resolve();
};

export const validateLearnerNameOnBlur = (_, value) => {
  if (!value) {
    return Promise.reject("Please enter name.");
  }
  if (value.trim().length < 3) {
    return Promise.reject("Name must be at least 3 characters long.");
  }
  return Promise.resolve();
};

export const validateLearnerNameOnChange = (_, value) => {
  // if (!value) {
  //     return Promise.reject("Please enter first name");
  // }
  if (value && !isAlphabetsAndSpaceAndDotFirstName(value)) {
    return Promise.reject("Name can only contain alphabets.");
  }
  if (value && isMaxCharCrossed(value, 20)) {
    return Promise.reject("Characters can not be more than 20.");
  }

  return Promise.resolve();
};

// Validation of last name

function isAlphabetsAndSpaceAndDotLastName(str) {
  // Regular expression to match only alphabets, space, and dot
  const regex = /^[A-Za-z\s]+$/;
  // Test if the string matches the regular expression
  return regex.test(str);
}

export const validateLastNameOnBlur = (_, value) => {
  if (!value) {
    return Promise.reject("Please enter last name.");
  }
  if (value.trim().length < 3) {
    return Promise.reject("Last name must be at least 3 characters long.");
  }
  return Promise.resolve();
};

export const validateLastNameOnChange = (_, value) => {
  // if (!value) {
  //     return Promise.reject("Please enter last name");
  // }
  if (value && !isAlphabetsAndSpaceAndDotLastName(value)) {
    return Promise.reject("Last name can only contain alphabets.");
  }
  if (value && isMaxCharCrossed(value, 20)) {
    return Promise.reject("Characters can not be more than 20.");
  }

  return Promise.resolve();
};

// Validation Of Age

export const ageValidationOnBlur = (_, value) => {
  // if (!value) {
  //     return Promise.reject("Please enter age");
  // }
  if (value && startingWithZero(value)) {
    return Promise.reject("Cannot start with 0.");
  }
  if (value && !isNaturalNumber(value)) {
    return Promise.reject("Please enter valid age.");
  }
  if (value && isMaxIntCrossed(value, 2)) {
    return Promise.reject("Age cannot be more than 2 digits.");
  }
  if (value && minAge(value)) {
    return Promise.reject("Age cannot be less than 18 years.");
  }
  return Promise.resolve();
};

export const ageValidationOnChange = (_, value) => {
  // if (!value) {
  //     return Promise.reject("Please enter age");
  // }
  if (value && startingWithZero(value)) {
    return Promise.reject("Cannot start with 0.");
  }
  if (value && !isNaturalNumber(value)) {
    return Promise.reject("Please enter valid age.");
  }
  if (value && isMaxIntCrossed(value, 2)) {
    return Promise.reject("Age cannot be more than 2 digits.");
  }
  // if (value && minAge(value)) {
  //     return Promise.reject("Age cannot be less than 18 years")
  // }
  return Promise.resolve();
};

// Validation Price/Hour
export const pricePerHourOnChange = (_, value) => {
  if (!value) {
    return Promise.reject("Please enter price.");
  }
  if (value && startingWithZero(value)) {
    return Promise.reject("Cannot start with 0.");
  }
  if (value && !isNaturalNumber(value)) {
    return Promise.reject("Please enter valid number.");
  }
  if (value && isMaxIntCrossed(value, 6)) {
    return Promise.reject("Price cannot be more than 2,00,000.");
  }
  if (value && minPrice(value)) {
    return Promise.reject("Price cannot be more than 2,00,000.");
  }
  return Promise.resolve();
};

export const pricePerHourOnBlur = (_, value) => {
  // if (!value) {
  //     return Promise.reject("Please enter age");
  // }
  if (value && startingWithZero(value)) {
    return Promise.reject("Cannot start with 0.");
  }
  if (value && !isNaturalNumber(value)) {
    return Promise.reject("Please enter valid price.");
  }
  if (value && minPrice(value)) {
    return Promise.reject("Price cannot be more than 2,00,000.");
  }
  return Promise.resolve();
};

// Validation Coach Fees/Hour
export const coachFeesPerHourOnChange = (_, value) => {
  if (!value) {
    return Promise.reject("Please enter price.");
  }
  if (value && startingWithZero(value)) {
    return Promise.reject("Cannot start with 0.");
  }
  if (value && !isNaturalNumber(value)) {
    return Promise.reject("Please enter valid number.");
  }
  if (value && minPrice(value)) {
    return Promise.reject("Price cannot be more than 2,00,000.");
  }
  return Promise.resolve();
};

export const coachFeesPerHourOnBlur = (_, value) => {
  // if (!value) {
  //     return Promise.reject("Please enter age");
  // }
  if (value && startingWithZero(value)) {
    return Promise.reject("Cannot start with 0.");
  }
  if (value && !isNaturalNumber(value)) {
    return Promise.reject("Please enter valid price.");
  }
  if (value && minPrice(value)) {
    return Promise.reject("Price cannot be more than 2,00,000.");
  }
  return Promise.resolve();
};

// Validation on Contact Number

export const contactValidationOnBlur = (_, value) => {
  if (!value) {
    return Promise.reject("Please enter your contact number.");
  }
  if (startingWithZero(value)) {
    return Promise.reject("Contact number should not start with zero.");
  }
  if (!isNaturalNumber(value)) {
    return Promise.reject("Please enter valid contact number.");
  }
  if (isMaxIntCrossed(value, 10)) {
    return Promise.reject("Digit can not be more than 10.");
  }
  return Promise.resolve();
};

export const contactValidationOnChange = (_, value) => {
  // if (!value) {
  //     return Promise.reject("Please enter your contact number");
  // }
  if (value && startingWithZero(value)) {
    return Promise.reject("Contact number should not start with zero.");
  }
  if (value && !isNaturalNumber(value)) {
    return Promise.reject("Please enter valid contact number.");
  }
  if (value && isMaxIntCrossed(value, 10)) {
    return Promise.reject("Digit can not be more than 10.");
  }
  return Promise.resolve();
};

// Validation On Password

export const validatePassword = (_, value) => {
  const error = returnPasswordError(value);

  if (error) {
    return Promise.reject(error);
  }
  if (isMaxIntCrossed(value, 19)) {
    return Promise.reject("Password should not be more than 20 characters.");
  }

  return Promise.resolve();
};

function returnPasswordError(password) {
  // Regular expressions to match the password
  const regexLength = /^.{8,}$/;
  const regexUpperCase = /[A-Z]/;
  const regexLowerCase = /[a-z]/;
  const regexNumber = /[0-9]/;
  const regexSpecialChar = /[\W]/;
  const regexSpace = /\s/;

  // Check if the password matches the regular expressions
  if (!regexLength.test(password)) {
    return "Password must be at least 8 characters long.";
  } else if (!regexUpperCase.test(password)) {
    return "Password must contain at least one uppercase letter.";
  } else if (!regexLowerCase.test(password)) {
    return "Password must contain at least one lowercase letter.";
  } else if (!regexNumber.test(password)) {
    return "Password must contain at least one number.";
  } else if (!regexSpecialChar.test(password)) {
    return "Password must contain at least one special character.";
  } else if (regexSpace.test(password)) {
    return "Password must not contain spaces.";
  } else {
    return null;
  }
}

const startingWithZero = (value) => {
  if (value.startsWith("0")) {
    return true;
  }
  return false;
};

function validateContact(_, value) {
  if (!value) {
    return Promise.reject("Please enter your contact number.");
  }

  const isValidNumber = /^\d{10}$/g.test(value);
  const isValidFormat = /^\d{10}$/.test(value);

  const isStartingWithZero = startingWithZero(value);

  if (isStartingWithZero) {
    return Promise.reject("Contact number should not start with zero.");
  }

  if (isValidFormat && isValidNumber) {
    return Promise.resolve();
  }

  return Promise.reject("Invalid phone number.");
}

function isNaturalNumber(value) {
  // Check if the value is a positive integer
  return Number.isInteger(+value) && value > 0;
}

export function isWholeNumber(value) {
  // Check if the value is a positive integer
  return Number.isInteger(+value);
}

const isMaxCharCrossed = (value, count) => {
  if (value.length > count) {
    return true;
  }
  return false;
};

const isMaxIntCrossed = (value, count) => {
  if (value.length > count) {
    return true;
  }
  return false;
};

const minAge = (value) => {
  if (value < 18) {
    return true;
  }
  return false;
};

export const minFees = (value) => {
  if (+value < 200000) {
    return true;
  }
  return false;
};

export const minPrice = (value) => {
  if (+value > 200000) {
    return true;
  }
  return false;
};

export const validateName = (value) => {
  if (!value) {
    return Promise.reject("Enter your first name.");
  }
  // if (!isAlphabetsAndSpaceAndDot(value)) {
  //     return Promise.reject("Name can only contain alphabets!");
  // }
  if (value.length < 3) {
    return Promise.reject("Name must be at least 3 characters long.");
  }
  // if (isMaxCharCrossed(value, 20)) {
  //     return Promise.reject("Characters can not be more than 20");
  // }

  return Promise.resolve();
};

export const validateCoacheeNameOnChange = (value) => {
  if (!value) {
    return Promise.reject("Enter your full name.");
  }
  if (value.length < 3) {
    return Promise.reject("Name must be at least 3 characters long.");
  }
  return Promise.resolve();
};

export const validateInteger = (_, value) => {
  if (startingWithZero(value)) {
    return Promise.reject("Cannot start with 0.");
  }
  if (!value) {
    return Promise.reject("Please enter valid number.");
  }
  if (!isNaturalNumber(value)) {
    return Promise.reject("Please enter valid number.");
  }
  if (isMaxIntCrossed(value, 5)) {
    return Promise.reject("Characters can not be more than 5.");
  }

  return Promise.resolve();
};

export { validateContact };

export const validateAge = (_, value) => {
  if (startingWithZero(value)) {
    return Promise.reject("Cannot start with 0.");
  }
  if (!value) {
    return Promise.reject("Please enter valid number.");
  }
  if (!isNaturalNumber(value)) {
    return Promise.reject("Please enter valid number.");
  }
  if (isMaxIntCrossed(value, 2)) {
    return Promise.reject("Age cannot be more than 2 digits.");
  }
  return Promise.resolve();
};

export const getPhoneError = (phone) => {
  if (phone) {
    if (isValidPhoneNumber(phone)) {
      return undefined;
    } else {
      return "Invalid phone number.";
    }
  } else {
    return "Phone number required.";
  }
};

export const minFeesValidationOnBlur = (_, value) => {
  if (!value) {
    return Promise.reject("Please enter minimum fee.");
  }
  if (value && startingWithZero(value)) {
    return Promise.reject("Cannot start with 0.");
  }
  if (value && !isNaturalNumber(value)) {
    return Promise.reject("Please enter valid age.");
  }
  if (+value && +value > 200000) {
    return Promise.reject("Maximum fees can be 2,00,000.");
  }
  return Promise.resolve();
};

export const minFeesValidationOnChange = (_, value) => {
  if (value && startingWithZero(value)) {
    return Promise.reject("Cannot start with 0.");
  }
  if (value && !isNaturalNumber(value)) {
    return Promise.reject("Please enter valid fee.");
  }
  if (+value && +value > 200000) {
    return Promise.reject("Maximum fees can be 2,00,000.");
  }
  return Promise.resolve();
};

export const minFeesPerDayValidationOnBlur = (_, value) => {
  // if (!value) {
  //   return Promise.reject("Please enter minimum fee.");
  // }
  if (value && startingWithZero(value)) {
    return Promise.reject("Cannot start with 0.");
  }
  if (value && !isNaturalNumber(value)) {
    return Promise.reject("Please enter valid fee.");
  }
  if (+value && +value > 1000000) {
    return Promise.reject("Maximum fees can be 10,00,000.");
  }
  return Promise.resolve();
};

export const minFeesPerDayValidationOnChange = (_, value) => {
  // if (value && startingWithZero(value)) {
  //   return Promise.reject("Cannot start with 0.");
  // }
  if (value && !isNaturalNumber(value)) {
    return Promise.reject("Please enter valid fee.");
  }
  if (+value && +value > 1000000) {
    return Promise.reject("Maximum fees can be 10,00,000.");
  }
  return Promise.resolve();
};

export const minFeesPerHourValidationOnBlur = (_, value) => {
  // if (!value) {
  //   return Promise.reject("Please enter minimum fee.");
  // }
  if (value && startingWithZero(value)) {
    return Promise.reject("Cannot start with 0.");
  }
  if (value && !isNaturalNumber(value)) {
    return Promise.reject("Please enter valid age.");
  }
  if (+value && +value > 200000) {
    return Promise.reject("Maximum fees can be 2,00,000.");
  }
  return Promise.resolve();
};

export const minFeesPerHourValidationOnChange = (_, value) => {
  // if (value && startingWithZero(value)) {
  //   return Promise.reject("Cannot start with 0.");
  // }
  if (value && !isNaturalNumber(value)) {
    return Promise.reject("Please enter valid fee.");
  }
  if (+value && +value > 200000) {
    return Promise.reject("Maximum fees can be 2,00,000.");
  }
  return Promise.resolve();
};
