import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getLearners = createAsyncThunk(
  "manageLearners/getLearners",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/learners/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addLearner = createAsyncThunk(
  "manageLearners/addLearner",
  async ({ learnerDetails, getLearners }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/add-learner/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: learnerDetails,
      });
      if (response.status === 200) {
        dispatch(getLearners());
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateLearner = createAsyncThunk(
  "manageLearners/updateLearner",
  async (
    { learnerDetails, id, getLearners },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/update-learner/${id}/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: learnerDetails,
      });
      if (response.status === 200) {
        dispatch(getLearners());
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
