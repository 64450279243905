import React, { useMemo, useState } from "react";
import { Button, Collapse, Modal } from "antd";
import { useSelector } from "react-redux";
import { usePostApi } from "../../../hooks/usePostApi";
import moment from "moment";
import { useEffect } from "react";
import { useGetApi } from "../../../hooks/useGetApi";

export const SlotRequests = ({ requests, getRequests }) => {
  return (
    <>
      {requests?.map((item) => (
        <Collapse
          key={item.id}
          className="mb-4"
          size="large"
          items={[
            {
              key: "1",
              label: (
                <div className="flex gap-10">
                  <div className="capitalize">{item.request_name}</div>
                  <div className="ml-auto">
                    <div className="text-xs">Expiry Date:</div>
                    <div>
                      {moment(new Date(item.expiry_date)).format("DD-MM-YYYY")}
                    </div>
                  </div>
                </div>
              ),
              children: <Request getRequests={getRequests} request={item} />,
            },
          ]}
        />
      ))}
    </>
  );
};

function convertToSlots(data, existingSlots) {
  const result = {};

  for (const date in data) {
    if (data.hasOwnProperty(date)) {
      const intervals = data[date];
      const slots = [];
      let slotIdCounter = 1;
      const currentDate = new Date();
      const currentTimeStamp = currentDate.getTime();

      if (currentDate >= new Date(`${date} 11:59 PM`)) {
        continue;
      }

      intervals.forEach((interval) => {
        const { start_time, end_time } = interval;
        const slotDuration = 1800000; // 30 minutes in milliseconds
        let startTimeStamp = start_time;

        while (startTimeStamp < end_time) {
          const slotEndTime = Math.min(startTimeStamp + slotDuration, end_time);
          const slotId = `${date}_slot_${slotIdCounter++}`;
          if (currentTimeStamp < startTimeStamp) {
            const startTimeStampOnCurrentLoop = startTimeStamp;
            const conflict = existingSlots[date].some(
              (existingSlot) =>
                existingSlot.start_time < slotEndTime &&
                existingSlot.end_time > startTimeStampOnCurrentLoop
            );

            slots.push({
              id: slotId,
              start_time: startTimeStamp,
              end_time: slotEndTime,
              conflict,
            });
          }
          startTimeStamp += slotDuration;
        }
      });

      result[date] = slots;
    }
  }

  return result;
}

function flattenSlots({ selectedSlots, coach, request }) {
  const result = [];

  for (const date in selectedSlots) {
    if (selectedSlots.hasOwnProperty(date)) {
      const slots = selectedSlots[date];
      slots.forEach((slot) => {
        result.push({ ...slot, request, coach });
      });
    }
  }

  return result;
}

function Request({ request, getRequests }) {
  const [selectedSlots, setSelectedSlots] = useState({});
  const { user } = useSelector((state) => state.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    data: existingSlots,
    isLoading: existingSlotsLoading,
    error: existingSlotsError,
    getData: getExistingSlots,
  } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/existing_slots_of_coach/${request.id}/${user.id}/`
  );

  const {
    isLoading: isLoadingGivingAvailibily,
    postData: postDataGivingAvailibilty,
    data: dataGivingAvailibilty,
  } = usePostApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/give_availibilty/`
  );

  const { datesRequested, formattedAvailabilities } = useMemo(() => {
    if (request && existingSlots) {
      const formattedAvailabilities = convertToSlots(
        request.availability,
        existingSlots?.coach_availabilities_date_wise
      );

      return {
        datesRequested: Object.keys(formattedAvailabilities),
        formattedAvailabilities,
      };
    }
    return { datesRequested: [], formattedAvailabilities: {} };
  }, [request, existingSlots]);

  const handleSlotClick = (slot, date) => {
    setSelectedSlots((prevSelectedSlots) => {
      if (date in prevSelectedSlots) {
        const sameSlot = prevSelectedSlots[date]?.find(
          (item) => item.id === slot.id
        );
        if (sameSlot) {
          return {
            ...prevSelectedSlots,
            [date]: [
              ...prevSelectedSlots[date].filter((item) => item.id !== slot.id),
            ],
          };
        } else {
          return {
            ...prevSelectedSlots,
            [date]: [...prevSelectedSlots[date], slot],
          };
        }
      } else {
        return { ...prevSelectedSlots, [date]: [slot] };
      }
    });
  };

  const isSelected = (id, date) => {
    if (date in selectedSlots) {
      return Boolean(selectedSlots[date].find((item) => item.id === id));
    } else return false;
  };

  const selectedSlotsArray = useMemo(() => {
    return flattenSlots({
      selectedSlots,
      request: request.id,
      coach: user.id,
    });
  }, [selectedSlots, user, request]);

  const handleConfirmSlots = (slots) => {
    postDataGivingAvailibilty({
      slots,
      slots_length: slots.length,
      request_id: request.id,
      coach_id: user.id,
    });
  };

  useEffect(() => {
    if (dataGivingAvailibilty) {
      getRequests();
      setIsModalOpen(false);
    }
  }, [dataGivingAvailibilty, getRequests]);

  return (
    <div>
      <div className="flex justify-end">
        <Button
          loading={isLoadingGivingAvailibily}
          disabled={selectedSlotsArray.length === 0}
          onClick={() => setIsModalOpen(true)}
        >
          Confirm Slots
        </Button>
      </div>
      {datesRequested.map((date) => {
        return (
          <div className="mb-8">
            {moment(new Date(date)).format("DD-MM-YYYY (dddd)")}
            <div className="flex flex-wrap ">
              {formattedAvailabilities[date].map((slot) => {
                return (
                  <div
                    key={slot.id}
                    onClick={
                      !slot.conflict
                        ? () => handleSlotClick(slot, date)
                        : () => {}
                    }
                    className={`mt-[1rem] border rounded-2xl p-1 text-center w-[12rem] mx-1 ${
                      isSelected(slot.id, date)
                        ? "bg-primary-3 cursor-pointer"
                        : !slot.conflict
                        ? "hover:bg-primary-4 cursor-pointer"
                        : "cursor-not-allowed opacity-50"
                    }`}
                  >
                    {moment(new Date(+slot.start_time)).format("hh:mm A")} -
                    {moment(new Date(+slot.end_time)).format("hh:mm A")}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <Modal
        title="Give availability"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => handleConfirmSlots(selectedSlotsArray)}
      >
        Are you sure want to confirm the selected slots?
      </Modal>
    </div>
  );
}
