import React from "react";
import { Header } from "../../../components/header/Header";
import { PageContent } from "../../../components/page-content/PageContent";
import { Tabs, Tooltip } from "antd";
import { ProjectStructure } from "../../../components/project-structure/ProjectStructure";
import { AddCoachee } from "../../../components/add-coachee/AddCoachee";
import { CoachInProject } from "../coach-in-project/CoachInProject";
import { useParams } from "react-router-dom";
import { useGetApi } from "../../../components/hooks/useGetApi";
import { LoadingAndError } from "../../../components/loading-and-error/LoadingAndError";
import { useMemo } from "react";
import { BatchCalendar } from "./BatchCalendar";
import { useState } from "react";

export const InsideProject = () => {
  const [activeKey, setActiveKey] = useState("project_structure");
  const { id } = useParams();
  const {
    data: project,
    isLoading: projectLoading,
    error: projectError,
    getData: getProject,
  } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/schedular-project/${id}/`
  );

  const {
    data: batches,
    isLoading: batchesLoading,
    error: batchesError,
    getData: getBatches,
  } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/schedular-batches/?project_id=${id}`
  );

  const items = useMemo(() => {
    const isProjectStructureAdded = project?.project_structure?.length > 0;
    const isBatchesAvailable = batches?.length > 0;
    const tooltipMessageToAddProjectStructure = "Add the project structure.";
    const tooltipMessageToAddBatch = "Please add the batch first.";
    const isProjectStructureFinalized = project?.is_project_structure_finalized;
    const tooltipMessageToProjectStructureFinalize =
      "Please finalize the project structure";

    if (batches && project)
      return [
        {
          key: "project_structure",
          label: "Project Structure",
          children: (
            <ProjectStructure getProject={getProject} project={project} />
          ),
        },
        {
          key: "coaches",
          label: (
            <Tooltip
              title={
                !isProjectStructureAdded
                  ? tooltipMessageToAddProjectStructure
                  : !isProjectStructureFinalized
                  ? tooltipMessageToProjectStructureFinalize
                  : !isBatchesAvailable
                  ? tooltipMessageToAddBatch
                  : ""
              }
            >
              Coaches
            </Tooltip>
          ),
          disabled:
            !isProjectStructureAdded ||
            !isBatchesAvailable ||
            !isProjectStructureFinalized,
          children: (
            <CoachInProject getBatches={getBatches} batches={batches} />
          ),
        },
        {
          key: "participants",
          disabled: !isProjectStructureAdded || !isProjectStructureFinalized,
          label: (
            <Tooltip
              title={
                !isProjectStructureAdded
                  ? tooltipMessageToAddProjectStructure
                  : !isProjectStructureFinalized
                  ? tooltipMessageToProjectStructureFinalize
                  : ""
              }
            >
              Participants
            </Tooltip>
          ),
          children: <AddCoachee getBatches={getBatches} batches={batches} />,
        },
        {
          key: "batch_calendar",
          label: (
            <Tooltip
              title={
                !isProjectStructureAdded
                  ? tooltipMessageToAddProjectStructure
                  : !isProjectStructureFinalized
                  ? tooltipMessageToProjectStructureFinalize
                  : !isBatchesAvailable
                  ? tooltipMessageToAddBatch
                  : ""
              }
            >
              Batch Calendar
            </Tooltip>
          ),
          disabled:
            !isProjectStructureAdded ||
            !isBatchesAvailable ||
            !isProjectStructureFinalized,
          children: (
            <BatchCalendar
              setSelectedTab={setActiveKey}
              getBatches={getBatches}
              batches={batches}
            ></BatchCalendar>
          ),
        },
      ];
    else return [];
  }, [project, getProject, batches, getBatches]);

  return (
    <div>
      <Header>Inside Project</Header>
      <PageContent>
        <LoadingAndError
          loading={projectLoading || batchesLoading}
          error={projectError || batchesError}
          data={Boolean(project && batches)}
        >
          {project && batches && !batchesLoading && !projectLoading && (
            <Tabs
              activeKey={activeKey}
              onChange={setActiveKey}
              defaultActiveKey="project_structure"
              items={items}
            />
          )}
        </LoadingAndError>
      </PageContent>
    </div>
  );
};
