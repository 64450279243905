import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Progress,
  Popover,
  Badge,
  Checkbox,
  InputNumber,
} from "antd";
import React, { useCallback, useRef } from "react";
import {
  DownOutlined,
  FilterOutlined,
  DownloadOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import { useForm } from "antd/lib/form/Form";
import { LinkedIn, SearchOutlined } from "@mui/icons-material";

import { useNavigate } from "react-router";
import { useState } from "react";

import { useEffect } from "react";

import EditOutlined from "@mui/icons-material/EditOutlined";

import { DeleteOutline, InfoOutlined } from "@mui/icons-material";

import { useMemo } from "react";
import moment from "moment";
import { Search } from "@mui/icons-material";

import { ShowMoreCell } from "../../utils/ShowMoreCell";
import { isMaxCharCrossed, isNaturalNumber } from "../../utils/validations";
import { calculateProfilePercentage } from "../../utils/coachProfilePercentage copy";
import { Header } from "../header/Header";
import { PageContent } from "../page-content/PageContent";
import { CoachSampleUpload } from "./CoachSampleUpload";
import { CoachProfileModal } from "../coach-profile-modal/CoachProfileModal";
import {
  ageValidationOnBlur,
  ageValidationOnChange,
  isWholeNumber,
  minFeesPerDayValidationOnChange,
  minFeesPerHourValidationOnChange,
  validateEmailOnBlur,
  validateNameOnBlur,
  validateNameOnChange,
} from "../../utils/validation";
import { VALIDATE_TRIGGERS } from "../../utils/constants";
import { usePostApi } from "../hooks/usePostApi";
import { useGetApi } from "../hooks/useGetApi";

const PROJECT_STATUSES = ["Ongoing", "Completed"];

const getHrFromProjects = (projectId, projects) => {
  const project = projects.find((item) => item.id === projectId);
  return project.hr;
};

const applyFilters = (data, filterRows) => {
  let filteredData = [...data];

  filterRows?.forEach((row) => {
    if (row?.filterType && row?.selectedOption?.length > 0) {
      filteredData = filteredData?.filter((coach) => {
        const filterValue = coach[row?.filterType];

        if (Array.isArray(filterValue)) {
          // If the filter value is an array, check if any selected option is included
          return filterValue?.some((value) =>
            row?.selectedOption?.includes(value)
          );
        } else {
          // If the filter value is not an array, perform direct comparison
          return row?.selectedOption?.includes(
            row.filterType === "ctt_nctt" ||
              row.filterType === "active_inactive" ||
              row.filterType === "internal_coach"
              ? filterValue?.toString()
              : filterValue
          );
        }
      });

      // Set filterApplied to true for "age" and "min_fees" filters
      if (row.filterType === "age" || row.filterType === "min_fees") {
        row.filterApplied = true;
      }
    }
  });

  return filteredData;
};

const INITIAL_FILTER_ROWS = [
  {
    title: "Industry",
    filterType: "area_of_expertise",
    selectedOption: [],
    searchText: "",
  },
  {
    title: "Level",
    filterType: "level",
    selectedOption: [],
    searchText: "",
  },
  {
    title: "Gender",
    filterType: "gender",
    selectedOption: [],
    searchText: "",
  },
  {
    title: "Topic",
    filterType: "topic",
    selectedOption: [],
    searchText: "",
  },
  {
    title: "Fee Per Hour",
    filterType: "fees_per_hour",
    selectedOption: [],
    searchText: "",
  },
  {
    title: "Fee Per Day",
    filterType: "fees_per_day",
    selectedOption: [],
    searchText: "",
  },
  {
    title: "City",
    filterType: "city",
    selectedOption: [],
    searchText: "",
  },
  {
    title: "Country",
    filterType: "country",
    selectedOption: [],
    searchText: "",
  },
  {
    title: "Age",
    filterType: "age",
    selectedOption: [],
    searchText: "",
    filterApplied: false,
  },
  {
    title: "Job Roles",
    filterType: "job_roles",
    selectedOption: [],
    searchText: "",
  },
  {
    title: "Language",
    filterType: "language",
    selectedOption: [],
    searchText: "",
  },
  {
    title: "Institution",
    filterType: "education",
    selectedOption: [],
    searchText: "",
  },
  {
    title: "Corporate YOE",
    filterType: "years_of_corporate_experience",
    selectedOption: [],
    searchText: "",
  },
  {
    title: "Functional Domain",
    filterType: "domain",
    selectedOption: [],
    searchText: "",
  },
  {
    title: "Client Companies",
    filterType: "client_companies",
    selectedOption: [],
    searchText: "",
  },
  {
    title: "Educational Qualification",
    filterType: "educational_qualification",
    selectedOption: [],
    searchText: "",
  },
];

export const ViewFacilitator = () => {
  const filterButtonRef = useRef(null);
  const [value, setValue] = useState(null);
  const [projectStatus, setProjectStatus] = useState(PROJECT_STATUSES[0]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isDeleteProfileModalOpen, setIsDeleteProfileModalOpen] =
    useState(null);
  const [isInviteCoachModal, setIsInviteCoachModal] = useState(false);
  const [showProfile, setShowProfile] = useState(null);
  const [filterRows, setFilterRows] = useState(INITIAL_FILTER_ROWS);
  const [selectedFilterColumn, setSelectedFilterColumn] =
    useState("area_of_expertise");
  const [filterDataSourceNull, setFilterDataSourceNull] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isModalOpenShareProfile, setIsModalOpenShareProfile] = useState(false);
  const [filterAge, setFilterAge] = useState("");
  const [filterMinimumFees, setFilterMinimumFees] = useState("");
  const [searchText1, setSearchText] = useState("");
  const [searcheddata, setSearchedData] = useState(null);
  const [filterFeesPerDay, setFilterFeesPerDay] = useState("");
  const [filterFeesPerHour, setFilterFeesPerHour] = useState("");

  const {
    data: coachFieldsData,
    isLoading: coachFieldsLoading,
    error: coachFieldsError,
    getData: coachFieldsGetData,
  } = useGetApi(`${process.env.REACT_APP_BASE_URL}/api/coach-field-values/`);

  const {
    data: projectData,
    isLoading: projectLoading,
    error: projectError,
    getData,
  } = useGetApi(
    `${
      process.env.REACT_APP_BASE_URL
    }/api/projects/${projectStatus.toLowerCase()}/`
  );

  const {
    data: sendConsentData,
    isLoading: sendConsetLoading,
    postData: sendConsent,
    resetState: resetConsentState,
  } = usePostApi(`${process.env.REACT_APP_BASE_URL}/api/send_consent/`);

  const handleOpenChange = (newOpen) => {
    setPopoverOpen(newOpen);
  };
  const showModalShareProfile = () => {
    setIsModalOpenShareProfile(true);
  };

  const handleOkShareProfile = () => {
    setIsModalOpenShareProfile(false);
  };

  const askConsentHandler = (values) => {
    sendConsent({
      coach_list: selectedRowKeys,
      consent_expiry_date: values.date.format("YYYY-MM-DD"),
      project_id: values.project,
    });
  };

  const navigate = useNavigate();

  const handleDownloadCertificate = useCallback((pdfUrl) => {
    window.open(pdfUrl, "_blank");
  }, []);

  const [form] = Form.useForm();
  const [askConsentForm] = Form.useForm();

  // const {
  //   data,
  //   isLoading,
  //   error,
  //   getData: getCoachData,
  // } = useGetApi(`${process.env.REACT_APP_BASE_URL}/api/coaches/all/`);

  const {
    data,
    isLoading,
    error,
    getData: getCoachData,
  } = useGetApi(`${process.env.REACT_APP_BASE_URL}/schedular/facilitators/`);

  console.log({ data });

  const {
    data: coachInProject,
    isLoading: isCoachInProjectLoading,
    error: coachInProjectError,
    getData: getCoachInProject,
  } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/api/coaches-which-are-included-in-projects/`
  );

  const {
    data: deleteCoachData,
    isLoading: deleteCoachLoading,
    error: deleteCoachError,
    postData: deleteCoach,
    resetState: deleteCoachResetState,
  } = usePostApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/facilitator/delete/`
  );

  const {
    data: inviteCoachData,
    isLoading: inviteCoachLoading,
    postData: inviteCoach,
    resetState: inviteCoachResetState,
  } = usePostApi(`${process.env.REACT_APP_BASE_URL}/api/coach-invites/create/`);

  const inviteCancelHandler = () => {
    form.resetFields();
    setIsInviteCoachModal(false);
  };

  const inviteConfirmHandler = () => {
    const values = form.getFieldsValue();
    inviteCoach(values);
  };

  const clearSearch = () => {
    setSearchText("");
    setSearchedData(null);
  };

  // let dataSource = useMemo(() => {
  //   if (data) {
  //     return data?.map((coach) => {
  //       const coachName = coach?.first_name + " " + coach?.last_name;
  //       const profilePercentage = calculateProfilePercentage(coach, "coach");
  //       return { ...coach, coachName, key: coach?.id, profilePercentage };
  //     });
  //   } else return [];
  // }, [data]);

  let dataSource = useMemo(() => {
    if (data) {
      return data?.map((coach, index) => {
        const sl_no = index + 1;
        const coachName = coach?.first_name + " " + coach?.last_name;
        const profilePercentage = calculateProfilePercentage(coach, "coach");
        return {
          ...coach,
          coachName,
          key: coach?.id,
          profilePercentage,
          sl_no,
        };
      });
    } else return [];
  }, [data]);

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    if (typeof searchText === "string") {
      searchText = searchText.toLowerCase();
    }
    let selectedData = [];
    let uniqueCoachIds = [];
    for (
      let filteredDataElement = 0;
      filteredDataElement < filteredDataSource?.length;
      filteredDataElement++
    ) {
      let filteredCoachData = filteredDataSource[filteredDataElement];
      for (let filteredCoachFields in filteredCoachData) {
        if (
          filteredCoachFields === "id" ||
          filteredCoachFields === "room_id" ||
          filteredCoachFields === "linkedin_profile_link" ||
          filteredCoachFields === "ctt_nctt" ||
          filteredCoachFields === "internal_coach" ||
          filteredCoachFields === "active_inactive" ||
          filteredCoachFields === "profilePercentage" ||
          filteredCoachFields === "created_at" ||
          filteredCoachFields === "edited_at" ||
          filteredCoachFields === "user" ||
          filteredCoachFields === "key" ||
          filteredCoachFields === "other_certification" ||
          filteredCoachFields === "corporate_experience" ||
          filteredCoachFields === "coaching_experience" ||
          filteredCoachFields === "currency" ||
          filteredCoachFields === "education_pic" ||
          filteredCoachFields === "education_upload_file" ||
          filteredCoachFields === "topic" ||
          filteredCoachFields === "city" ||
          filteredCoachFields === "country" ||
          filteredCoachFields === "fees_per_hour" ||
          filteredCoachFields === "fees_per_day"
        ) {
          continue;
        } else {
          let filteredCoachValue = filteredCoachData[filteredCoachFields];
          if (
            typeof filteredCoachValue === "number" &&
            Number.isFinite(filteredCoachValue) &&
            !Number.isInteger(filteredCoachValue)
          ) {
            let conval = filteredCoachValue.toString();
            if (
              typeof conval === "string" &&
              conval.includes(searchText.toString())
            ) {
              if (!uniqueCoachIds.includes(filteredCoachData.id)) {
                selectedData.push(filteredCoachData);
                uniqueCoachIds.push(filteredCoachData.id);
                break;
              }
            }
          } else if (Array.isArray(filteredCoachValue)) {
            for (var filteredElement in filteredCoachValue) {
              var nestedFilteredElement = filteredCoachValue[filteredElement];
              if (
                typeof nestedFilteredElement === "string" &&
                nestedFilteredElement.toLowerCase().includes(searchText) &&
                !uniqueCoachIds.includes(filteredCoachData.id)
              ) {
                selectedData.push(filteredCoachData);
                uniqueCoachIds.push(filteredCoachData.id);
                break;
              }
            }
          } else if (
            typeof filteredCoachValue === "string" &&
            filteredCoachValue.toLowerCase().includes(searchText)
          ) {
            if (!uniqueCoachIds.includes(filteredCoachData.id)) {
              selectedData.push(filteredCoachData);
              uniqueCoachIds.push(filteredCoachData.id);
              break;
            }
          } else if (
            !isNaN(searchText) &&
            typeof filteredCoachValue === "string" &&
            filteredCoachValue.includes(searchText)
          ) {
            var searchTextElement = searchText;
            if (searchTextElement !== String) {
              var filteredTextElement =
                searchTextElement.toString(filteredCoachValue);
            }
            if (
              typeof filteredTextElement === "string" &&
              filteredTextElement.includes(searchTextElement)
            ) {
              if (!uniqueCoachIds.includes(filteredCoachData.id)) {
                selectedData.push(filteredCoachData);
                uniqueCoachIds.push(filteredCoachData.id);
                break;
              }
            }
          }
        }
      }
    }

    setSearchedData(selectedData);
  };

  useEffect(() => {
    if (inviteCoachData) {
      form.resetFields();
      inviteCoachResetState();
      setIsInviteCoachModal(false);
    }
  }, [inviteCoachData, form, inviteCoachResetState]);

  useEffect(() => {
    if (sendConsentData) {
      setIsModalOpenShareProfile(false);
      resetConsentState();
      setSelectedRowKeys([]);
      askConsentForm.resetFields();
      navigate(`/projects/caas/progress/${value}`);
    }
  }, [sendConsentData, resetConsentState]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys?.length > 0;

  const projectOptions = useMemo(() => {
    if (projectData) {
      const res = [];
      projectData?.forEach((project) => {
        res.push({
          value: project.id,
          label: project.name,
        });
      });
      return res;
    }
    return [];
  }, [projectData]);

  const disabledDate = (current) => {
    // Disable dates today and before today's date
    return current && current < moment().endOf("day");
  };

  useEffect(() => {
    console.log({ deleteCoachData });

    if (deleteCoachData) {
      deleteCoachResetState();
      getCoachData();
      coachFieldsGetData();
      setIsDeleteProfileModalOpen(null);
    }
  }, [deleteCoachData]);

  const viewProfileModal = showProfile && (
    <Modal onCancel={() => setShowProfile(null)} footer={false} open={true}>
      <CoachProfileModal
        showProfile={showProfile}
        setShowProfile={setShowProfile}
      />
    </Modal>
  );

  const deleteProfileModal = isDeleteProfileModalOpen && (
    <Modal
      onCancel={() => setIsDeleteProfileModalOpen(null)}
      title="Delete coach profile"
      open={true}
      footer={null}
    >
      Once deleted, you cannot restore. Are you sure you want to delete the
      coach?
      <div className="mt-[1rem] ml-auto flex gap-2">
        <Button
          onClick={() => {
            deleteCoach({
              facilitator_id: isDeleteProfileModalOpen?.id,
            });
          }}
        >
          Delete
        </Button>
        <Button onClick={() => setIsDeleteProfileModalOpen(null)}>
          Cancel
        </Button>
      </div>
    </Modal>
  );

  const handleRemoveAgeOrMinimumFeesFilter = () => {
    if (selectedFilterColumn === "age") {
      setFilterRows((prev) =>
        prev.map((row) => {
          if (row.filterType === selectedFilterColumn) {
            return {
              ...row,
              selectedOption: [],
              filterApplied: false,
            };
          }
          return row;
        })
      );
    } else if (
      selectedFilterColumn === "fees_per_hour" ||
      selectedFilterColumn === "fees_per_day"
    ) {
      setFilterRows((prev) =>
        prev.map((row) => {
          if (row.filterType === selectedFilterColumn) {
            return {
              ...row,
              selectedOption: [],
              filterApplied: false,
            };
          }
          return row;
        })
      );
    }

    const hasOtherFilters = filterRows.some(
      (row) =>
        row.filterType !== selectedFilterColumn &&
        row.selectedOption?.length > 0
    );
    setFilterDataSourceNull(false);
    if (!hasOtherFilters) {
      applyFilters(dataSource, filterRows);
    }
  };

  const getFilterOptions = (data) => {
    console.log("get filter option running");
    const options = {
      // coachName: new Set(),
      // email: new Set(),
      area_of_expertise: new Set(),
      level: new Set(),
      coaching_hours: new Set(),
      active_inactive: new Set(),
      ctt_nctt: new Set(),
      gender: new Set(),
      min_fees: new Set(),
      age: new Set(),
      location: new Set(),
      job_roles: new Set(),
      language: new Set(),
      education: new Set(),
      years_of_corporate_experience: new Set(),
      years_of_coaching_experience: new Set(),
      domain: new Set(),
      client_companies: new Set(),
      educational_qualification: new Set(),
      internal_coach: new Set(),
      city: new Set(),
      country: new Set(),
      topic: new Set(),
      fees_per_hour: new Set(),
      fees_per_day: new Set(),
    };

    if (data) {
      data.forEach((eachData) => {
        // coachName
        // if (eachData?.coachName) {
        //   options.coachName.add(eachData.coachName);
        // }

        // email
        // if (eachData?.email && typeof eachData?.email === "string") {
        //   options.email.add(eachData.email);
        // }

        // area_of_expertise
        if (Array.isArray(eachData?.area_of_expertise)) {
          eachData.area_of_expertise.forEach((expertise) => {
            if (typeof expertise === "string") {
              options.area_of_expertise.add(expertise);
            }
          });
        }
        // city
        if (Array.isArray(eachData?.city)) {
          eachData.city.forEach((city) => {
            if (typeof city === "string") {
              options.city.add(city);
            }
          });
        }
        // topic

        if (Array.isArray(eachData?.topic)) {
          eachData.topic.forEach((topic) => {
            if (typeof topic === "string") {
              options.topic.add(topic);
            }
          });
        }

        // country
        if (Array.isArray(eachData?.country)) {
          eachData.country.forEach((country) => {
            if (typeof country === "string") {
              options.country.add(country);
            }
          });
        }

        // level
        if (Array.isArray(eachData?.level)) {
          eachData.level.forEach((level) => {
            if (typeof level === "string") {
              options.level.add(level);
            }
          });
        }

        // coaching_hours
        if (
          eachData?.coaching_hours &&
          typeof eachData?.coaching_hours === "string"
        ) {
          options.coaching_hours.add(eachData.coaching_hours);
        }

        // active_inactive
        if (eachData?.active_inactive !== undefined) {
          const labelValue = eachData?.active_inactive ? "true" : "false";
          options.active_inactive.add(labelValue);
        }

        // ctt_nctt
        if (eachData?.ctt_nctt !== undefined) {
          const labelValue = eachData?.ctt_nctt ? "true" : "false";
          options.ctt_nctt.add(labelValue);
        }

        // gender
        if (eachData?.gender && typeof eachData?.gender === "string") {
          options.gender.add(eachData.gender);
        }

        // min_fees
        if (eachData?.min_fees && typeof eachData?.min_fees === "string") {
          options.min_fees.add(eachData.min_fees);
        }

        // fee_per_hour
        if (
          eachData?.fees_per_hour &&
          typeof eachData?.fees_per_hour === "string"
        ) {
          options.fees_per_hour.add(eachData.fees_per_hour);
        }

        // fee_per_day
        if (
          eachData?.fees_per_day &&
          typeof eachData?.fees_per_day === "string"
        ) {
          options.fees_per_day.add(eachData.fees_per_day);
        }

        // age
        if (eachData?.age && typeof eachData?.age === "string") {
          options.age.add(eachData.age);
        }

        // location
        if (Array.isArray(eachData?.location)) {
          eachData.location.forEach((value) => {
            if (typeof value === "string") {
              options.location.add(value);
            }
          });
        }

        // job_roles
        if (Array.isArray(eachData?.job_roles)) {
          eachData.job_roles.forEach((value) => {
            if (typeof value === "string") {
              options.job_roles.add(value);
            }
          });
        }

        // language
        if (Array.isArray(eachData?.language)) {
          eachData.language.forEach((value) => {
            if (typeof value === "string") {
              options.language.add(value);
            }
          });
        }

        // education
        if (Array.isArray(eachData?.education)) {
          eachData.education.forEach((value) => {
            if (typeof value === "string") {
              options.education.add(value);
            }
          });
        }

        // years_of_corporate_experience
        if (
          eachData?.years_of_corporate_experience &&
          typeof eachData?.years_of_corporate_experience === "string"
        ) {
          options.years_of_corporate_experience.add(
            eachData.years_of_corporate_experience
          );
        }

        // years_of_coaching_experience
        if (
          eachData?.years_of_coaching_experience &&
          typeof eachData?.years_of_coaching_experience === "string"
        ) {
          options.years_of_coaching_experience.add(
            eachData.years_of_coaching_experience
          );
        }

        // domain
        // if (eachData?.domain && typeof eachData?.domain === "string") {
        //   options.domain.add(eachData.domain);
        // }
        if (Array.isArray(eachData?.domain)) {
          eachData.domain.forEach((value) => {
            if (typeof value === "string") {
              options.domain.add(value);
            }
          });
        }

        //client_companies
        if (Array.isArray(eachData?.client_companies)) {
          eachData.client_companies.forEach((client) => {
            if (typeof client === "string") {
              options.client_companies.add(client);
            }
          });
        }
        //educational_qualification
        if (Array.isArray(eachData?.educational_qualification)) {
          eachData.educational_qualification.forEach((qualification) => {
            if (typeof qualification === "string") {
              options.educational_qualification.add(qualification);
            }
          });
        }

        // internal_coach
        if (eachData?.internal_coach !== undefined) {
          const labelValue = eachData?.internal_coach ? "true" : "false";
          options.internal_coach.add(labelValue);
        }
      });
    }

    const filterOptions = {};
    for (const key in options) {
      filterOptions[key] = Array.from(options[key]).map((value) => ({
        label: value,
        value: value,
      }));
    }

    return filterOptions;
  };

  const filterOptions = useMemo(() => {
    return getFilterOptions(dataSource);
  }, [dataSource]);

  const filteredDataSource = useMemo(() => {
    return applyFilters(dataSource, filterRows);
  }, [filterRows, dataSource]);

  const handleFilterOptionChange = (
    event,
    optionValue,
    selectedFilterColumn
  ) => {
    setFilterRows((prev) =>
      prev?.map((row) => {
        if (row?.filterType === selectedFilterColumn) {
          return {
            ...row,
            selectedOption: event?.target?.checked
              ? [...row?.selectedOption, optionValue]
              : row?.selectedOption?.filter((option) => option !== optionValue),
          };
        } else {
          return row;
        }
      })
    );
  };
  const handleAgeAndFeesFilterOptionChange = (
    event,
    optionValue,
    selectedFilterColumn
  ) => {
    setFilterRows((prev) =>
      prev.map((row) => {
        if (row?.filterType === selectedFilterColumn) {
          let updatedSelectedOption;
          if (event?.target?.checked) {
            if (!row?.selectedOption?.includes(optionValue)) {
              updatedSelectedOption = [...row?.selectedOption, optionValue];
            } else {
              updatedSelectedOption = row?.selectedOption;
            }
          } else {
            updatedSelectedOption = row?.selectedOption?.filter(
              (option) => option !== optionValue
            );
          }
          return {
            ...row,
            selectedOption: updatedSelectedOption,
          };
        } else {
          return row;
        }
      })
    );
  };

  const selectedRow = useMemo(() => {
    return filterRows?.find(
      (item) => item?.filterType === selectedFilterColumn
    );
  }, [filterRows, selectedFilterColumn]);

  const handleClearFilters = () => {
    setFilterRows(INITIAL_FILTER_ROWS);
    setFilterDataSourceNull(false);
    setFilterAge("");
    setFilterMinimumFees("");
  };

  const text = (
    <div className="flex px-2">
      <div>Filter</div>
      <div
        className="ml-auto text-gray-500 cursor-pointer"
        onClick={handleClearFilters}
      >
        Clear
      </div>
    </div>
  );
  const updateSelectedOptionsForAgeAndFees = () => {
    // Clear the selectedOption array before mapping
    setFilterRows((prev) =>
      prev?.map((row) => {
        if (row?.filterType === selectedFilterColumn) {
          return {
            ...row,
            selectedOption: [],
          };
        } else {
          return row;
        }
      })
    );

    const filteredOptions = filterOptions[selectedFilterColumn]?.filter(
      (item) => item?.label?.toLowerCase()?.includes(selectedRow?.searchText)
    );

    const hasOptionsBelow = filteredOptions?.some(
      (option) =>
        parseInt(option?.label) <
        (selectedFilterColumn === "age"
          ? filterAge?.target?.value
          : selectedFilterColumn === "fees_per_day"
          ? filterFeesPerDay?.target.value
          : selectedFilterColumn === "fees_per_hour"
          ? filterFeesPerHour?.target.value
          : "")
    );

    if (!hasOptionsBelow) {
      setFilterDataSourceNull(true);
      setFilterRows((prev) =>
        prev?.map((row) => {
          if (row?.filterType === selectedFilterColumn) {
            return {
              ...row,
              selectedOption: [
                selectedFilterColumn === "age"
                  ? filterAge?.target?.value
                  : selectedFilterColumn === "fees_per_day"
                  ? filterFeesPerDay?.target.value
                  : selectedFilterColumn === "fees_per_hour"
                  ? filterFeesPerHour?.target.value
                  : "",
              ],
            };
          } else {
            return row;
          }
        })
      );
    } else {
      setFilterDataSourceNull(false);
    }

    filteredOptions
      ?.filter(
        (option) =>
          parseInt(option?.label) <
          (selectedFilterColumn === "age"
            ? filterAge?.target?.value
            : selectedFilterColumn === "fees_per_day"
            ? filterFeesPerDay?.target.value
            : selectedFilterColumn === "fees_per_hour"
            ? filterFeesPerHour?.target.value
            : "")
      )
      ?.forEach((option) => {
        handleAgeAndFeesFilterOptionChange(
          { target: { checked: true } },
          option?.value,
          selectedFilterColumn
        );
      });
  };
  const handleActiveCttNames = (value) => {
    const answer =
      value === "true" && selectedFilterColumn === "active_inactive"
        ? "Active"
        : value === "false" && selectedFilterColumn === "active_inactive"
        ? "Inactive"
        : value === "true" && selectedFilterColumn === "ctt_nctt"
        ? "CTT"
        : value === "false" && selectedFilterColumn === "ctt_nctt"
        ? "Non CTT"
        : value === "true" && selectedFilterColumn === "internal_coach"
        ? "Yes"
        : value === "false" && selectedFilterColumn === "internal_coach"
        ? "No"
        : value;
    return answer;
  };

  const totalFilters = useMemo(() => {
    if (filterRows) {
      let count = 0;
      filterRows?.forEach((row) => {
        if (row?.selectedOption?.length > 0) {
          count += 1;
        }
      });
      return count;
    }
    return 0;
  }, [filterRows]);

  const columnForCoaches = [
    {
      title: "Sl No.",
      dataIndex: "sl_no",
      key: "sl_no",
      align: "center",
      fixed: "left",
      width: 90,
    },
    {
      title: "Facilitator",
      dataIndex: "coachName",
      key: "coachName",
      fixed: "left",
      width: 140,
      align: "center",

      render: (text, coach) => (
        // <Link to={`/profileandfeedback/${record.id}`}>{text}</Link>
        <>
          <span
          // className="cursor-pointer hover: text-primary-1 m-hyperlink-cta"
          // onClick={() => setShowProfile(coach)}
          >
            <Tooltip title="">{text}</Tooltip>
          </span>
        </>
      ),
    },
    // {
    //   title: "Profile Completion",
    //   dataIndex: "profilePercentage",
    //   key: "profilePercentage",
    //   width: 150,
    //   align: "center",
    //   render: (profilePercentage) => {
    //     const integerPercentage = Math?.floor(profilePercentage);
    //     return (
    //       <Progress
    //         percent={integerPercentage}
    //         strokeColor={{ from: "#CC3A92", to: "#7E39A4" }}
    //       />
    //     );
    //   },
    // },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      // fixed: "left",
      width: 200,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      // fixed: "left",
      width: 160,
    },
    {
      title: "Topic",
      dataIndex: "topic",
      key: "topic",
      align: "center",
      width: 200,

      render: (topic, record) => <ShowMoreCell data={topic} maxItems={2} />,
    },
    {
      title: "Industry",
      dataIndex: "area_of_expertise",
      key: "area_of_expertise",
      align: "center",
      width: 200,

      render: (area_of_expertise, record) => (
        <ShowMoreCell data={area_of_expertise} maxItems={2} />
      ),
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      align: "center",
      width: 120,

      render: (level, record) => <ShowMoreCell data={level} maxItems={2} />,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      align: "center",
      width: 120,
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
      align: "center",
      width: 80,
    },
    {
      title: "Job Roles",
      dataIndex: "job_roles",
      key: "job_roles",
      align: "center",
      width: 180,

      render: (job_roles, record) => (
        <ShowMoreCell data={job_roles} maxItems={2} />
      ),
    },
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
      align: "center",
      width: 180,
      render: (language, record) => (
        <ShowMoreCell data={language} maxItems={2} />
      ),
    },
    {
      title: "Companies Worked",
      dataIndex: "companies_worked_in",
      key: "companies_worked_in",
      align: "center",
      width: 180,
      render: (companies_worked_in, record) => (
        <ShowMoreCell data={companies_worked_in} maxItems={2} />
      ),
    },
    {
      title: "Linkedin Profile",
      dataIndex: "linkedin_profile_link",
      key: "linkedin_profile_link",
      align: "center",
      width: 180,
      render: (linkedin_profile_link) =>
        linkedin_profile_link ? (
          <p class="text-ellipsis overflow-hidden">
            <a
              className="text-blue-600 border-0 border-b"
              target="_blank"
              rel="noreferrer"
              href={linkedin_profile_link}
            >
              <Tooltip title={linkedin_profile_link}>
                <span>
                  <LinkedIn />
                </span>
                {/* {linkedin_profile_link} */}
              </Tooltip>
            </a>
          </p>
        ) : (
          <></>
        ),
    },
    {
      title: (
        <Tooltip
          className="flex items-center gap-2"
          title="Corporate years of experience"
        >
          Corporate YOE <InfoOutlined className="!text-[14px]" />
        </Tooltip>
      ),
      dataIndex: "years_of_corporate_experience",
      key: "years_of_corporate_experience",
      align: "center",
      width: 180,
    },

    {
      title: "Fees Per Hour",
      dataIndex: "fees_per_hour",
      key: "fees_per_hour",
      align: "center",
      // fixed: "left",
      width: 200,
    },
    {
      title: "Fees Per Day",
      dataIndex: "fees_per_day",
      key: "fees_per_day",
      align: "center",
      // fixed: "left",
      width: 200,
    },

    {
      title: "Functional Domain",
      dataIndex: "domain",
      key: "domain",
      align: "center",
      width: 180,
      render: (domain, record) => <ShowMoreCell data={domain} maxItems={2} />,
    },

    {
      title: "Client Companies",
      dataIndex: "client_companies",
      key: "client_companies",
      align: "center",
      width: 180,
      render: (client_companies, record) => (
        <ShowMoreCell data={client_companies} maxItems={2} />
      ),
    },

    {
      title: "Institution",
      dataIndex: "education",
      key: "education",
      align: "center",
      width: 180,
      render: (education, record) => (
        <ShowMoreCell data={education} maxItems={2} />
      ),
    },
    {
      title: "Educational Qualification",
      dataIndex: "educational_qualification",
      key: "educational_qualification",
      align: "center",
      width: 180,
      render: (educational_qualification, record) => (
        <ShowMoreCell data={educational_qualification} maxItems={2} />
      ),
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      width: 120,
      fixed: "right",
      render: (_, coach) => (
        <div className="flex gap-2">
          <Tooltip title="Edit profile" className="cursor-pointer ">
            <EditOutlined
              onClick={() =>
                navigate("/add/facilitator#edit", { state: { coach } })
              }
              className="hover:text-primary-1 "
            />
          </Tooltip>

          {/* {coachInProject?.includes(coach?.id) ||
          isCoachInProjectLoading ||
          coachInProjectError ? (
            <Tooltip
              className="cursor-not-allowed"
              title={
                coachInProjectError ? "Delete Disabled" : "Project in Progress"
              }
            >
              <DeleteOutline className="text-gray-300" />
            </Tooltip>
          ) : ( */}
          <Tooltip className="cursor-pointer" title="Delete Coach">
            <DeleteOutline
              onClick={() => setIsDeleteProfileModalOpen(coach)}
              className="hover:text-primary-1"
            />
          </Tooltip>
          {/* )} */}
        </div>
      ),
    },
  ];

  if (isLoading || coachFieldsLoading) {
    return (
      <div className="flex justify-center">
        {" "}
        <Spin />
      </div>
    );
  } else if (error || coachFieldsError) {
    return (
      <div className="flex justify-center text-gray-400 font-semibold">
        Failed to load..
      </div>
    );
  } else if (coachFieldsData && data) {
    return (
      <div>
        <Header>
          Facilitators
          {/* <span class="text-gray-400">
            {" "}
            (
            {filterDataSourceNull
              ? 0
              : searchText1
              ? searcheddata?.length
              : `showing ${filteredDataSource.length} of ${dataSource?.length} `}
            )
          </span> */}
        </Header>

        <Space className="ml-auto" size={24}></Space>
        <PageContent>
          <div className="flex">
            <div className="flex justify-start">
              <Input
                placeholder="Search..."
                value={searchText1}
                onChange={(event) => handleSearch(event.target.value)}
                prefix={<SearchOutlined style={{ color: "gray" }} />}
                suffix={
                  searchText1 && (
                    <CloseCircleOutlined
                      style={{ color: "gray", cursor: "pointer" }}
                      onClick={clearSearch}
                    />
                  )
                }
              />
            </div>
            <Space className="ml-auto" size={24}>
              {/* <Button
                type="text"
                disabled={!hasSelected}
                onClick={showModalShareProfile}
              >
                Ask Consent
              </Button> */}

              <Modal
                title="Consent Request"
                footer={null}
                open={isModalOpenShareProfile}
                onOk={handleOkShareProfile}
                onCancel={() => {
                  askConsentForm?.resetFields();
                  setIsModalOpenShareProfile(false);
                }}
              >
                <span>Coaches Selected: {selectedRowKeys?.length}</span>
                <Form form={askConsentForm} onFinish={askConsentHandler}>
                  <div className="mt-[2rem]">
                    <Form.Item
                      name="date"
                      rules={[
                        {
                          required: true,
                          message: "Please select the date.",
                        },
                      ]}
                    >
                      <DatePicker
                        disabledDate={disabledDate}
                        format={"DD-MM-YYYY"}
                      />
                    </Form.Item>
                  </div>
                  <div className="mt-[2rem]">
                    <Form.Item
                      name="project"
                      rules={[
                        {
                          required: true,
                          message: "Please select the project.",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        value={value}
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                        style={{
                          width: "37%",
                        }}
                        options={projectOptions}
                        placeholder="Select Project"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            ?.toLowerCase()
                            ?.includes(input?.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </div>
                  {projectOptions?.length > 0 ? (
                    <></>
                  ) : (
                    <p className="text-gray-400 mt-4">No projects available</p>
                  )}
                  <div className="mt-[2rem]">
                    HR Email:{" "}
                    {value ? (
                      getHrFromProjects(value, projectData)[0]?.email
                    ) : (
                      <span className="m-body3 ml-[1rem]">N/A</span>
                    )}
                  </div>
                  <div className="flex justify-end">
                    <Button htmlType="submit" loading={sendConsetLoading}>
                      Ask Consent
                    </Button>
                  </div>
                </Form>
              </Modal>
              <div>
                <Popover
                  placement="bottomRight"
                  title={text}
                  content={
                    FilterComponent ? (
                      <FilterComponent
                        filterRows={filterRows}
                        selectedFilterColumn={selectedFilterColumn}
                        setSelectedFilterColumn={setSelectedFilterColumn}
                        filterAge={filterAge}
                        setFilterAge={setFilterAge}
                        filterMinimumFees={filterMinimumFees}
                        setFilterMinimumFees={setFilterMinimumFees}
                        selectedRow={selectedRow}
                        setFilterRows={setFilterRows}
                        filterOptions={filterOptions}
                        handleFilterOptionChange={handleFilterOptionChange}
                        handleActiveCttNames={handleActiveCttNames}
                        updateSelectedOptionsForAgeAndFees={
                          updateSelectedOptionsForAgeAndFees
                        }
                        setPopoverOpen={setPopoverOpen}
                        filterButtonRef={filterButtonRef}
                        handleRemoveAgeOrMinimumFeesFilter={
                          handleRemoveAgeOrMinimumFeesFilter
                        }
                        filterFeesPerDay={filterFeesPerDay}
                        filterFeesPerHour={filterFeesPerHour}
                        setFilterFeesPerDay={setFilterFeesPerDay}
                        setFilterFeesPerHour={setFilterFeesPerHour}
                      />
                    ) : (
                      <div></div>
                    )
                  }
                  trigger="click"
                  open={popoverOpen}
                  onOpenChange={handleOpenChange}
                >
                  <Button ref={filterButtonRef} type="text">
                    <div className="flex gap-2 text-center">
                      <div>
                        <FilterOutlined className="text-[15px]" />
                      </div>
                      <div className="mt-[.1rem]">Filter </div>
                      <div>
                        {totalFilters ? (
                          <Badge
                            size={"10px"}
                            color={totalFilters ? "blue" : ""}
                            count={totalFilters || ""}
                          ></Badge>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    </div>
                  </Button>
                </Popover>
              </div>
              <Button type="text" onClick={handleClearFilters}>
                Remove all filters
              </Button>
              {/* <CoachSampleUpload
                disabled={filteredDataSource?.length === 0}
                coaches={filteredDataSource}
                title={
                  <span className="inline-flex items-center">Download</span>
                }
                showModal={true}
              /> */}
            </Space>
          </div>
          <Table
            // pagination={false}
            pagination={{
              showSizeChanger: true,
            }}
            className="mt-4 rounded-lg"
            columns={columnForCoaches}
            // rowSelection={rowSelection}
            dataSource={
              filterDataSourceNull
                ? null
                : searchText1
                ? searcheddata
                : filteredDataSource
            }
            scroll={
              searchText1 && searcheddata?.length === 0
                ? { x: true }
                : (searchText1 && searcheddata?.length > 0) ||
                  filteredDataSource?.length > 0
                ? { x: "max-content", y: 500 }
                : { x: true }
            }
          />
        </PageContent>

        {isInviteCoachModal && (
          <Modal
            title="Invite Coach"
            footer={false}
            open={true}
            onCancel={inviteCancelHandler}
          >
            <Form
              form={form}
              onFinish={inviteConfirmHandler}
              layout="vertical"
              className="px-4"
            >
              <Form.Item
                validateTrigger={VALIDATE_TRIGGERS}
                rules={[
                  {
                    validator: validateNameOnChange,
                    validateTrigger: "onChange",
                  },
                  {
                    validator: validateNameOnBlur,
                    validateTrigger: "onBlur",
                  },
                ]}
                className="mb-2"
                label="Name"
                name="name"
                required
              >
                <Input maxLength={31} placeholder="Input name" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                required
                validateTrigger={VALIDATE_TRIGGERS}
                rules={[
                  {
                    validator: () => Promise?.resolve(),
                    validateTrigger: "onChange",
                  },
                  {
                    validator: validateEmailOnBlur,
                    validateTrigger: "onBlur",
                  },
                ]}
              >
                <Input placeholder="Input email" />
              </Form.Item>
              <div className="flex gap-4 mt-4">
                <Form.Item>
                  <Button
                    // onClick={inviteConfirmHandler}
                    loading={inviteCoachLoading}
                    htmlType="submit"
                    type="primary"
                  >
                    Invite
                  </Button>
                </Form.Item>
                <Button onClick={inviteCancelHandler}>Cancel</Button>
              </div>
            </Form>
          </Modal>
        )}
        {viewProfileModal}
        {deleteProfileModal}
      </div>
    );
  } else {
    return (
      <div className="flex justify-center text-gray-400 font-semibold">
        Some error occured..
      </div>
    );
  }
};

export const FilterComponent = React.memo(
  ({
    filterRows,
    selectedFilterColumn,
    setSelectedFilterColumn,
    filterAge,
    filterMinimumFees,
    filterFeesPerDay,
    filterFeesPerHour,
    setFilterAge,
    setFilterFeesPerDay,
    setFilterFeesPerHour,
    setFilterMinimumFees,
    selectedRow,
    setFilterRows,
    filterOptions,
    handleFilterOptionChange,
    handleActiveCttNames,
    updateSelectedOptionsForAgeAndFees,
    setPopoverOpen,
    filterButtonRef,
    handleRemoveAgeOrMinimumFeesFilter,
  }) => {
    const targetDivRef = useRef(null);
    const [form] = useForm();

    const handleClickOutside = (event) => {
      const targetDiv = targetDivRef.current;
      const filterButton = filterButtonRef.current;

      if (!targetDiv || !filterButton) return;

      if (
        !filterButton.contains(event.target) &&
        !targetDiv.contains(event.target)
      ) {
        if (document.activeElement !== filterButton) {
          setPopoverOpen(false);
        }
      }
    };

    // Attach the event listener when the component mounts
    useEffect(() => {
      document.addEventListener("click", handleClickOutside);
      // Remove the event listener when the component unmounts
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, []);

    const minimumFeePerHour = (_, value) => {
      // if (!value?.trim()) {
      //   return Promise.reject("Please input the minimum coach price!");
      // }
      if (value && !isWholeNumber(value?.trim())) {
        return Promise.reject("Please enter valid price");
      }
      if (value && isMaxCharCrossed(value.trim(), 6)) {
        return Promise.reject("Price cannot be more than 2,00,000");
      }
      return Promise.resolve();
    };

    const minimumFeePerDay = (_, value) => {
      // if (!value?.trim()) {
      //   return Promise.reject("Please input the minimum coach price!");
      // }
      if (value && !isWholeNumber(value?.trim())) {
        return Promise.reject("Please enter valid price");
      }
      if (value && isMaxCharCrossed(value.trim(), 7)) {
        return Promise.reject("Price cannot be more than 10,00,000");
      }
      return Promise.resolve();
    };

    return (
      <div ref={targetDivRef}>
        <div className="flex gap-14 px-2 pb-4 h-80 ">
          <div className="overflow-y-auto custom-scrollbar pr-4 h-full">
            {filterRows?.map((item) => (
              <div
                className={
                  (selectedFilterColumn === item?.filterType
                    ? "bg-gray-200 p-1"
                    : "") +
                  "hover:bg-gray-200 p-1 flex justify-between gap-4 rounded-md border-b cursor-pointer mt-2 px-2"
                }
                onClick={() => setSelectedFilterColumn(item?.filterType)}
              >
                {item?.title}
                <div className="min-w-[2rem]">
                  <Badge
                    color={item?.selectedOption?.length ? "blue" : ""}
                    count={
                      item?.filterApplied === true
                        ? 1
                        : item?.selectedOption?.length
                        ? item?.selectedOption?.length
                        : ""
                    }
                  ></Badge>
                </div>
              </div>
            ))}
          </div>
          <div
            className="max-h-80 overflow-y-auto custom-scrollbar pr-2 "
            style={{ width: "250px" }}
          >
            <div>
              {selectedFilterColumn === "age" ||
              selectedFilterColumn === "fees_per_day" ||
              selectedFilterColumn === "fees_per_hour" ? (
                <div className="mt-2 min-w-[5rem]">
                  <p>
                    {selectedFilterColumn === "age"
                      ? "Age"
                      : selectedFilterColumn === "fees_per_day"
                      ? "Fees Per Day"
                      : selectedFilterColumn === "fees_per_hour"
                      ? "Fees Per Hour"
                      : ""}{" "}
                    Below:
                  </p>
                  {(selectedFilterColumn === "fees_per_day" ||
                    selectedFilterColumn === "fees_per_hour") && (
                    <p className="text-gray-500 text-xs w-40">
                      The facilitator displayed are the ones whose minimum fees
                      fall below the specified value.
                    </p>
                  )}
                  <div className="">
                    <Form form={form}>
                      <Form.Item
                        name={
                          selectedFilterColumn === "age"
                            ? "age"
                            : selectedFilterColumn === "fees_per_day"
                            ? "fees_per_day"
                            : selectedFilterColumn === "fees_per_hour"
                            ? "fees_per_hour"
                            : ""
                        }
                        labelCol={{ span: 24 }}
                        validateTrigger={VALIDATE_TRIGGERS}
                        rules={
                          selectedFilterColumn === "age"
                            ? [
                                {
                                  validator: ageValidationOnChange,
                                  validateTrigger: "onChange",
                                },
                                {
                                  validator: ageValidationOnBlur,
                                  validateTrigger: "onBlur",
                                },
                              ]
                            : selectedFilterColumn === "fees_per_hour"
                            ? [{ validator: minFeesPerHourValidationOnChange }]
                            : selectedFilterColumn === "fees_per_day"
                            ? [{ validator: minFeesPerDayValidationOnChange }]
                            : ""
                        }
                      >
                        <Input
                          maxLength={
                            selectedFilterColumn === "fees_per_day"
                              ? 7
                              : selectedFilterColumn === "fees_per_hour"
                              ? 6
                              : ""
                          }
                          className="mt-[1rem] mb-[1rem] w-[100%]"
                          placeholder={
                            selectedFilterColumn === "age"
                              ? "Enter Age"
                              : selectedFilterColumn === "fees_per_day"
                              ? "Enter fees per day"
                              : selectedFilterColumn === "fees_per_hour"
                              ? "Enter fees per hour"
                              : ""
                          }
                          value={
                            selectedFilterColumn === "age"
                              ? filterAge
                              : selectedFilterColumn === "fees_per_day"
                              ? filterFeesPerDay
                              : selectedFilterColumn === "fees_per_hour"
                              ? filterFeesPerHour
                              : ""
                          }
                          onChange={(value) =>
                            selectedFilterColumn === "age"
                              ? setFilterAge(value)
                              : selectedFilterColumn === "fees_per_day"
                              ? setFilterFeesPerDay(value)
                              : selectedFilterColumn === "fees_per_hour"
                              ? setFilterFeesPerHour(value)
                              : ""
                          }
                        />
                      </Form.Item>

                      <div className="flex justify-end mt-2">
                        <div className=" mr-2">
                          <Button
                            className="p-1"
                            onClick={handleRemoveAgeOrMinimumFeesFilter}
                            size="small"
                          >
                            Remove Filter
                          </Button>
                        </div>
                        <div>
                          <Button
                            className="p-1"
                            onClick={() => {
                              updateSelectedOptionsForAgeAndFees();
                            }}
                            size="small"
                            // disabled={
                            //   (selectedFilterColumn === "age" &&
                            //     (!isNaturalNumber(filterAge?.target?.value) ||
                            //       filterAge?.target?.value >= 100)) ||
                            //   (selectedFilterColumn === "fees_per_day" &&
                            //     (!isNaturalNumber(
                            //       filterMinimumFees?.target?.value
                            //     ) ||
                            //       filterMinimumFees?.target?.value >= 100000))
                            // }
                          >
                            Add Filter
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              ) : (
                <div className="mt-2 min-w-[5rem]">
                  <Input
                    className="mb-[1rem]"
                    prefix={<Search />}
                    placeholder="Search..."
                    value={selectedRow?.searchText}
                    onChange={(e) => {
                      const searchText = e?.target?.value?.toLowerCase();
                      setFilterRows((prev) =>
                        prev?.map((row) => {
                          if (row?.filterType === selectedRow?.filterType) {
                            return { ...row, searchText: searchText };
                          } else {
                            return { ...row };
                          }
                        })
                      );
                    }}
                  />
                  {filterOptions[selectedFilterColumn]
                    ?.filter((item) =>
                      item?.label
                        ?.toLowerCase()
                        ?.includes(selectedRow?.searchText)
                    )
                    ?.map((option) => (
                      <div className="mb-[0.3rem]" key={option?.value}>
                        <Checkbox
                          checked={selectedRow?.selectedOption?.includes(
                            option?.value
                          )}
                          onChange={(e) =>
                            handleFilterOptionChange(
                              e,
                              option?.value,
                              selectedFilterColumn
                            )
                          }
                        >
                          <div className="">
                            <Tooltip
                              title={handleActiveCttNames(option?.label)}
                            >
                              <h3 className="">
                                {handleActiveCttNames(option?.label)?.length <=
                                18 ? (
                                  handleActiveCttNames(option?.label)
                                ) : (
                                  <>
                                    {handleActiveCttNames(option?.label)?.slice(
                                      0,
                                      18
                                    )}
                                    ...
                                  </>
                                )}
                              </h3>
                            </Tooltip>
                          </div>
                        </Checkbox>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
