import React, { useMemo } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { USER_BASED_ROUTES } from "../../constants";

const AuthenticatedRoutes = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const { pathname } = useLocation();
  const userType = useMemo(() => {
    if (user) {
      return user.user.type;
    } else return undefined;
  }, [user]);

  let isRouteAllowed;
  if (isAuthenticated) {
    isRouteAllowed = USER_BASED_ROUTES[userType].includes(pathname);
    if (!isRouteAllowed) {
      // pmo
      if (userType === "pmo") {
        isRouteAllowed = pathname.startsWith("/projects/progress");
      }
    }
  }

  return isAuthenticated ? (
    isRouteAllowed ? (
      <div style={{ height: "100vh", overflowY: "auto" }} className="bg-bg-4">
        <Outlet />
      </div>
    ) : (
      <Navigate to="/page-not-found"></Navigate>
    )
  ) : (
    <Navigate to="/" replace={true} state={{ from: pathname }} />
  );
};

export { AuthenticatedRoutes };
