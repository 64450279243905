import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const deleteConfirmedSession = createAsyncThunk(
  "manageCoaches/deleteConfirmedSession",
  async (
    {
      learnerConfirmedSlotId,
      coachConfirmedSlotId,
      formData,
      setAlert,
      getConfirmedSlotsByEventId,
      expandedRowKeys,
      setDeleteSlotDetails,
      form,
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { adminName, requestedPerson, reason } = formData;
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_BASE_URL}/delete-booked-slots-of-learner/${learnerConfirmedSlotId}/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: {
          admin_name: adminName,
          requested_person: requestedPerson,
          reason,
          slot_id: coachConfirmedSlotId,
        },
      });
      if (response.status === 200) {
        dispatch(
          setAlert({
            message: "Coaching session deleted successfully!",
            isAlert: true,
            status: "success",
          })
        );
        form.setFieldsValue({
          adminName: null,
          requestedPerson: null,
          reason: null,
        });
        setDeleteSlotDetails(null);
        dispatch(getConfirmedSlotsByEventId(expandedRowKeys));
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: "Coaching session deletion is failed!",
          isAlert: true,
          status: "failure",
        })
      );
      return rejectWithValue(error);
    }
  }
);
