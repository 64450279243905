export const calculateProfilePercentage = (coachData, type) => {
    let excludedFields = [];
    if (type === 'coach') {
        excludedFields = ['created_at', 'edited_at', 'email', 'completed_sessions', 'user', 'room_id', 'phone', 'rating', 'is_approved', 'ctt_nctt', 'id', 'last_login','active_inactive'];
    } else if (type === 'pmo') {
        excludedFields = ['created_at', 'edited_at', 'completed_sessions', 'user', 'room_id', 'rating', 'is_approved', 'ctt_nctt', 'id', 'active_inactive'];
    }
    const totalFields = Object.keys(coachData).filter(field => !excludedFields.includes(field))
    const totalFieldsCount = totalFields.length
    let filledFields = 0;

    for (const field of totalFields) {
        if (Array.isArray(coachData[field])) {
            filledFields += coachData[field].length > 0 ? 1 : 0;
        } else {
            filledFields += coachData[field] ? 1 : 0;
        }
    }

    const coachProfileCompletionPercentage = (filledFields / totalFieldsCount) * 100;

    return coachProfileCompletionPercentage;
};
