import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCoaches = createAsyncThunk(
  "manageCoaches/getCoaches",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/coaches/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addCoach = createAsyncThunk(
  "manageCoaches/addCoach",
  async (
    { coachDetails, getCoaches, setAlert },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/add-coaches/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: coachDetails,
      });
      if (response.status === 200) {
        dispatch(
          setAlert({
            message: "Added coach successfully!",
            isAlert: true,
            status: "success",
          })
        );
        dispatch(getCoaches());
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: "Failed to add coach! Try again.",
          isAlert: true,
          status: "failure",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const updateCoach = createAsyncThunk(
  "manageCoaches/updateCoach",
  async (
    { coachDetails, id, getCoaches, setAlert },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/update-coach/${id}/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: coachDetails,
      });
      if (response.status === 200) {
        dispatch(
          setAlert({
            message: "updated coach successfully!",
            isAlert: true,
            status: "success",
          })
        );
        dispatch(getCoaches());
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: "Failed to update coach! Try again.",
          isAlert: true,
          status: "failure",
        })
      );
      return rejectWithValue(error);
    }
  }
);
