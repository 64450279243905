import { Button, Form, Input, Modal, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { AddRounded } from "@mui/icons-material";
import NoData from "../../assets/no_data.png";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import {
  getPhoneError,
  validateEmailOnBlur,
  validateLearnerNameOnBlur,
  validateLearnerNameOnChange,
} from "../../utils/validation";
import { VALIDATE_TRIGGERS } from "../../utils/constants";
import { usePostApi } from "../hooks/usePostApi";
import axios from "axios";
import { LearnerModal } from "../learner-modal/LearnerModal";
import { useGetApi } from "../hooks/useGetApi";
import { useMemo } from "react";
import { LoadingAndError } from "../loading-and-error/LoadingAndError";

export const AddCoachee = ({ batches, getBatches }) => {
  const [isModalOpen, setIsModalOpen] = useState(null);
  const [phoneError, setPhoneError] = useState();
  const [phone, setPhone] = useState();
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [isModalOpenBulkUpload, setIsModalOpenBulkUpload] = useState(null);
  const [form] = Form.useForm();

  const batchOptions = batches.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const [selectedBatchId, setSelectedBatchId] = useState(batches[0]?.id);

  const {
    data: batchData,
    isLoading: batchDataLoading,
    error: batchDataError,
    getData: getBatchData,
  } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/batch-details/${selectedBatchId}/`
  );

  const batch = useMemo(() => {
    if (selectedBatchId) {
      return batches.find((item) => item.id === selectedBatchId);
    }
    return null;
  }, [batches, selectedBatchId]);

  const {
    isLoading: isLoadingLearners,
    postData: addLearner,
    data: dataAddLeaner,
    resetState: resetStateAddLearner,
  } = usePostApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/create-schedular-participant/${batch?.id}/`
  );

  const validatePhone = () => {
    setPhoneError(getPhoneError(phone));
  };

  const handleAddLearners = async (values) => {
    if (phoneError) {
      return;
    }

    const err = getPhoneError(phone);
    if (err) {
      return setPhoneError(err);
    }

    const mergedObject = {
      ...values,
      phone: phone,
    };

    addLearner(mergedObject);
  };

  useEffect(() => {
    if (dataAddLeaner) {
      getBatchData();
      setIsModalOpen(false);
      resetStateAddLearner();
      form.resetFields();
      setPhone(null);
    }
  }, [dataAddLeaner, form, getBatchData, setIsModalOpen, resetStateAddLearner]);

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    setPhone(null);
  };

  const tableColumns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
  ];

  return (
    <>
      {batches?.length > 0 ? (
        <div>
          <div className="flex gap-2">
            <div className="flex gap-2 items-center">
              Batch:
              <Select
                placeholder={"Please select batch"}
                options={batchOptions}
                value={selectedBatchId}
                onChange={(value) => setSelectedBatchId(value)}
              ></Select>
            </div>
            <Button
              onClick={() => {
                setIsModalOpen(true);
              }}
              className="ml-auto"
            >
              Single Coachee
            </Button>

            <Button
              onClick={() => {
                setIsModalOpenBulkUpload(true);
              }}
            >
              Add Batch
            </Button>
          </div>
          <Modal
            title={<p className="mb-6">Add coachee in batch</p>}
            footer={null}
            open={isModalOpen}
            onCancel={handleCancel}
          >
            <div className="mb-[1rem]">Batch : {batch?.name}</div>
            <Form form={form} onFinish={handleAddLearners}>
              <Form.Item
                validateTrigger={VALIDATE_TRIGGERS}
                required
                rules={[
                  {
                    validator: validateLearnerNameOnChange,
                    validateTrigger: "onChange",
                  },
                  {
                    validator: validateLearnerNameOnBlur,
                    validateTrigger: "onBlur",
                  },
                ]}
                name="name"
                label="Name"
              >
                <Input maxLength={21} placeholder="Input your name." />
              </Form.Item>
              <Form.Item
                validateTrigger={VALIDATE_TRIGGERS}
                required
                rules={[
                  {
                    validator: () => Promise.resolve(),
                    validateTrigger: "onChange",
                  },
                  {
                    validator: validateEmailOnBlur,
                    validateTrigger: "onBlur",
                  },
                ]}
                name="email"
                label="Email"
              >
                <Input placeholder="Input your email." />
              </Form.Item>
              <div className="phone-input-wrapper flex">
                <p className="phone-input-label py-[0.2px]">Contact: </p>
                <div>
                  <PhoneInput
                    className={
                      "border px-2 py-1 rounded-lg" +
                      (phoneError ? " border-red-500" : "")
                    }
                    defaultCountry={"IN"}
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={(value) => {
                      setPhone(value);
                      setPhoneError(undefined);
                      if (value?.length > 14) {
                        setPhoneError("Max length exceeded.");
                      }
                    }}
                    maxLength={13}
                    onBlur={validatePhone}
                    error={
                      phone
                        ? isValidPhoneNumber(phone)
                          ? undefined
                          : "Invalid phone number"
                        : "Phone number required"
                    }
                  />
                  <p className="text-red-500">{phoneError}</p>{" "}
                </div>
              </div>
              <div className="flex mt-8 gap-4 justify-end">
                <Button onClick={handleCancel}>Cancel</Button>
                <Button
                  loading={isLoadingLearners}
                  // disabled={showProjectSelection && !selectedProjectId}
                  htmlType="submit"
                  type="primary"
                >
                  Add coachee
                </Button>
              </div>
            </Form>
          </Modal>
          <LoadingAndError
            loading={batchDataLoading}
            error={batchDataError}
            data={Boolean(batchData)}
          >
            {batchData && !batchDataLoading && (
              <>
                <Table
                  className="mt-4"
                  columns={tableColumns}
                  dataSource={batchData.participants}
                />
              </>
            )}
          </LoadingAndError>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center">
          <div className="w-[30%]">
            <img
              className="w-full object-cover"
              src={NoData}
              alt="placeholder"
            ></img>
          </div>
          <Button
            className="mt-4"
            onClick={() => {
              setIsModalOpenBulkUpload(true);
            }}
            type="primary"
          >
            <AddRounded />
            Add Batch
          </Button>
        </div>
      )}
      {isModalOpenBulkUpload && (
        <Modal
          width={1000}
          className="learner-preview-modal"
          open={true}
          footer={false}
          onOk={() => setIsModalOpenBulkUpload(false)}
          onCancel={() => {
            setData([]);
            setColumns([]);
            setIsModalOpenBulkUpload(false);
          }}
        >
          <LearnerModal
            setIsModalOpen={setIsModalOpenBulkUpload}
            data={data}
            setData={setData}
            columns={columns}
            setColumns={setColumns}
            getBatches={getBatches}
          />
        </Modal>
      )}
    </>
  );
};
