import { ContentCopyOutlined } from "@mui/icons-material";
import { Button } from "antd";
import React from "react";
import { useState } from "react";

export const Copy = ({ textToCopy, initialText, finalText }) => {
  const [copiedText, setCopiedText] = useState(null);

  const handleCopyLink = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setCopiedText(text);
    setTimeout(() => setCopiedText(null), 3000); // Clear copied text after 3 seconds
  };

  return (
    <div>
      <Button
        type="text"
        icon={<ContentCopyOutlined className="!text-[16px] cursor-pointer" />}
        onClick={() => handleCopyLink(textToCopy)}
      >
        {copiedText ? finalText : initialText}
      </Button>
    </div>
  );
};
