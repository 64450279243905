import { createSlice } from "@reduxjs/toolkit";
import { uploadParticipants, getBatchesList } from "./batchViewServices";

const initialState = {
  batchViewStatus: "idle",
  batchUploadStatus: "idle",
  batchViewError: null,
  batchesList: [],
};

export const batchViewSlice = createSlice({
  name: "batchView",
  initialState,
  reducers: {},
  extraReducers: {
    [uploadParticipants.pending]: (state) => {
      state.batchUploadStatus = "pending";
    },
    [uploadParticipants.fulfilled]: (state) => {
      state.batchUploadStatus = "fulfilled";
    },
    [uploadParticipants.rejected]: (state, action) => {
      state.batchUploadStatus = "rejected";
      state.batchViewError = action.payload;
    },
    [getBatchesList.pending]: (state) => {
      state.batchViewStatus = "pending";
    },
    [getBatchesList.fulfilled]: (state, action) => {
      state.batchViewStatus = "fulfilled";
      state.batchesList = action.payload;
    },
    [getBatchesList.rejected]: (state, action) => {
      state.batchViewStatus = "rejected";
      state.batchViewError = action.payload;
    },
  },
});

export default batchViewSlice.reducer;
