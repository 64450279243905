import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getEventsList = createAsyncThunk(
  "manageCoaches/getEventsList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/events/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getConfirmedSlotsByEventId = createAsyncThunk(
  "manageCoaches/getConfirmedSlotsByEventId",
  async (eventId, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/get-booked-slots-of-learner-event-id/${eventId}/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addEvent = createAsyncThunk(
  "manageFaculties/addEvent",
  async (
    { eventDetails, form, setAlert, setCreateEvent, getEventsList },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/add-event/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: eventDetails,
      });
      if (response.status === 201) {
        setCreateEvent(false);
        form.setFieldsValue({
          eventName: undefined,
          startAndEndDate: undefined,
          expiryDate: undefined,
          coachIds: undefined,
          numberOfSlotsPerEvent: undefined,
        });
        dispatch(
          setAlert({
            message: "Event created successfully!",
            isAlert: true,
            status: "success",
          })
        );
        dispatch(getEventsList());
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: "Creating event is failed!",
          isAlert: true,
          status: "failure",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const editEvent = createAsyncThunk(
  "manageFaculties/editEvent",
  async (
    {
      eventDetails,
      form,
      setAlert,
      setEditEventDetails,
      setCreateEvent,
      getEventsList,
      eventId,
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/edit-event/${eventId}/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: eventDetails,
      });
      if (response.status === 200) {
        setCreateEvent(false);
        setEditEventDetails(null);
        form.setFieldsValue({
          eventName: undefined,
          startAndEndDate: undefined,
          expiryDate: undefined,
          coachIds: undefined,
          numberOfSlotsPerEvent: undefined,
        });
        dispatch(
          setAlert({
            message: "Event updated successfully!",
            isAlert: true,
            status: "success",
          })
        );
        dispatch(getEventsList());
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: "Event update is failed!",
          isAlert: true,
          status: "failure",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const deleteEvent = createAsyncThunk(
  "manageCoaches/deleteEvent",
  async (
    { eventId, getEventsList, setAlert },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_BASE_URL}/delete-event/${eventId}/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        dispatch(
          setAlert({
            message: "Event deleted successfully!",
            isAlert: true,
            status: "success",
          })
        );
        dispatch(getEventsList());
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: "Event deletion is failed!",
          isAlert: true,
          status: "failure",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const getAvailableSlotsByEventId = createAsyncThunk(
  "manageCoaches/getAvailableSlotsByEventId",
  async (eventId, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/get-slots-by-event-id/${eventId}/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        return response.data.slots;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
