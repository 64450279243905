import { createSlice } from "@reduxjs/toolkit";
import {
  addCourse,
  getBatches,
  getCourses,
  updateCourse,
  addBatch,
  updateBatch,
  getCourseCategories,
} from "./manageCoursesServices";

const initialState = {
  manageCoursesStatus: "pending",
  allCoursesList: [],
  allBatchesList: [],
  allCourseCategories: [],
  allFacultyNames: [],
  isEditBatch: false,
  batch: null,
  coursesError: null,
  courseCategories: null,
};

export const manageCourses = createSlice({
  name: "manageCourses",
  initialState,
  reducers: {
    setEditBatch: (state, action) => {
      state.isEditBatch = action.payload.isEditBatch;
      state.batch = action.payload.batch;
    },
  },
  extraReducers: {
    [getCourses.pending]: (state) => {
      state.manageCoursesStatus = "pending";
    },
    [getCourses.fulfilled]: (state, action) => {
      state.allCoursesList = action.payload;
      state.manageCoursesStatus = "fulfilled";
    },
    [getCourses.rejected]: (state, action) => {
      state.manageCoursesStatus = "rejected";
      state.coursesError = action.payload;
    },
    [addCourse.pending]: (state) => {
      state.manageCoursesStatus = "pending";
    },
    [addCourse.fulfilled]: (state) => {
      state.manageCoursesStatus = "fulfilled";
    },
    [addCourse.rejected]: (state, action) => {
      state.manageCoursesStatus = "rejected";
      state.coursesError = action.payload;
    },
    [updateCourse.pending]: (state) => {
      state.manageCoursesStatus = "pending";
    },
    [updateCourse.fulfilled]: (state, action) => {
      state.manageCoursesStatus = "fulfilled";
    },
    [updateCourse.rejected]: (state, action) => {
      state.manageCoursesStatus = "rejected";
      state.coursesError = action.payload;
    },
    [getBatches.pending]: (state) => {
      state.manageCoursesStatus = "pending";
    },
    [getBatches.fulfilled]: (state, action) => {
      state.manageCoursesStatus = "fulfilled";
      state.allBatchesList = action.payload;
    },
    [getBatches.rejected]: (state, action) => {
      state.manageCoursesStatus = "rejected";
      state.coursesError = action.payload;
    },
    [addBatch.pending]: (state) => {
      state.manageCoursesStatus = "pending";
    },
    [addBatch.fulfilled]: (state, action) => {
      state.manageCoursesStatus = "fulfilled";
    },
    [addBatch.rejected]: (state, action) => {
      state.manageCoursesStatus = "rejected";
      state.coursesError = action.payload;
    },
    [updateBatch.pending]: (state) => {
      state.manageCoursesStatus = "pending";
    },
    [updateBatch.fulfilled]: (state, action) => {
      state.manageCoursesStatus = "fulfilled";
    },
    [updateBatch.rejected]: (state, action) => {
      state.manageCoursesStatus = "rejected";
      state.coursesError = action.payload;
    },
    [getCourseCategories.pending]: (state) => {
      state.manageCoursesStatus = "pending";
    },
    [getCourseCategories.fulfilled]: (state, action) => {
      state.courseCategories = action.payload;
      state.manageCoursesStatus = "fulfilled";
    },
    [getCourseCategories.rejected]: (state, action) => {
      state.manageCoursesStatus = "rejected";
      state.coursesError = action.payload;
    },
  },
});

export const { setEditBatch } = manageCourses.actions;

export default manageCourses.reducer;
