import { Avatar, Button, Space, Progress, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import ACCimage from "../../assets/acc.jpeg";
import PCCimage from "../../assets/pcc_logo.png";
import MCCimage from "../../assets/MCC.png";
import MaleCoach from "../../assets/male.png";
import FemaleCoach from "../../assets/female.png";
import ICFnew from "../../assets/ICFnew.jpg";
import {
  LinkedIn,
  Mail,
  Phone,
  School,
  WorkHistory,
} from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PeopleIcon from "@mui/icons-material/People";
import MeeraqLogoColor from "../../assets/meeraq_logo_color.png";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { calculateProfilePercentage } from "../../utils/coachProfilePercentage copy";
import { CoachProfileModal } from "./CoachProfileModal";
import { HtmlContent } from "./HtmlContent";

export const ViewProfile = ({ setIsEdit, isHr, coach }) => {
  const { user } = useSelector((state) => state.auth);

  console.log({ coach });

  let first_name,
    last_name,
    email,
    phone,
    level,
    gender,
    education,
    coaching_experience,
    corporate_experience,
    years_of_coaching_experience,
    years_of_corporate_experience,
    area_of_expertise,
    profile_pic,
    education_pic,
    level_pic,
    coaching_hours,
    linkedin_profile_link,
    is_approved;

  // if hr is viewing this
  if (isHr) {
    first_name = coach.first_name;
    last_name = coach.last_name;
    email = coach.email;
    phone = coach.phone;
    level = coach.level;
    gender = coach.gender;
    education = coach.education;
    coaching_experience = coach.coaching_experience;
    corporate_experience = coach.corporate_experience;
    years_of_coaching_experience = coach.years_of_coaching_experience;
    years_of_corporate_experience = coach.years_of_corporate_experience;
    area_of_expertise = coach.area_of_expertise;
    profile_pic = coach.profile_pic;
    education_pic = coach.education_pic;
    level_pic = coach.level_pic;
    linkedin_profile_link = coach.linkedin_profile_link;
    coaching_hours = coach.coaching_hours;
    education_pic = coach.education_pic;
    is_approved = coach.is_approved;
  } else {
    first_name = user.first_name;
    last_name = user.last_name;
    email = user.email;
    phone = user.phone;
    level = user.level;
    gender = user.gender;
    education = user.education;
    linkedin_profile_link = user.linkedin_profile_link;
    coaching_hours = user.coaching_hours;
    coaching_experience = user.coaching_experience;
    corporate_experience = user.corporate_experience;
    years_of_coaching_experience = user.years_of_coaching_experience;
    years_of_corporate_experience = user.years_of_corporate_experience;
    area_of_expertise = user.area_of_expertise;
    profile_pic = user.profile_pic;
    education_pic = user.education_pic;
    level_pic = user.level_pic;
    is_approved = user.is_approved;
    education_pic = user.education_pic;
  }
  const [showProfile, setShowProfile] = useState(null);

  const handlePreviewClick = () => {
    setShowProfile(user);
  };

  return (
    <>
      {!isHr && (
        <div className="flex justify-center border-b-4 border-gray pb-2">
          {is_approved === true ? (
            <div className="flex items-center">
              <CheckCircleOutlineIcon
                className="!text-[2.5rem]"
                style={{ color: "#7E39A4" }}
              />
              <span
                className="ml-2 pt-1.5 !text-[20px] mr-20"
                style={{ color: "#7E39A4" }}
              >
                Approved
              </span>
            </div>
          ) : (
            <div className="flex items-center">
              <HourglassTopIcon className="!text-[2.5rem]" />
              <span className="ml-2 pt-1.5 !text-[20px] mr-20">In Review</span>
            </div>
          )}
        </div>
      )}

      <div className="flex flex-col gap-4 p-4 relative overflow-hidden rounded-lg">
        <div className="block lg:hidden w-[150px] ml-auto mr-2 md:mr-8 relative z-1">
          <img
            className="w-[8rem] h-[auto] md:w-[10rem] inline-block"
            alt="Meeraq-logo"
            src={MeeraqLogoColor}
          />
        </div>

        <div className="relative z-1 lg:flex">
          <div className="">
            {profile_pic ? (
              <Avatar
                src={<img src={profile_pic} alt="user profile" />}
                size={120}
              />
            ) : (
              <Avatar
                src={gender === "Female" ? FemaleCoach : MaleCoach}
                size={120}
              />
            )}
          </div>
          <div className="lg:ml-[1rem] lg:flex lg:flex-col gap-4 lg:mt-0 mt-4">
            <div className="text-xl font-semibold ml-[1rem] md:text-2xl">
              {first_name} {last_name}
            </div>

            {user?.user.type === "coach" && (
              <div className="flex items-center ml-[1rem] text-gray-600 ">
                <div className="">Profile Completion:</div>
                <div style={{ width: 150, marginLeft: "1rem" }}>
                  <Progress
                    percent={Math.floor(
                      calculateProfilePercentage(user, "coach")
                    )}
                    size="large"
                    strokeColor={{ from: "#CC3A92", to: "#7E39A4" }}
                  />
                </div>
              </div>
            )}

            <div className=" ml-[1rem] flex flex-col gap-1">
              <div className="flex items-center">
                <Mail className="!text-[1.2rem]" />
                <span className="ml-[1rem]">{email}</span>
              </div>
              <div className="flex items-center">
                <Phone className="!text-[1.2rem]" />
                <span className="ml-[1rem]">{phone}</span>
              </div>
              {linkedin_profile_link && (
                <div className="flex items-center">
                  <LinkedIn className="!text-[1.2rem]" />
                  <Tooltip title={linkedin_profile_link}>
                    <a
                      href={linkedin_profile_link}
                      target="_blank"
                      className="ml-[1rem] truncate w-[15rem]"
                      rel="noreferrer"
                    >
                      {linkedin_profile_link}
                    </a>
                  </Tooltip>
                </div>
              )}
            </div>

            <div>
              {!isHr && (
                <>
                  <Button className="m-1" onClick={() => setIsEdit(true)}>
                    Edit
                  </Button>
                  <Button className="m-1" onClick={handlePreviewClick}>
                    Preview
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className="hidden lg:block w-[150px] ml-auto mr-2 md:mr-8 relative z-1">
            <img
              className="w-[8rem] h-[auto] md:w-[10rem] inline-block"
              alt="Meeraq-logo"
              src={MeeraqLogoColor}
            />
          </div>
        </div>
      </div>

      <div className="mt-[2rem] p-4">
        <div className="flex flex-col gap-4 sm:flex-row sm:justify-between">
          <div className="flex gap-5 p-3 md:max-w-[30%]">
            <div>
              {/* <Avatar
                className="flex items-center justify-center"
                style={{
                  backgroundColor: "#ffffff",
                  color: "#000",
                }}
                icon={<School className="!text-[2.8rem]" />}
                size={50}
              /> */}
              {education_pic ? (
                <Avatar
                  src={<img src={education_pic} alt="user education pic" />}
                  size={50}
                />
              ) : (
                <Avatar
                  className="flex items-center justify-center"
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#000",
                  }}
                  icon={<School className="!text-[2.8rem]" />}
                  size={50}
                />
              )}
            </div>
            <div>
              <div className="font-semibold">Educational Institution</div>
              <div>
                {education && education.length > 0 ? (
                  education.join(", ")
                ) : (
                  <span className="text-gray-400">No data!</span>
                )}
              </div>
            </div>
          </div>
          <div className="flex gap-5 p-3">
            <Avatar
              className="flex items-center justify-center"
              style={{
                backgroundColor: "#ffffff",
                color: "#000",
              }}
              icon={<AccessTimeIcon className="!text-[3.2rem]" />}
              size={50}
            />
            <div>
              <div className="font-semibold">Coaching Hours</div>
              <div>
                {coaching_hours === "None" ? (
                  <span className="text-gray-400 ">No Data!</span>
                ) : coaching_hours ? (
                  `${coaching_hours} Hours`
                ) : (
                  <span className="text-gray-400 ">No Data!</span>
                )}
              </div>
            </div>
          </div>
          <div className="flex gap-5 p-3">
            {level === "ACC" ? (
              <div>
                <div>
                  <Avatar
                    className="bg-sky-200"
                    src={<img src={ACCimage} alt="coaching" />}
                    size={50}
                  />
                </div>
              </div>
            ) : (
              " "
            )}

            {level === "PCC" ? (
              <div>
                <div>
                  <Avatar
                    className="bg-sky-200"
                    src={<img src={PCCimage} alt="coaching" />}
                    size={50}
                  />
                </div>
              </div>
            ) : (
              " "
            )}

            {level === "MCC" ? (
              <div>
                <div>
                  <Avatar
                    className="bg-sky-200"
                    src={<img src={MCCimage} alt="coaching" />}
                    size={50}
                  />
                </div>
              </div>
            ) : (
              " "
            )}
            {!(level === "ACC") && !(level === "MCC") && !(level === "PCC") && (
              <div>
                <div>
                  <Avatar
                    className="bg-sky-200"
                    src={<img src={ICFnew} alt="coaching" />}
                    size={50}
                  />
                </div>
              </div>
            )}

            <div>
              <div className="font-semibold">
                International Coaching Federation (ICF)
              </div>
              <div>
                {["ACC", "PCC", "MCC"].includes(level) ? (
                  `${level}- Certified Coach`
                ) : level ? (
                  level
                ) : (
                  <span className="text-gray-400">No data!</span>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-[.5rem] p-4">
        <div className="flex flex-col sm:flex-row">
          <div className="flex gap-5 p-5 grow w-full sm:w-[50%]">
            <div>
              <Avatar
                className="flex items-center justify-center"
                style={{
                  backgroundColor: "#ffffff",
                  color: "#000",
                }}
                icon={<PeopleIcon className="!text-[2.8rem]" />}
                size={50}
              />
            </div>
            <div>
              <div className="font-semibold text-lg">Coaching Experience</div>
              <div>
                {years_of_coaching_experience === "None" ? (
                  <span className="text-gray-400 ">No Data! </span>
                ) : years_of_coaching_experience ? (
                  `${years_of_coaching_experience} Years`
                ) : (
                  <span className="text-gray-400 ">No Data! </span>
                )}
              </div>
              <div className="mt-[1rem] exp-wrapper">
                {coaching_experience ? (
                  <HtmlContent value={coaching_experience}></HtmlContent>
                ) : (
                  <p className="text-gray-400 text-center">
                    No data! Update your profile.
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="flex gap-5 p-5 grow w-full sm:w-[50%]">
            <div>
              <Avatar
                className="flex items-center justify-center"
                style={{
                  backgroundColor: "#ffffff",
                  color: "#000",
                }}
                icon={<WorkHistory className="!text-[2.125rem]" />}
                size={50}
              />
            </div>
            <div>
              <div className="font-semibold text-lg">Corporate Experience</div>
              <div>
                {years_of_corporate_experience === "None" ? (
                  <span className="text-gray-400 ">No Data! </span>
                ) : years_of_corporate_experience ? (
                  `${years_of_corporate_experience} Years`
                ) : (
                  <span className="text-gray-400 ">No Data! </span>
                )}
              </div>
              <div className="mt-[1rem] exp-wrapper">
                {corporate_experience ? (
                  <HtmlContent value={corporate_experience}></HtmlContent>
                ) : (
                  <p className="text-gray-400 text-center">
                    No data! Update your profile.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isHr && showProfile && (
        <CoachProfileModal
          showProfile={showProfile}
          setShowProfile={setShowProfile}
        />
      )}
    </>
  );
};
