import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import {
  AssessmentOutlined,
  FactCheckOutlined,
  GroupOutlined,
  PersonOutlineOutlined,
  VideoCameraFrontOutlined,
} from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label: key.startsWith("header") ? (
      <>{label}</>
    ) : (
      <NavLink className="inline-flex gap-4" to={`/${key}`}>
        {label}
      </NavLink>
    ),
  };
}

const keysInitials = [
  "dashboard",
  // "dashboard",
  "projects",
  "coach",
  "calendar",
  // "sessions",
  "pmo/project-create",
  "start-new-project",
  "add/coach",
  "organisation",
  "all/hr",
  "add/organisation",
  "add/hr",
  "hr/organisation",
  "coachee/list",
  "sessions",
  "report",
  "pmo/help",
  "registeredcoach",
  "pmo/project-create",
  "request-availability",
  "templates",
  "emailsdata",
  "participants",
  "coaches",
  "facilitator",
  "add/facilitator",
];

const insideProject = [`projects/progress/`];

const insideCoachee = [`coachee/`];

const PmoNavigation = () => {
  const [selectedKey, setSelectedKey] = useState("coach");
  const { pathname } = useLocation();

  // const { sessionRequestsCount } = useSelector((state) => state.notification);
  //   const dispatch = useDispatch();

  useEffect(() => {
    let pathExists = false;
    keysInitials.forEach((key) => {
      if (pathname === `/${key}`) {
        setSelectedKey(key);
        pathExists = true;
      }
    });

    insideProject.forEach((key) => {
      if (pathname.startsWith(`/${key}`)) {
        setSelectedKey("projects");
        pathExists = true;
      }
    });

    insideCoachee.forEach((key) => {
      if (pathname.startsWith(`/${key}`)) {
        setSelectedKey("coachee/list");
        pathExists = true;
      }
    });

    if (!pathExists) {
      setSelectedKey(null);
    }

    // dispatch(getSessionRequestsCount());
  }, [pathname]);

  const items = [
    // getItem(
    //   "Dashboard",
    //   "dashboard",
    //   <DashboardOutlinedIcon className="!text-[24px]" />
    // ),
    // getItem(
    //   "Coach",
    //   "header1",
    //   <PersonOutlineOutlined className="!text-[24px]" />,
    //   [
    //     getItem("View Coach", "coach"),
    //     getItem("Add Coach", "add/coach"),
    //     getItem("Registered Coach", "registeredcoach"),
    //   ]
    // ),
    getItem(
      "Projects",
      "header1",
      <FactCheckOutlined className="!text-[24px]" />,
      [
        getItem("View Projects", "projects"),
        getItem("Create New Project", "pmo/project-create"),
      ]
    ),
    // getItem(
    //   "Organisation and HR",
    //   "hr/organisation",
    //   <BusinessIcon className="!text-[24px]" />
    // ),
    getItem(
      "Participants",
      "participants",
      <GroupOutlined className="!text-[24px]" />
    ),
    getItem("Coaches", "coaches", <GroupOutlined className="!text-[24px]" />),
    getItem(
      "Facilitators",
      "header4",
      <GroupOutlined className="!text-[24px]" />,
      [
        getItem("View Facilitator", "facilitator"),
        getItem("Add Facilitator", "add/facilitator"),
      ]
    ),

    getItem(
      "Sessions",
      "sessions",
      <VideoCameraFrontOutlined className="!text-[24px]" />
    ),
    getItem(
      "Scheduler",
      "header2",
      <AccessTimeIcon className="!text-[24px]" />,
      [
        getItem("Request Availability", "request-availability"),
        // getItem("Availabilities", "/availabilities"),
      ]
    ),
    // getItem(
    //   "Reports",
    //   "report",
    //   <AssessmentOutlined className="!text-[24px]" />
    // ),
    // getItem(
    //   "Help",
    //   "pmo/help",
    //   <HelpCenterOutlinedIcon className="!text-[24px]" />
    // ),
    // getItem(
    //   "Reports",
    //   "report",
    //   <AssessmentOutlined className="!text-[24px]" />
    // ),
    // getItem(
    //   "Help",
    //   "pmo/help",
    //   <HelpCenterOutlinedIcon className="!text-[24px]" />
    // ),
    // getItem(
    //   "Email",
    //   "templates",
    //   <HelpCenterOutlinedIcon className="!text-[24px]" />
    // ),
    getItem(
      "Email",
      "header3",
      <MailOutlineOutlinedIcon className="!text-[24px]" />,
      [
        getItem("Email Templates", "templates"),
        getItem("Mails Sent", "emailsdata"),
      ]
    ),
  ];

  return (
    <Menu
      style={{
        border: 0,
        background: "inherit",
        color: "inherit",
      }}
      selectedKeys={[selectedKey]}
      onClick={(item) => setSelectedKey(item.key)}
      mode={"inline"}
      theme={"light"}
      items={items}
    />
  );
};

export { PmoNavigation };
