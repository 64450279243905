const PMO_ROUTES_ARR = [
  // "/coach",
  "/projects",
  "/pmo/project-create",
  "/projects/progress/:id",
  "/participants",
  "/coaches",
  "/sessions",
  "/templates",
  "/templates1",
  "/preview",
  "/successtestpage",
  "/successpreview",
  "/successpage",
  "/emailsdata",
  "/CoachingSessionDates",
  "/RequestInfo",
  "/request-availability",
  "/add/facilitator",
  "/facilitator",
];

const COACH_ROUTES_ARR = ["/slot-request", "/sessions", "/slots"];

// const UNAPPROVED_COACH_ROUTES_ARR = ["/profile", "/notifications"];

// const LEARNER_ROUTES_ARR = [
//   "/projects",
//   "/request-sessions",
//   "/pending-request",
//   "/upcoming-session",
//   "/completed-session",
//   "/call/:id",
//   "/notifications",
//   "/sessions-request",
//   "/sessions",
//   "/goal",
//   "/action-items",
//   "/competency",
//   "/dashboard",
//   "/learner/help",
// ];

// const HR_ROUTES_ARR = [
//   "/dashboard",
//   "/project-list",
//   "/learner",
//   "/notifications",
//   "/sessions",
//   "/coachee/reports",
//   "/sharedcoachprofiles",
//   "/coachee/list",
//   "/hr/report",
//   "/hr/help",
// ];

export const USER_BASED_ROUTES = {
  coach: [...COACH_ROUTES_ARR],
  pmo: [...PMO_ROUTES_ARR],
  // unapprovedCoach: [...UNAPPROVED_COACH_ROUTES_ARR],
  // hr: [...HR_ROUTES_ARR, { path: "/hr" }],
  // learner: [...LEARNER_ROUTES_ARR, { path: "/learner" }],
};
