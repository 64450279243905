import { Search } from "@mui/icons-material";
import { Input, Table } from "antd";
import React from "react";
import { useState } from "react";
import { Header } from "../../../components/header/Header";
import { PageContent } from "../../../components/page-content/PageContent";
import { useGetApi } from "../../../hooks/useGetApi";

export const Coaches = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const { data: coaches, isLoading: coachesLoading } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/coaches/`
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (_, coach) => coach.first_name + " " + coach.last_name,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
  ];

  const filteredCoaches = coaches?.filter((coach) => {
    const name = coach?.first_name + " " + coach?.last_name;
    return (
      name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      coach?.email?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      coach?.phone?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
  });

  return (
    <>
      <Header>Coaches</Header>
      <PageContent>
        <Input
          className="mb-4 w-auto"
          prefix={<Search />}
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Table
          loading={coachesLoading}
          columns={columns}
          dataSource={filteredCoaches || []}
        />
      </PageContent>
    </>
  );
};
