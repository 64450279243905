import React,{useState} from "react";
import parse from "html-react-parser";


let characterLimit=200

export const HtmlContent = ({ value }) => {


  const [showFullParagraph, setShowFullParagraph] = useState(false);
  const toggleParagraph = () => {
    setShowFullParagraph(!showFullParagraph);
  };


  let displayText = value;
  if (value.length > characterLimit && !showFullParagraph) {
    displayText = value.substring(0, characterLimit) + '...';
  }

  return <>
  <div className="html-wrapper">{parse(displayText)}
  </div>
  {value.length > characterLimit && (
        <button onClick={toggleParagraph}>
          {showFullParagraph ? <button className="text-blue-400">See Less</button> : <button className="text-blue-400">See More</button>}
        </button>
      )}

  </> 
  ;
};
