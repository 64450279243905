import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const uploadParticipants = createAsyncThunk(
  "coachingSessionSlotBooking/uploadParticipants",
  async ({ learnersData, setAlert, getBatchesList }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/learner-upload/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: { participent: learnersData },
      });
      if (response.status === 200) {
        dispatch(
          setAlert({
            message: "uploaded successfully!",
            isAlert: true,
            status: "success",
          })
        );
        dispatch(getBatchesList());
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: "Uploading failed!",
          isAlert: true,
          status: "failure",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const getBatchesList = createAsyncThunk(
  "coachingSessionSlotBooking/getBatchesList",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/get-batches/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
