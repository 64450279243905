import {
  CloseOutlined,
  Download,
  EditOutlined,
  RemoveRedEyeOutlined,
} from "@mui/icons-material";
import {
  Button,
  Collapse,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Tag,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Copy } from "../../../components/copy/Copy";
import { useGetApi } from "../../../components/hooks/useGetApi";
import { LoadingAndError } from "../../../components/loading-and-error/LoadingAndError";
import { usePutApi } from "../../../hooks/usePutApi";
import { DURATION_VALUES } from "../../../utils/constants";
import { useNavigate, useParams } from "react-router";
import { usePostApi } from "../../../hooks/usePostApi";

export const BatchCalendar = ({ batches, setSelectedTab }) => {
  const { id: projectId } = useParams();

  const batchOptions = batches.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const [selectedBatchId, setSelectedBatchId] = useState(batches[0]?.id);

  const {
    data: batchData,
    isLoading: batchDataLoading,
    error: batchDataError,
    getData: getBatchData,
  } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/batch-details/${selectedBatchId}/`
  );

  const batch = useMemo(() => {
    if (selectedBatchId) {
      return batches.find((item) => item.id === selectedBatchId);
    }
    return null;
  }, [batches, selectedBatchId]);

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-center">
          Batch:
          <Select
            placeholder={"Please select batch"}
            options={batchOptions}
            value={selectedBatchId}
            onChange={(value) => setSelectedBatchId(value)}
          ></Select>
        </div>
        <a
          href={`${process.env.REACT_APP_BASE_URL}/schedular/project-report-download/${projectId}/`}
        >
          <Button
            type="text"
            icon={<Download className="!text-[0.9rem]" />}
            className=""
          >
            Download Report
          </Button>
        </a>
      </div>
      <LoadingAndError
        loading={batchDataLoading}
        error={batchDataError}
        data={Boolean(batchData)}
      >
        {batchData && !batchDataLoading && (
          <div className="flex flex-col gap-4 mt-6">
            {batchData.sessions.map((session) =>
              "live_session_number" in session ? (
                <LiveSession
                  getBatchData={getBatchData}
                  key={session.order}
                  session={session}
                  participants={batchData?.participants}
                />
              ) : "coaching_session_number" in session ? (
                <CoachingSession
                  setSelectedTab={setSelectedTab}
                  batch={batch}
                  participants={batchData?.participants}
                  getBatchData={getBatchData}
                  coaches={batchData?.coaches}
                  key={session.order}
                  session={session}
                />
              ) : null
            )}
          </div>
        )}
      </LoadingAndError>
    </>
  );
};

function LiveSession({ session, getBatchData, participants }) {
  const [activeKey, setActiveKey] = useState(null);

  const onChange = (key) => {
    setActiveKey((prev) => (prev ? null : "1"));
  };

  const items = [
    {
      key: "1",
      label: <LiveSessionHeader session={session} />,
      showArrow: false,
      extra: (
        <Button type="text ml-6">
          {activeKey ? <CloseOutlined /> : <RemoveRedEyeOutlined />}
        </Button>
      ),
      children: (
        <LiveSessionContent
          participants={participants}
          session={session}
          getBatchData={getBatchData}
        />
      ),
    },
  ];

  return (
    <Collapse
      destroyInactivePanel={true}
      items={items}
      onChange={onChange}
      activeKey={activeKey}
    ></Collapse>
  );
}

function LiveSessionContent({ session, getBatchData, participants }) {
  const [isFormActive, setIsFormActive] = useState(false);

  const isSessionDetailsUnavailable = Boolean(
    !session.date_time || !session.description
  );

  useEffect(() => {
    if (isSessionDetailsUnavailable) {
      setIsFormActive(true);
    }
  }, [session, isSessionDetailsUnavailable]);

  return (
    <>
      {isFormActive ? (
        <LiveSessionForm
          setIsFormActive={setIsFormActive}
          isSessionDetailsUnavailable={isSessionDetailsUnavailable}
          participants={participants}
          session={session}
          getBatchData={getBatchData}
        />
      ) : (
        <LiveSessionDetails
          participants={participants}
          session={session}
          getBatchData={getBatchData}
          setIsFormActive={setIsFormActive}
        />
      )}
    </>
  );
}

function LiveSessionDetails({
  session,
  getBatchData,
  participants,
  setIsFormActive,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    data: dataLiveSession,
    // error: errorLiveSession,
    isLoading: isLoadingLiveSession,
    postData: postDataLiveSession,
    resetState: resetStateLiveSession,
  } = usePostApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/send_live_session_link/`
  );

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOkSendMail = async (
    batchName,
    participant,
    description,
    facilitator
  ) => {
    const participantEmail = participant?.map((item) => {
      return item;
    });

    postDataLiveSession({
      batchName: batchName,
      participant: participantEmail,
      description: description,
      facilitator: facilitator,
    });
  };

  useEffect(() => {
    if (dataLiveSession) {
      resetStateLiveSession();
      setIsModalOpen(false);
    }
  }, [dataLiveSession, resetStateLiveSession]);

  return (
    <>
      <div className="flex justify-between">
        Details:
        <Button type="text" onClick={() => setIsFormActive(true)}>
          <EditOutlined />
        </Button>
      </div>
      <div className="flex gap-2">
        <p className="font-semibold min-w-[8rem]">Schdeduled At:</p>
        <p>
          {moment(new Date(session.date_time)).format("DD MMMM YYYY hh:mm A")}
        </p>
      </div>
      <div className="flex gap-2 ">
        <p className="font-semibold min-w-[8rem]">Attendees:</p>
        <p>{session?.attendees?.length}</p>
      </div>
      <div className="flex gap-2 mb-4">
        <p className="font-semibold min-w-[8rem]">Description:</p>
        <p>{session.description}</p>
      </div>
      <Button className="mt-4" onClick={showModal}>
        Send Live Session Link
      </Button>
      <Modal
        footer={false}
        title="Send live session mail."
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <>
          This content will be shared through mail to all{" "}
          <span className="font-semibold">{session?.batch?.name}</span>{" "}
          participants:
          <p className="font-semibold mt-2">{session?.description}</p>
          <div className="mt-4">Are you sure you want to send the mail?</div>
          <div className="flex gap-2">
            <Button className="ml-auto" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              loading={isLoadingLiveSession}
              onClick={() =>
                handleOkSendMail(
                  session?.batch?.name,
                  participants,
                  session.description,
                  session.batch.facilitator
                )
              }
            >
              Send
            </Button>
          </div>
        </>
      </Modal>
    </>
  );
}

function LiveSessionForm({
  session,
  participants,
  getBatchData,
  isSessionDetailsUnavailable,
  setIsFormActive,
}) {
  const [form] = Form.useForm();

  const {
    isLoading: updateSessionLoading,
    putData: updateSession,
    data: updateSessionData,
    resetState: updateSessionResetState,
  } = usePutApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/live-sessions/${
      session?.id || ""
    }/update/`
  );

  const statusOptions = [
    { label: "Pending", value: "pending" },
    { label: "Completed", value: "completed" },
  ];

  const handleFinish = (values) => {
    updateSession({
      date_time: values.dateTime.$d,
      description: values.description,
      attendees: values.attendees,
      status: values.status,
    });
  };

  const attendeesOptions = participants.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  useEffect(() => {
    if (updateSessionData) {
      updateSessionResetState();
      getBatchData();
    }
  }, [updateSessionData, getBatchData, updateSessionResetState]);

  useEffect(() => {
    if (session) {
      form.setFieldsValue({
        status: session.status,
        dateTime: session.date_time ? dayjs(new Date(session.date_time)) : null,
        attendees: session.attendees,
        description: session.description,
      });
    }
  }, [session, form]);

  return (
    <>
      <Form
        labelCol={{
          span: 3,
        }}
        labelAlign="left"
        onFinish={handleFinish}
        form={form}
      >
        <Form.Item
          label="Date Time"
          name="dateTime"
          rules={[{ required: true, message: "Please select date and time" }]}
        >
          <DatePicker minuteStep={15} showTime format="DD-MM-YYYY hh:mm A" />
        </Form.Item>

        <Form.Item label="Select Attendees" name="attendees">
          <Select
            mode="multiple"
            options={attendeesOptions}
            placeholder="Select attendees"
          ></Select>
        </Form.Item>
        <Form.Item
          label="Select Status"
          name="status"
          rules={[{ required: true, message: "Please select status" }]}
        >
          <Select options={statusOptions} placeholder="Select status"></Select>
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            { required: true, message: "Please enter description." },
            {
              max: 1000,
              message: "Character cannot be more than 1000",
            },
          ]}
        >
          <Input.TextArea
            maxLength={1001}
            rows={4}
            placeholder="Enter description"
          />
        </Form.Item>
        <div className="flex gap-2">
          <Form.Item>
            <Button
              type="primary"
              loading={updateSessionLoading}
              htmlType="submit"
            >
              Update Session
            </Button>
          </Form.Item>
          {!isSessionDetailsUnavailable && (
            <Button onClick={() => setIsFormActive(false)}>Cancel</Button>
          )}
        </div>
      </Form>
    </>
  );
}

function LiveSessionHeader({ session }) {
  return (
    <div className="">
      <div
        onClick={(e) => e.stopPropagation()}
        className="grid grid-cols-5 gap-2 items-center"
      >
        <div> Live Session {session.live_session_number}</div>
        <div className="flex flex-col gap-2">
          <span className="text-gray-400">Date Time</span>
          <span>
            {session.date_time
              ? moment(new Date(session.date_time)).format("DD-MM-YYYY hh:mm A")
              : "Not available!"}
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-gray-400">Duration</span>
          <span>{DURATION_VALUES[session.duration]}</span>
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-gray-400">Attendees</span>
          <span>{session?.attendees?.length}</span>
        </div>
        <div className="flex flex-col gap-2 items-start">
          <span className="text-gray-400">Status</span>
          <Tag
            className="capitalize min-w-[5rem] grow-0"
            color={
              session.status === "pending"
                ? "warning"
                : session.status === "completed"
                ? "green"
                : ""
            }
          >
            {session.status || "Not available!"}
          </Tag>
        </div>
      </div>
    </div>
  );
}

function CoachingSession({
  session,
  participants,
  batch,
  getBatchData,
  setSelectedTab,
  coaches,
}) {
  const [activeKey, setActiveKey] = useState(null);

  const onChange = (key) => {
    setActiveKey((prev) => (prev ? null : "1"));
  };

  const items = [
    {
      key: "1",
      label: (
        <CoachingSessionHeader participants={participants} session={session} />
      ),
      showArrow: false,
      extra: (
        <Button type="text ml-6">
          {activeKey ? <CloseOutlined /> : <RemoveRedEyeOutlined />}
        </Button>
      ),
      children: (
        <CoachingSessionContent
          coaches={coaches}
          setSelectedTab={setSelectedTab}
          getBatchData={getBatchData}
          batch={batch}
          session={session}
        />
      ),
    },
  ];

  return (
    <Collapse
      destroyInactivePanel={true}
      items={items}
      onChange={onChange}
      activeKey={activeKey}
    ></Collapse>
  );
}

function CoachingSessionHeader({ session, participants }) {
  return (
    <div className="">
      <div
        onClick={(e) => e.stopPropagation()}
        className="grid grid-cols-5  gap-2 items-center"
      >
        <div> Coaching Session {session.coaching_session_number}</div>

        <div className="flex flex-col gap-2">
          <span className="text-gray-400">Dates</span>
          <span>
            {session.start_date && session.end_date ? (
              <>
                {moment(new Date(session.start_date)).format("DD-MM-YYYY")} to{" "}
                {""}
                {moment(new Date(session.end_date)).format("DD-MM-YYYY")}
              </>
            ) : (
              <>Not available!</>
            )}
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-gray-400">Duration</span>
          <span>{DURATION_VALUES[session.duration]}</span>
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-gray-400">Booked</span>
          <span>
            {" "}
            {session.start_date && session.end_date
              ? `${session.booked_session_count} /${participants.length}`
              : "Not available!"}
          </span>
        </div>

        <div className="flex flex-col gap-2">
          <span className="text-gray-400">Available Slots</span>
          <span>
            {" "}
            {session.start_date && session.end_date
              ? `${session.available_slots_count}`
              : "Not available!"}
          </span>
        </div>
      </div>
    </div>
  );
}

function CoachingSessionContent({
  session,
  batch,
  getBatchData,
  setSelectedTab,
  coaches,
}) {
  const [isFormActive, setIsFormActive] = useState(false);

  const isSessionDatesUnavailable = Boolean(
    !session.start_date || !session.end_date || !session.expiry_date
  );

  useEffect(() => {
    if (isSessionDatesUnavailable) {
      setIsFormActive(true);
    }
  }, [session, isSessionDatesUnavailable]);

  return (
    <>
      {isFormActive ? (
        <CoachingSessionForm
          isSessionDatesUnavailable={isSessionDatesUnavailable}
          getBatchData={getBatchData}
          batch={batch}
          session={session}
          setIsFormActive={setIsFormActive}
        />
      ) : (
        <CoachingSessionDetails
          coaches={coaches}
          setSelectedTab={setSelectedTab}
          setIsFormActive={setIsFormActive}
          session={session}
          batch={batch}
        ></CoachingSessionDetails>
      )}
    </>
  );
}

function CoachingSessionForm({
  session,
  batch,
  getBatchData,
  isSessionDatesUnavailable,
  setIsFormActive,
}) {
  const [form] = Form.useForm();
  const {
    isLoading: updateSessionLoading,
    putData: updateSession,
    data: updateSessionData,
    resetState: updateSessionResetState,
  } = usePutApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/coaching-sessions/${
      session?.id || ""
    }/update/`
  );

  const validateEndDate = (_, value) => {
    const startDate = form.getFieldValue("startDate");
    if (value && startDate && value.isBefore(startDate)) {
      return Promise.reject("End date must be greater than start date!");
    }
    return Promise.resolve();
  };

  const validateExpiryDate = (_, value) => {
    const endDate = form.getFieldValue("endDate");
    if (value && endDate && value.isAfter(endDate)) {
      return Promise.reject("Expiry date must be less than end date!");
    }
    return Promise.resolve();
  };

  const finishHandler = (values) => {
    updateSession({
      start_date: values.startDate.format("YYYY-MM-DD"),
      end_date: values.endDate.format("YYYY-MM-DD"),
      expiry_date: values.expiryDate.format("YYYY-MM-DD"),
    });
  };

  useEffect(() => {
    if (updateSessionData) {
      updateSessionResetState();
      getBatchData();
    }
  }, [updateSessionData, getBatchData, updateSessionResetState]);

  useEffect(() => {
    // Check if session object is available
    if (session) {
      // Fill in form values using form.setFieldsValue
      form.setFieldsValue({
        startDate: session.start_date
          ? dayjs(new Date(session.start_date))
          : null,
        endDate: session.end_date ? dayjs(new Date(session.end_date)) : null,
        expiryDate: session.expiry_date
          ? dayjs(new Date(session.expiry_date))
          : null,
      });
    }
  }, [form, session]);

  return (
    <Form
      labelCol={{ span: 4 }}
      labelAlign="left"
      form={form}
      onFinish={finishHandler}
    >
      <div className="mb-4 font-semibold">
        Update the coaching session dates:
      </div>
      <Form.Item
        label="Start Date"
        name="startDate"
        rules={[{ required: true, message: "Please select the start date!" }]}
      >
        <DatePicker format={"DD-MM-YYYY"} />
      </Form.Item>
      <Form.Item
        label="End Date"
        name="endDate"
        rules={[
          { required: true, message: "Please select the end date!" },
          { validator: validateEndDate },
        ]}
      >
        <DatePicker format={"DD-MM-YYYY"} />
      </Form.Item>
      <Form.Item
        label="Expiry Date"
        name="expiryDate"
        rules={[
          { required: true, message: "Please select the expiry date!" },
          { validator: validateExpiryDate },
        ]}
      >
        <DatePicker format={"DD-MM-YYYY"} />
      </Form.Item>
      <div className="flex gap-2">
        <Form.Item>
          <Button
            loading={updateSessionLoading}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
        {!isSessionDatesUnavailable && (
          <Button onClick={() => setIsFormActive(false)}>Cancel</Button>
        )}
      </div>
    </Form>
  );
}

function CoachingSessionDetails({
  session,
  batch,
  setSelectedTab,
  setIsFormActive,
  coaches,
}) {
  const { data, isLoading, postData, resetState } = usePostApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/send_coaching_session_mail/`
  );
  const navigate = useNavigate();
  const [isSendBookingLinkModalOpen, setIsSendBookingLinkModalOpen] =
    useState(false);
  const showSendBookingLinkModal = () => {
    setIsSendBookingLinkModalOpen(true);
  };
  const handleOkSendBookingLink = () => {
    setIsSendBookingLinkModalOpen(false);
  };
  const handleCancelSendBookingLink = () => {
    setIsSendBookingLinkModalOpen(false);
  };

  const handleSendEmail = async (
    batchName,
    batchId,
    sessionNumber,
    bookingLink,
    expiryDate
  ) => {
    const participantEmails = session.participants_not_booked.map(
      (participant) => {
        return participant.email;
      }
    );

    postData({
      batchName: batchName,
      expiry_date: expiryDate,
      // batchId: batchId,
      // sessionNumber: sessionNumber,
      participants: participantEmails,
      bookingLink: bookingLink,
    });
  };

  useEffect(() => {
    if (data) {
      setIsSendBookingLinkModalOpen(false);
      resetState();
    }
  }, [data, resetState]);

  return (
    <div>
      <div className="flex justify-between">
        Details:
        <Button type="text" onClick={() => setIsFormActive(true)}>
          <EditOutlined />
        </Button>
      </div>
      <div className="flex gap-2 mb-4">
        <p className="font-semibold min-w-[6rem]">Link Expiry:</p>
        <p>{moment(new Date(session.expiry_date)).format("DD-MM-YYYY")}</p>
      </div>
      <div className="flex justify-between">
        {coaches?.length > 0 ? (
          <Button
            onClick={() =>
              navigate("/request-availability", {
                state: { arrayOfCoaches: coaches },
              })
            }
          >
            Request Availability
          </Button>
        ) : (
          <Button onClick={() => setSelectedTab("coaches")}>
            Add Coaches to Batch
          </Button>
        )}
        <div className="flex gap-4 items-center">
          <Copy
            textToCopy={session.booking_link}
            initialText={"Copy Booking Link"}
            finalText={"Link Copied"}
          />
          <Button onClick={showSendBookingLinkModal}>Send Booking Link</Button>
          <Modal
            footer={false}
            title="Send Booking Link"
            open={isSendBookingLinkModalOpen}
            onOk={handleOkSendBookingLink}
            onCancel={handleCancelSendBookingLink}
          >
            <div>
              Sending mail to {session?.participants_not_booked?.length}{" "}
              participant
              {session?.participants_not_booked?.length !== 1
                ? "s"
                : ""} who{" "}
              {session?.participants_not_booked?.length !== 1 ? "have" : "has"}{" "}
              not booked the coaching session yet.
              <div className="mt-[1rem]">
                {session.participants_not_booked.map((participant) => (
                  <ul key={participant.email}>
                    <li>&#8226; {participant.email}</li>
                  </ul>
                ))}
              </div>
            </div>

            <div className="flex mt-[1rem] gap-2">
              <Button className="ml-auto" onClick={handleCancelSendBookingLink}>
                Cancel
              </Button>
              <Button
                loading={isLoading}
                onClick={() =>
                  handleSendEmail(
                    batch.name,
                    batch.id,
                    session.number,
                    session.booking_link,
                    session.expiry_date
                  )
                }
              >
                Send
              </Button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}
