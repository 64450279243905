import { Alert, Button, Table, Upload, Tooltip } from "antd";
import React, { useState, useMemo } from "react";
import NoData from "../../assets/no_data.png";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { read, utils } from "xlsx";
import { InboxOutlined } from "@ant-design/icons";
import { usePostApi } from "../../hooks/usePostApi";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CoachSampleUpload } from "./CoachSampleUpload";
import {
  COACHES_SAMPLE_DATA,
  COACHES_SAMPLE_DATA_FACILITATOR,
} from "../../utils/constants";

import { useSelector } from "react-redux";
const EXPECTED_COLUMNS = [
  "first_name",
  "last_name",
  "age",
  "gender",
  "level",
  "corporate_yoe",
  "functional_domain",
  "email",
  "mobile",
  "job_roles",
  "companies_worked_in",
  "language",
  "industries",
  "city",
  "country",
  "topic",
  "linkedin_profile",
  "client_companies",
  "educational_qualification",
  "education",
  "fees_per_hour",
  "fees_per_day",
];

const WRONG_FILE =
  "File that you selected is wrong. Check the sample file below for more details.";
const MISSING_DATA =
  ", some data is missing. Expected details are: first name, last name, coachId, gender, level, ctt_nctt, active_inactive, email and phone. ";

const Uploader = ({ handleUpload }) => {
  return (
    <Upload.Dragger
      className="w-full"
      accept=".xlsx,.xls"
      multiple={false} // Only allow one
      beforeUpload={(file) => {
        handleUpload(file);
        return false;
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload facilitators
      </p>
      <p>File formats: .xlsx or .xls </p>
      {/* <Button className="w-full">Select File</Button> */}
    </Upload.Dragger>
  );
};

const COLUMNS_WITH_MULTIPLE_VALUES = [
  "job_roles",
  "companies_worked_in",
  "language",
  "industries",
  "client_companies",
  "educational_qualification",
  "education",
  "city",
  "country",
  "level",
  "functional_domain",
  "topic",
];

export const BulkCoachesUpload = ({ setIsBulkUpload }) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const { user } = useSelector((state) => state.auth);

  const [isFileSelected, setIsFileSelected] = useState(
    data?.length > 0 ? true : false
  );
  const userType = useMemo(() => {
    return user.user.type;
  }, [user]);
  const navigate = useNavigate();

  const {
    data: addFacilitatorData,
    isLoading: addFacilitatorLoading,
    error: addFacilitatorError,
    postData: addFacilitator,
  } = usePostApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/add-multiple-facilitator/`
  );

  // const handleUpload = (file) => {
  //   const reader = new FileReader();
  //   setIsFileSelected(false);
  //   reader.onload = (e) => {
  //     const binaryData = e.target.result;
  //     setIsFileSelected(true);

  //     const workbook = read(binaryData, { type: "binary" });
  //     const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  //     const data = utils.sheet_to_json(worksheet, { header: 1 });
  //     const columns = data.shift().map((col) => ({
  //       title: col,
  //       dataIndex: col,
  //       render: (value) => {
  //         if (COLUMNS_WITH_MULTIPLE_VALUES.includes(col)) {
  //           const res = value.join(', ')
  //           return res
  //         }
  //         return value
  //       }
  //     }));
  //     const formattedData = data.map((row) => {
  //       return columns.reduce((obj, column, index) => {
  //         if (COLUMNS_WITH_MULTIPLE_VALUES.includes(column.dataIndex)) {
  //           obj[column.dataIndex] = row[index] ? row[index].split(",") : [];
  //         } else {
  //           obj[column.dataIndex] = row[index];
  //         }
  //         return obj;
  //       }, {});
  //     });
  //     setData(formattedData);
  //     setColumns(columns);
  //   };
  //   reader.readAsBinaryString(file);
  // };

  const handleUpload = (file) => {
    const reader = new FileReader();
    setIsFileSelected(false);
    reader.onload = (e) => {
      const binaryData = e.target.result;
      setIsFileSelected(true);

      const workbook = read(binaryData, { type: "binary" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const data = utils.sheet_to_json(worksheet, { header: 1 });

      // Filter out empty rows
      const nonEmptyRows = data.filter((row) =>
        row.some((cell) => cell !== null && cell !== "")
      );

      if (nonEmptyRows.length === 0) {
        // Handle case when all rows are empty
        setData([]);
        setColumns([]);
        return;
      }

      const columns = nonEmptyRows.shift().map((col) => ({
        title: col,
        dataIndex: col,
        render: (value) => {
          if (COLUMNS_WITH_MULTIPLE_VALUES.includes(col)) {
            const res = value.join(", ");
            return res;
          }
          return value;
        },
      }));

      const formattedData = nonEmptyRows.map((row) => {
        return columns.reduce((obj, column, index) => {
          let cellValue = row[index] ? row[index] : "";
          if (COLUMNS_WITH_MULTIPLE_VALUES.includes(column.dataIndex)) {
            obj[column.dataIndex] = cellValue
              .trim()
              .split(",")
              .map((cellItem) => cellItem.trim());
          } else {
            obj[column.dataIndex] = cellValue;
          }
          return obj;
        }, {});
      });

      setData(formattedData);
      setColumns(columns);
    };
    reader.readAsBinaryString(file);
  };

  const handleAddCoaches = () => {
    addFacilitator({
      coaches: data,
    });
  };

  let error = null;
  let lineNo = null;
  if (columns.length > 0) {
    // checking if the columns are same or not
    for (let i = 0; i < columns.length; i++) {
      if (EXPECTED_COLUMNS[i] !== columns[i].title) {
        error = WRONG_FILE;
        break;
      }
    }
    if (!error) {
      for (let index in data) {
        if (
          Boolean(data[index]["first_name"]?.trim()) &&
          Boolean(data[index]["last_name"]?.trim()) &&
          Boolean(data[index]["gender"]?.trim()) &&
          // Boolean(data[index]["level"]?.trim()) &&
          Boolean(data[index]["email"]?.trim()) &&
          Boolean(String(data[index]["mobile"])?.trim()) &&
          Boolean(String(data[index]["phone_country_code"])?.trim())
        ) {
          continue;
        } else {
          error = MISSING_DATA;
          lineNo = +index + 1;
          break;
        }
      }
    }
  } else {
    error = WRONG_FILE;
  }

  useEffect(() => {
    if (addFacilitatorData) {
      setData([]);
      setColumns([]);
      setIsFileSelected(null);
      navigate("/facilitator");
    }
  }, [addFacilitatorData]);

  return (
    <div>
      <div className="flex justify-between px-2 py-2 items-center mb-4">
        <span className=""></span>
        <Button onClick={() => setIsBulkUpload(false)}>
          Cancel bulk upload
        </Button>
      </div>

      <>
        <div>
          {isFileSelected ? (
            <>
              {error === WRONG_FILE ? (
                <>
                  <Alert
                    type="error"
                    message={WRONG_FILE}
                    showIcon
                    className="mb-4"
                  />
                  <Uploader handleUpload={handleUpload} />
                </>
              ) : (
                <>
                  <p className="px-2 font-semibold">Data: </p>
                  {error === MISSING_DATA && (
                    <Alert
                      className="mt-2 mb-4"
                      message={`Row no. ${lineNo} ${MISSING_DATA}`}
                      type="error"
                      showIcon
                    />
                  )}
                  <Table
                    pagination={{ pageSize: 5 }}
                    className="text-black"
                    dataSource={data}
                    columns={columns}
                  />
                </>
              )}
            </>
          ) : (
            <Uploader handleUpload={handleUpload} />
          )}

          {/* MODAL FOOTER */}
          <div className="lg:flex justify-end gap-4 mt-6">
            {/* <DownloadSampleExcel /> */}
            <CoachSampleUpload
              title="Download Sample File"
              coaches={COACHES_SAMPLE_DATA_FACILITATOR}
            />

            {/* <Button
              onClick={() => {
                setData([]);
                setIsFileSelected(false);
                setColumns([]);
              }}
              className=""
            >
              Cancel
            </Button> */}

            <Button
              disabled={Boolean(error)}
              className="bg-sky-500 ml-2 "
              loading={addFacilitatorLoading}
              type="primary"
              onClick={handleAddCoaches}
            >
              Add Facilitators
            </Button>
          </div>
        </div>
      </>
    </div>
  );
};
