import React from "react";

export const CustomAlert = ({ icon, message, className }) => {
  return (
    <div
      className={`inline-flex items-center gap-2 py-2 px-2 pr-6 border my-2 rounded-xl ${className}`}
    >
      {icon} <>{message}</>
    </div>
  );
};
