import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  notification,
  Select,
  Space,
  Spin,
  Upload,
} from "antd";
import { usePostApi } from "../../hooks/usePostApi";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import {
  validatePassword,
  validateName,
  validateInteger,
  validateContact,
  validateAge,
  validateProjectNameOnChange,
  validateProjectNameOnBlur,
  validateFirstNameOnBlur,
  validateFirstNameOnChange,
  validateLastNameOnChange,
  validateLastNameOnBlur,
  ageValidationOnChange,
  ageValidationOnBlur,
  contactValidationOnChange,
  contactValidationOnBlur,
  getPhoneError,
  isWholeNumber,
  validateEmailOnBlur,
  validityCurrency,
  minFeesValidationOnChange,
  minFeesValidationOnBlur,
  minFeesPerDayValidationOnChange,
  minFeesPerDayValidationOnBlur,
  minFeesPerHourValidationOnChange,
  minFeesPerHourValidationOnBlur,
} from "../../utils/validation";
import {
  ACTIVE_INACTIVE,
  COACHING_HOURS,
  CTT,
  CURRENCY_LIST,
  DOMAIN,
  EDUCATION,
  EXPERIENCE_LEVEL,
  EXPERTISE,
  FACILITATOR_LEVEL,
  GENDER,
  INTERNAL_COACH,
  JOB_ROLES,
  LANGUAGE,
  LEVEL_OPTIONS,
  LOCATION,
  VALIDATE_TRIGGERS,
} from "../../utils/constants";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { ImageUpload } from "../image-upload/ImageUpload";
import { useDispatch, useSelector } from "react-redux";
import { isMaxCharCrossed, isMinIntCrossed } from "../../utils/validations";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"; // import {ReactLanguageSelect} from 'react-languages-select';
import { useGetApi } from "../../hooks/useGetApi";
import { arrayToOptions } from "../../utils/arrayToOptions";
// import { BulkCoachesUpload } from "./BulkCoachesUpload";
import { Header } from "../header/Header";
import { PageContent } from "../page-content/PageContent";
import { updateUser } from "../../redux/slices/authSlice";
import { useMemo } from "react";
import { usePutApi } from "../../hooks/usePutApi";
import { AddCoachImageUpload } from "../image-upload/AddCoachImageUpload";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { EducationImageUpload } from "../image-upload/EducationImageUpload";
import { parsePhoneNumber } from "react-phone-number-input";
import { RichTextEditor } from "../rich-text-editor/RichTextEditor";
import { BulkCoachesUpload } from "./BulkCoachesUpload";
import { SelectAll } from "@mui/icons-material";

export const AddFacilitator = ({ setIsEdit }) => {
  const [isMeetingLinkLoading, setIsMeetingLinkLoading] = useState(false);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const {
    data: coachFieldsData,
    isLoading: coachFieldsLoading,
    error: coachFieldsError,
    getData: coachFieldsGetData,
  } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/facilitator-field-values/`
  );

  const {
    isLoading: isLoadingFacilitator,
    postData: addFacilitator,
    data: dataAddFacilitator,
    resetState: resetStateAddFacilitator,
  } = usePostApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/add-facilitator/`
  );

  const [certificateUpload, setCertificateUpload] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const userType = useMemo(() => {
    return user.user.type;
  }, [user]);
  const dispatch = useDispatch();
  const [internalCoachChange, setInternalCoachChange] = useState(null);
  const [statusChange, setStatusChange] = useState(null);

  const [jobRolesOptions, setJobRolesOptions] = useState([]);
  const [jobRole, setJobRole] = useState("");
  const jobRoleInputRef = useRef(null);

  const [countryOptions, setCountryOptions] = useState([]);
  const [country, setCountry] = useState("");
  const countryInputRef = useRef(null);

  const [topicOptions, setTopicOptions] = useState([]);
  const [topic, setTopic] = useState("");
  const topicInputRef = useRef(null);

  const [educationRolesOptions, setEducationRolesOptions] = useState([]);
  const [educationRole, setEducationRole] = useState("");
  const educationRoleInputRef = useRef(null);

  const [languageRolesOptions, setLanguageRolesOptions] = useState([]);
  const [languageRole, setLanguageRole] = useState("");
  const languageRoleInputRef = useRef(null);

  const [locationRolesOptions, setLocationRolesOptions] = useState([]);
  const [locationRole, setLocationRole] = useState("");
  const locationRoleInputRef = useRef(null);

  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [qualificationRole, setqualificationROle] = useState("");
  const qualificationROleInputRef = useRef(null);

  const [industryOptions, setIndustryOptions] = useState([]);
  const [industry, setIndustry] = useState("");
  const industryInputRef = useRef(null);

  const [cityOptions, setCityOptions] = useState([]);
  const [city, setCity] = useState("");
  const cityInputRef = useRef(null);

  const [companiesWorkedinRolesOptions, setCompaniesWorkedinRolesOptions] =
    useState([]);
  const [companiesWorkedinRole, setCompaniesWorkedinRole] = useState("");
  const companiesWorkedinRoleInputRef = useRef(null);

  const [clientCompaniesOptions, setClientCompaniesOptions] = useState([]);
  const [clientCompanies, setclientCompanies] = useState("");
  const clientCompaniesInputRef = useRef(null);

  const [otherCertificatesRolesOptions, setOtherCertificatesRolesOptions] =
    useState([]);
  const [otherCertificatesRole, setOtherCertificatesRole] = useState("");
  const otherCertificatesRoleInputRef = useRef(null);

  const [domainRolesOptions, setDomainRolesOptions] = useState([]);
  const [domainRole, setDomainRole] = useState("");
  const domainRoleInputRef = useRef(null);

  const [coachingExperience, setCoachingExperience] = useState("");
  const [corporateExperience, setCorporateExperience] = useState("");

  const [profilePic, setProfilePic] = useState(null);
  const [educationProfilePic, setEducationProfilePic] = useState(null);
  const [certificateDownloadUrl, setCertificateDownloadUrl] = useState("");

  const location = useLocation();
  const isEditCoach =
    (Boolean(location?.state?.coach) && location.hash === "#edit") ||
    Boolean(setIsEdit);

  const [phone, setPhone] = useState();
  const [phoneError, setPhoneError] = useState();
  const [coachUser, setCoachUser] = useState(
    setIsEdit ? { ...user } : { ...location?.state?.coach }
  );
  const {
    data: editCoachData,
    isLoading: editCoachLoading,
    error: editCoachError,
    putData: editCoachPutData,
  } = usePutApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/facilitator/profile/${
      location?.state?.coach ? location?.state?.coach?.id : user.id
    }/`
  );

  const validatePhone = () => {
    setPhoneError(getPhoneError(phone));
  };

  const onJobChange = (event) => {
    setJobRole(event.target.value);
  };

  const onIndustryChange = (event) => {
    setIndustry(event.target.value);
  };

  const onCityChange = (event) => {
    setCity(event.target.value);
  };

  const onClientCompaniesChange = (event) => {
    setclientCompanies(event.target.value);
  };

  const onEducationChange = (event) => {
    setEducationRole(event.target.value);
  };

  const onLanguageChange = (event) => {
    setLanguageRole(event.target.value);
  };

  const onLocationChange = (event) => {
    setLocationRole(event.target.value);
  };

  const onCompaniesWorkedinChange = (event) => {
    setCompaniesWorkedinRole(event.target.value);
  };

  const onOtherCertificatesChange = (event) => {
    setOtherCertificatesRole(event.target.value);
  };

  const onDomainChange = (event) => {
    setDomainRole(event.target.value);
  };

  const addJobRole = (e) => {
    e.preventDefault();

    const existingJobRole = jobRolesOptions.find(
      (item) => item.label.trim().toLowerCase() === jobRole.trim().toLowerCase()
    );

    if (existingJobRole) {
      return notification.error({ message: "Job role already exists." });
    }

    setJobRolesOptions((prev) => [...prev, { label: jobRole, value: jobRole }]);
    setJobRole("");
    setTimeout(() => {
      jobRoleInputRef.current?.focus();
    }, 0);
  };

  const addIndustryOption = (e) => {
    e.preventDefault();

    const existingIndustry = industryOptions.find(
      (item) =>
        item.label.trim().toLowerCase() === industry.trim().toLowerCase()
    );

    if (existingIndustry) {
      return notification.error({ message: "Industry already exists." });
    }

    setIndustryOptions((prev) => [
      ...prev,
      { label: industry, value: industry },
    ]);
    setIndustry("");
    setTimeout(() => {
      industryInputRef.current?.focus();
    }, 0);
  };

  const addCityOption = (e) => {
    e.preventDefault();

    const existingCity = cityOptions.find(
      (item) => item.label.trim().toLowerCase() === city.trim().toLowerCase()
    );

    if (existingCity) {
      return notification.error({ message: "City already exists." });
    }

    setCityOptions((prev) => [...prev, { label: city, value: city }]);
    setCity("");
    setTimeout(() => {
      cityInputRef.current?.focus();
    }, 0);
  };

  const addClientCompaniesOption = (e) => {
    e.preventDefault();

    const existingClientCompanies = clientCompaniesOptions.find(
      (item) => item.label.trim().toLowerCase() === city.trim().toLowerCase()
    );

    if (existingClientCompanies) {
      return notification.error({
        message: "Client companies already exists.",
      });
    }

    setClientCompaniesOptions((prev) => {
      return [...prev, { label: clientCompanies, value: clientCompanies }];
    });
    setIndustry("");
    setTimeout(() => {
      clientCompaniesInputRef.current?.focus();
    }, 0);
  };

  const addEducationRole = (e) => {
    e.preventDefault();

    const existingEducationRole = educationRolesOptions.find(
      (item) =>
        item.label.trim().toLowerCase() === educationRole.trim().toLowerCase()
    );

    if (existingEducationRole) {
      return notification.error({ message: "Education already exists." });
    }

    setEducationRolesOptions((prev) => [
      ...prev,
      { label: educationRole, value: educationRole },
    ]);
    setEducationRole("");
    setTimeout(() => {
      educationRoleInputRef.current?.focus();
    }, 0);
  };

  const addLanguageRole = (e) => {
    e.preventDefault();

    const existingLanguageRole = languageRolesOptions.find(
      (item) =>
        item.label.trim().toLowerCase() === languageRole.trim().toLowerCase()
    );

    if (existingLanguageRole) {
      return notification.error({ message: "Language role already exists." });
    }

    setLanguageRolesOptions((prev) => [
      ...prev,
      { label: languageRole, value: languageRole },
    ]);
    setLanguageRole("");
    setTimeout(() => {
      languageRoleInputRef.current?.focus();
    }, 0);
  };

  const addLocationRole = (e) => {
    e.preventDefault();

    const existingLocationRole = locationRolesOptions.find(
      (item) =>
        item.label.trim().toLowerCase() === locationRole.trim().toLowerCase()
    );

    if (existingLocationRole) {
      return notification.error({ message: "Location already exists." });
    }

    setLocationRolesOptions((prev) => [
      ...prev,
      { label: locationRole, value: locationRole },
    ]);
    setLocationRole("");
    setTimeout(() => {
      locationRoleInputRef.current?.focus();
    }, 0);
  };

  const addCompaniesWorkedinRole = (e) => {
    e.preventDefault();

    const existingCompaniesWorkedinRole = companiesWorkedinRolesOptions.find(
      (item) =>
        item.label.trim().toLowerCase() ===
        companiesWorkedinRole.trim().toLowerCase()
    );

    if (existingCompaniesWorkedinRole) {
      return notification.error({ message: "Company already exists." });
    }

    setCompaniesWorkedinRolesOptions((prev) => [
      ...prev,
      { label: companiesWorkedinRole, value: companiesWorkedinRole },
    ]);
    setCompaniesWorkedinRole("");
    setTimeout(() => {
      companiesWorkedinRoleInputRef.current?.focus();
    }, 0);
  };

  const addOtherCertificatesRole = (e) => {
    e.preventDefault();

    const existingOtherCertificatesRole = otherCertificatesRolesOptions.find(
      (item) =>
        item.label.trim().toLowerCase() ===
        otherCertificatesRole.trim().toLowerCase()
    );

    if (existingOtherCertificatesRole) {
      return notification.error({ message: "Certificate already exists." });
    }

    setOtherCertificatesRolesOptions((prev) => [
      ...prev,
      { label: otherCertificatesRole, value: otherCertificatesRole },
    ]);
    setOtherCertificatesRole("");
    setTimeout(() => {
      otherCertificatesRoleInputRef.current?.focus();
    }, 0);
  };

  const addDomainRole = (e) => {
    e.preventDefault();

    const existingDomainRole = domainRolesOptions.find(
      (item) =>
        item.label.trim().toLowerCase() === domainRole.trim().toLowerCase()
    );

    if (existingDomainRole) {
      return notification.error({ message: "Domain already exists." });
    }

    setDomainRolesOptions((prev) => [
      ...prev,
      { label: domainRole, value: domainRole },
    ]);
    setDomainRole("");
    setTimeout(() => {
      domainRoleInputRef.current?.focus();
    }, 0);
  };

  const handleUpload = (file) => setCertificateUpload(file);

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const minimumFeePerHour = (_, value) => {
    // if (!value?.trim()) {
    //   return Promise.reject("Please input the minimum coach price!");
    // }
    if (value && !isWholeNumber(value?.trim())) {
      return Promise.reject("Please enter valid price");
    }
    if (value && isMaxCharCrossed(value.trim(), 5)) {
      return Promise.reject("Price cannot be more than 1,00,000");
    }
    return Promise.resolve();
  };

  const feeRemarkValidator = (_, value) => {
    if (value && isMaxCharCrossed(value.trim(), 100)) {
      return Promise.reject("Fee remark cannot exceed 100 characters.");
    }
    return Promise.resolve();
  };

  const linkedinValidator = (_, value) => {
    if (!value) {
      return Promise.reject("Please enter linkedin link.");
    }
    if (value && isMaxCharCrossed(value.trim(), 500)) {
      return Promise.reject("Link cannot exceed 500 characters.");
    }
    return Promise.resolve();
  };

  const validateCoachidOnChange = (_, value) => {
    if (!value?.trim()) {
      return Promise.reject("Please input the coach id.");
    }
    if (value && !isWholeNumber(value?.trim())) {
      return Promise.reject("Please enter number.");
    }
    if (value && isMaxCharCrossed(value?.trim(), 6)) {
      return Promise.reject("Id can only have 6 digit.");
    }
    if (value && value == 0) {
      return Promise.reject("Id cannot be zero.");
    }
    return Promise.resolve();
  };

  const validateCoachIDOnBlur = (_, value) => {
    if (value && isMinIntCrossed(value?.trim(), 6)) {
      return Promise.reject("Id should contain 6 digit.");
    }
    return Promise.resolve();
  };

  const validateConfirmPassword = (_, value) => {
    if (!value) {
      return Promise.reject("Please input the confirm password!");
    }

    const passwordFieldValue = form.getFieldValue("password");
    if (passwordFieldValue !== value) {
      return Promise.reject("Password does not match");
    }

    return Promise.resolve();
  };

  const handleInternalCoachChange = (values) => {
    setInternalCoachChange(values);
    if (values === false) {
      form.setFieldsValue({
        organization_of_coach: "",
      });
      form.validateFields(["organization_of_coach"]);
    }
  };
  const handleStatusChange = (value) => setStatusChange(value);

  const addCoachHandler = async (values) => {
    const { countryCallingCode, nationalNumber } = parsePhoneNumber(phone);

    const data = {
      ...values,
      phoneCountryCode: countryCallingCode,
      phone: nationalNumber,
      profilePic: profilePic,
    };

    try {
      addFacilitator(data);
      navigate(`/facilitator`);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const editCoachHandler = async (values) => {
    if (phoneError) {
      return;
    }
    // validating before completing
    const err = getPhoneError(phone);
    if (err) {
      return setPhoneError(err);
    }

    const { countryCallingCode, nationalNumber } = parsePhoneNumber(phone);

    let formData = new FormData();
    formData.append("first_name", values.firstName);
    formData.append("last_name", values.lastName);
    formData.append("email", values.email);
    formData.append(
      "area_of_expertise",
      JSON.stringify(values.areaOfExpertise ? values.areaOfExpertise : [])
    );

    formData.append(
      "client_companies",
      JSON.stringify(values.client_companies ? values.client_companies : [])
    );

    formData.append(
      "educational_qualification",
      JSON.stringify(
        values.educational_qualification ? values.educational_qualification : []
      )
    );

    formData.append("age", values.age);
    formData.append("gender", values.gender);
    formData.append(
      "domain",
      JSON.stringify(values.domain ? values.domain : [])
    );
    formData.append("phone", nationalNumber);
    formData.append("phone_country_code", countryCallingCode);
    formData.append(
      "language",
      JSON.stringify(values.language ? values.language : [])
    );

    formData.append(
      "job_roles",
      JSON.stringify(values.job_roles ? values.job_roles : [])
    );

    formData.append(
      "education",
      JSON.stringify(values.education ? values.education : [])
    );
    formData.append("fees_per_day", values.fees_per_day);
    formData.append("fees_per_hour", values.fees_per_hour);

    // formData.append(
    //   "fees_per_day",
    //   JSON.stringify(values.fees_per_day ? values.fees_per_day : [])
    // );
    // formData.append(
    //   "fees_per_hour",
    //   JSON.stringify(values.fees_per_hour ? values.fees_per_hour : [])
    // );

    formData.append("level", JSON.stringify(values.level ? values.level : []));
    formData.append("topic", JSON.stringify(values.topic ? values.topic : []));

    formData.append("currency", values.currency ? values.currency : "");

    formData.append(
      "linkedin_profile_link",
      values.linkedin_profile_link ? values.linkedin_profile_link : ""
    );
    formData.append(
      "companies_worked_in",
      JSON.stringify(
        values.companies_worked_in ? values.companies_worked_in : []
      )
    );
    formData.append(
      "other_certification",
      JSON.stringify(
        values.other_certification ? values.other_certification : []
      )
    );
    formData.append("city", JSON.stringify(values.city ? values.city : []));
    formData.append(
      "country",
      JSON.stringify(values.country ? values.country : [])
    );

    formData.append(
      "years_of_corporate_experience",
      values.corporateyearsOfExperience
    );

    if (profilePic && typeof profilePic !== "string") {
      formData.append("profile_pic", profilePic);
    }
    setError(null);
    editCoachPutData(formData);
  };

  useEffect(() => {
    if (editCoachData) {
      if (location?.state?.coach) {
        // when pmo edits
        if (coachUser?.is_approved === false) {
          navigate("/registeredcoach");
        } else {
          navigate("/facilitator");
        }
      } else {
        // when coach edits
        dispatch(updateUser(editCoachData));
        setIsEdit(false);
      }
    }
  }, [editCoachData]);

  const yearsOfExperienceValidator = (_, value) => {
    const age = form.getFieldValue("age");

    if (age && value) {
      const minExperience = value.split("-");

      if (+age - 18 <= +minExperience[0]) {
        return Promise.reject("Age can't be less than years of experience.");
      }
    }

    return Promise.resolve();
  };

  useEffect(() => {
    form.resetFields();
    setProfilePic(null);
    setPhone(null);
    if (isEditCoach) {
      const coach = setIsEdit ? { ...user } : { ...location.state.coach };
      console.log({
        fees_per_day: coach.fees_per_day,
        fees_per_hour: coach.fees_per_hour,
      });
      form.setFieldsValue({
        firstName:
          coach.first_name.charAt(0).toUpperCase() + coach.first_name.slice(1),
        lastName:
          coach.last_name.charAt(0).toUpperCase() + coach.last_name.slice(1),
        email: coach.email,
        areaOfExpertise: coach.area_of_expertise,
        client_companies: coach.client_companies,
        educational_qualification: coach.educational_qualification,
        age: String(coach?.age),
        gender: coach.gender,
        domain: coach.domain,
        city: coach.city,
        country: coach.country,
        topic: coach.topic,
        fees_per_day: coach.fees_per_day,
        fees_per_hour: coach.fees_per_hour,
        // phone: coach.phone,
        language: coach.language,
        job_roles: coach.job_roles,
        education: coach.education ? coach.education : undefined,
        level: coach.level,
        currency: coach.currency,
        linkedin_profile_link: coach.linkedin_profile_link,
        companies_worked_in: coach.companies_worked_in,
        other_certification: coach.other_certification,
        corporateyearsOfExperience: String(coach.years_of_corporate_experience),
      });

      if (coach.profile_pic) {
        setProfilePic(coach.profile_pic);
      }

      if (coach.phone) {
        setPhone(() => "+" + (coach.phone_country_code || "") + coach.phone);
      }
    }
  }, [isEditCoach, form, location]);

  useEffect(() => {
    if (coachFieldsData) {
      setJobRolesOptions(arrayToOptions(coachFieldsData.job_roles));
      setEducationRolesOptions(arrayToOptions(coachFieldsData.educations));
      setLanguageRolesOptions(arrayToOptions(coachFieldsData.languages));
      setLocationRolesOptions(arrayToOptions(coachFieldsData.locations));
      setCompaniesWorkedinRolesOptions(
        arrayToOptions(coachFieldsData.companies_worked_in)
      );
      setOtherCertificatesRolesOptions(
        arrayToOptions(coachFieldsData.other_certifications)
      );
      setDomainRolesOptions(arrayToOptions(coachFieldsData.domains));
      setIndustryOptions(arrayToOptions(coachFieldsData.industries));
      setClientCompaniesOptions(
        arrayToOptions(coachFieldsData.client_companies)
      );
      setCityOptions(arrayToOptions(coachFieldsData.city));
      setCountryOptions(arrayToOptions(coachFieldsData.country));
      setTopicOptions(arrayToOptions(coachFieldsData.topic));
      setQualificationOptions(
        arrayToOptions(coachFieldsData.educational_qualifications)
      );
    }
  }, [coachFieldsData]);

  const downloadPDF = (pdfUrl) => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "certificate.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (coachFieldsLoading) {
    return <Spin></Spin>;
  } else if (coachFieldsError) {
    return <p className="text-center">Some error occured</p>;
  } else if (coachFieldsData) {
    return (
      <>
        <Header>
          {isEditCoach
            ? setIsEdit
              ? "Edit Facilitator"
              : "Edit facilitator profile"
            : "Add Facilitator"}
        </Header>
        <PageContent>
          {isBulkUpload ? (
            <BulkCoachesUpload
              setIsBulkUpload={setIsBulkUpload}
            ></BulkCoachesUpload>
          ) : (
            <div className="px-[10px]">
              {user?.user?.type === "pmo" ? (
                <div className="flex items-center justify-between p-2">
                  {/* Bulk Upload */}
                  <Button
                    onClick={() => setIsBulkUpload(true)}
                    className="ml-auto"
                    type="primary"
                  >
                    Bulk Upload
                  </Button>
                </div>
              ) : (
                ""
              )}
              <div className="ml-[3rem]">
                <Form
                  onFinishFailed={() =>
                    notification.error({
                      message:
                        "Invalid Input: Please review the highlighted fields and correct the errors.",
                    })
                  }
                  form={form}
                  onFinish={isEditCoach ? editCoachHandler : addCoachHandler}
                >
                  <div className="flex gap-5">
                    <ImageUpload image={profilePic} setImage={setProfilePic} />
                  </div>
                  <div>
                    <div className="mt-[2rem]">
                      <div className="font-semibold text-xl border-b w-[97%]">
                        Immediate Details
                      </div>
                      <div className="mt-[1rem] lg:grid grow lg:grid-cols-2 sm:flex-col gap-x-8 max-w-[80rem]">
                        <Form.Item
                          name="firstName"
                          label="First Name"
                          labelCol={{ span: 24 }}
                          required
                          validateTrigger={VALIDATE_TRIGGERS}
                          rules={[
                            {
                              validator: validateFirstNameOnChange,
                              validateTrigger: "onChange",
                            },
                            {
                              validator: validateFirstNameOnBlur,
                              validateTrigger: "onBlur",
                            },
                          ]}
                        >
                          <Input
                            maxLength={21}
                            className="w-full"
                            placeholder="First name"
                          ></Input>
                        </Form.Item>

                        <Form.Item
                          name="lastName"
                          label="Last Name"
                          labelCol={{ span: 24 }}
                          required
                          validateTrigger={VALIDATE_TRIGGERS}
                          rules={[
                            {
                              validator: validateLastNameOnChange,
                              validateTrigger: "onChange",
                            },
                            {
                              validator: validateLastNameOnBlur,
                              validateTrigger: "onBlur",
                            },
                          ]}
                        >
                          <Input
                            maxLength={21}
                            className=""
                            placeholder="Last name"
                          ></Input>
                        </Form.Item>

                        <Form.Item
                          name="age"
                          label="Age"
                          labelCol={{ span: 24 }}
                          validateTrigger={VALIDATE_TRIGGERS}
                          rules={[
                            {
                              validator: ageValidationOnChange,
                              validateTrigger: "onChange",
                            },
                            {
                              validator: ageValidationOnBlur,
                              validateTrigger: "onBlur",
                            },
                          ]}
                        >
                          <Input
                            maxLength={3}
                            className=""
                            placeholder="Age"
                          ></Input>
                        </Form.Item>
                        <Form.Item
                          name="gender"
                          label="Gender"
                          labelCol={{ span: 24 }}
                          required
                          rules={[
                            {
                              required: true,
                              message: "Please select gender.",
                            },
                          ]}
                        >
                          <Select
                            options={GENDER}
                            className=""
                            placeholder="Gender"
                          ></Select>
                        </Form.Item>

                        {userType === "pmo" && (
                          <Form.Item
                            labelCol={{ span: 24 }}
                            label="E-mail"
                            name="email"
                            required
                            validateTrigger={VALIDATE_TRIGGERS}
                            rules={[
                              {
                                validator: () => Promise.resolve(),
                                validateTrigger: "onChange",
                              },
                              {
                                validator: validateEmailOnBlur,
                                validateTrigger: "onBlur",
                              },
                            ]}
                          >
                            <Input placeholder="Email" />
                          </Form.Item>
                        )}
                        <>
                          <div className="phone-input-wrapper">
                            <p className="phone-input-label py-[0.2px]">
                              Contact
                            </p>
                            <PhoneInput
                              className={
                                "border mt-4 px-2 py-1 rounded-lg" +
                                (phoneError ? " border-red-500" : "")
                              }
                              defaultCountry={"IN"}
                              placeholder="Enter phone number"
                              value={phone}
                              onChange={(value) => {
                                setPhone(value);
                                setPhoneError(undefined);
                                if (value?.length > 17) {
                                  setPhoneError("Max length exceeded.");
                                }
                              }}
                              maxLength={17}
                              onBlur={validatePhone}
                              error={
                                phone
                                  ? isValidPhoneNumber(phone)
                                    ? undefined
                                    : "Invalid phone number"
                                  : "Phone number required"
                              }
                            />
                            <p className="text-red-500">{phoneError}</p>
                          </div>
                        </>

                        <Form.Item
                          name="city"
                          label="City"
                          labelCol={{ span: 24 }}
                          rules={[
                            {
                              required: true,
                              message: "Please select city.",
                            },
                          ]}
                        >
                          <Select
                            options={cityOptions}
                            mode="tags"
                            className=" w-fit"
                            placeholder="Select city"
                          ></Select>
                        </Form.Item>
                        <Form.Item
                          name="country"
                          label="Country"
                          labelCol={{ span: 24 }}
                          rules={[
                            {
                              required: true,
                              message: "Please select country.",
                            },
                          ]}
                        >
                          <Select
                            options={countryOptions}
                            mode="tags"
                            className=" w-fit"
                            placeholder="Select Location"
                          ></Select>
                        </Form.Item>

                        <Form.Item
                          name="level"
                          label="Level"
                          labelCol={{ span: 24 }}
                          rules={[
                            {
                              required: true,
                              message: "Please select level.",
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            options={FACILITATOR_LEVEL}
                            className="w-full"
                            placeholder="Select Level"
                          ></Select>
                        </Form.Item>

                        <Form.Item
                          name="linkedin_profile_link"
                          label="Linkedin Profile Link"
                          labelCol={{ span: 24 }}
                          required
                          validateTrigger={VALIDATE_TRIGGERS}
                          rules={[{ validator: linkedinValidator }]}
                        >
                          <Input
                            className="w-full"
                            placeholder="Input linkedin profile link"
                            maxLength={501}
                          ></Input>
                        </Form.Item>
                      </div>
                    </div>
                    <div>
                      <div className="mt-[1rem] lg:grid grow lg:grid-cols-2 sm:flex-col gap-x-8 max-w-[80rem]">
                        <Form.Item
                          rules={[{ validator: validityCurrency }]}
                          name="currency"
                          label="Currency"
                          labelCol={{ span: 24 }}
                          required
                        >
                          <Select
                            className="min-w-[11.5rem]"
                            placeholder="Select Currency"
                            options={CURRENCY_LIST}
                          />
                        </Form.Item>

                        <Form.Item
                          name="fees_per_hour"
                          label={
                            <>
                              Minimum Fee Per Hour{" "}
                              <span className="text-gray-500 ml-[.5rem]">
                                (Max fee: 2,00,000 INR)
                              </span>
                            </>
                          }
                          labelCol={{ span: 24 }}
                          validateTrigger={VALIDATE_TRIGGERS}
                          // rules={[{ validator: minimumFeePerHour }]}
                          rules={[
                            {
                              validator: minFeesPerHourValidationOnChange,
                              validateTrigger: "onChange",
                            },
                            // {
                            //   validator: minFeesPerHourValidationOnBlur,
                            //   validateTrigger: "onBlur",
                            // },
                          ]}
                        >
                          <Input
                            maxLength={6}
                            className="w-full"
                            placeholder="Input fee per hour"
                          ></Input>
                        </Form.Item>
                        <Form.Item
                          name="fees_per_day"
                          label={
                            <>
                              Minimum Fee Per Day{" "}
                              <span className="text-gray-500 ml-[.5rem]">
                                (Max fee: 10,00,000 INR)
                              </span>
                            </>
                          }
                          labelCol={{ span: 24 }}
                          validateTrigger={VALIDATE_TRIGGERS}
                          // rules={[{ validator: minimumFeePerHour }]}
                          rules={[
                            {
                              validator: minFeesPerDayValidationOnChange,
                              validateTrigger: "onChange",
                            },
                            // {
                            //   validator: minFeesPerDayValidationOnBlur,
                            //   validateTrigger: "onBlur",
                            // },
                          ]}
                        >
                          <Input
                            maxLength={7}
                            className="w-full"
                            placeholder="Input fee per day"
                          ></Input>
                        </Form.Item>
                        <Form.Item
                          name="topic"
                          label="Topics"
                          labelCol={{ span: 24 }}
                          rules={[
                            {
                              required: true,
                              message: "Please select topics.",
                            },
                          ]}
                        >
                          <Select
                            options={topicOptions}
                            mode="tags"
                            className="w-full"
                            placeholder="Select topics"
                          />
                        </Form.Item>

                        <Form.Item
                          name="language"
                          label="Language Proficiency"
                          labelCol={{ span: 24 }}
                        >
                          <Select
                            options={languageRolesOptions}
                            className="w-full"
                            placeholder="Select Languages"
                            mode="tags"
                          ></Select>
                        </Form.Item>

                        <Form.Item
                          name="client_companies"
                          label="Client Companies"
                          labelCol={{ span: 24 }}
                          // required
                        >
                          <Select
                            options={clientCompaniesOptions}
                            mode="tags"
                            className=" w-fit"
                            placeholder="Input or select client companies."
                          ></Select>
                        </Form.Item>
                        <Form.Item
                          name="other_certification"
                          label={
                            <div>
                              Assessment Certification{" "}
                              <span className="ml-[.3rem] text-gray-400">
                                (Eg: Hogan Certified)
                              </span>{" "}
                            </div>
                          }
                          labelCol={{ span: 24 }}
                        >
                          <Select
                            options={otherCertificatesRolesOptions}
                            mode="tags"
                            className=" w-fit"
                            placeholder="Select certificates"
                          ></Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="mt-[2rem]">
                      <div className="font-semibold text-xl border-b w-[97%]">
                        Corporate Experience
                      </div>
                      <div className="mt-[1rem] lg:grid grow lg:grid-cols-2 sm:flex-col gap-x-8 max-w-[80rem]">
                        <Form.Item
                          name="companies_worked_in"
                          label="Companies Worked In"
                          labelCol={{ span: 24 }}
                        >
                          <Select
                            options={companiesWorkedinRolesOptions}
                            mode="tags"
                            className=" w-fit"
                            placeholder="Select companies "
                          ></Select>
                        </Form.Item>

                        <Form.Item
                          name="job_roles"
                          label="Job Roles"
                          labelCol={{ span: 24 }}
                        >
                          <Select
                            options={jobRolesOptions}
                            mode="tags"
                            className="w-full"
                            placeholder="Select job roles"
                          ></Select>
                        </Form.Item>

                        <Form.Item
                          name="areaOfExpertise"
                          label="Industry"
                          labelCol={{ span: 24 }}
                        >
                          <Select
                            options={industryOptions}
                            mode="tags"
                            maxTagCount={5}
                            className=" w-fit"
                            placeholder="Select industry"
                          ></Select>
                        </Form.Item>

                        <Form.Item
                          name="domain"
                          label="Functional Domain"
                          labelCol={{ span: 24 }}
                        >
                          <Select
                            options={domainRolesOptions}
                            mode="tags"
                            className=""
                            placeholder="Select Domain"
                          ></Select>
                        </Form.Item>

                        <Form.Item
                          dependencies={["age"]}
                          rules={[
                            {
                              validator: yearsOfExperienceValidator,
                            },
                          ]}
                          name="corporateyearsOfExperience"
                          label="Corporate Years of Experience"
                          labelCol={{ span: 24 }}
                        >
                          <Select
                            options={EXPERIENCE_LEVEL}
                            className=" w-fit"
                            placeholder="Corporate Years of experience"
                          ></Select>
                        </Form.Item>
                      </div>
                    </div>

                    <div className="mt-[2rem]">
                      <div className="font-semibold text-xl border-b w-[97%]">
                        Educational Institution
                      </div>
                      <div className="mt-[1rem] lg:grid grow lg:grid-cols-2 sm:flex-col gap-x-8 max-w-[80rem]">
                        <Form.Item
                          name="education"
                          label="Institution"
                          labelCol={{ span: 24 }}
                        >
                          <Select
                            mode="tags"
                            options={educationRolesOptions}
                            className="w-full"
                            placeholder="Education"
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? "").includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                          ></Select>
                        </Form.Item>
                        <Form.Item
                          name="educational_qualification"
                          label="Educational Qualification"
                          labelCol={{ span: 24 }}
                        >
                          <Select
                            options={qualificationOptions}
                            mode="tags"
                            className="w-full"
                            placeholder="Education"
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? "").includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                          ></Select>
                        </Form.Item>
                      </div>
                    </div>

                    <div className="pt-4 col-span-3 w-full flex gap-3  justify-center">
                      <Form.Item>
                        <Button
                          loading={
                            loading || isMeetingLinkLoading || editCoachLoading
                          }
                          type="primary"
                          htmlType="submit"
                          className="border"
                        >
                          {isEditCoach ? "Save" : "Add"}
                        </Button>
                      </Form.Item>
                      <Button
                        onClick={
                          Boolean(setIsEdit)
                            ? () => setIsEdit(false)
                            : () => {
                                if (
                                  coachUser?.is_approved === false &&
                                  userType === "pmo"
                                ) {
                                  navigate("/registeredcoach");
                                } else {
                                  navigate("/facilitator");
                                }
                              }
                        }
                        type="default"
                        className="border "
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>{" "}
            </div>
          )}
        </PageContent>
      </>
    );
  } else {
    return <p className="text-center">Some error occured</p>;
  }
};
