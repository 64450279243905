import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Input, Modal, Select, Tooltip } from "antd";
// import { getBatchesList } from "redux-management";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";
import axios from "axios";
import { DatePicker } from "antd";
import moment from "moment";
import { useGetApi } from "../hooks/useGetApi";
import dayjs from "dayjs";

const FinalPreview = () => {
  const location = useLocation();
  const { template, subject } = location.state || {};
  const [isScheduleMail, setIsScheduleMail] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [valueSubject2, setValueSubject2] = useState(subject);
  const [copiedText, setCopiedText] = useState(null);
  // const { batchesList } = useSelector((state) => state.batchView);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [fetchingLearners, setFetchingLearners] = useState({
    loading: true,
    error: false,
    data: [],
  });

  const { data: dataBatch, isLoading: isLoadingBatch } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/all_batches/`
  );

  // const options = dataBatch?.map((batch) => ({
  //   value: batch.id, // Use a unique identifier as the value
  //   label: batch.name, // Display name as the label
  // }));

  const [isModalOpenSendEmail, setIsModalOpenSendEmail] = useState(false);
  const showModalSendEmail = () => {
    setIsModalOpenSendEmail(true);
  };
  const handleOkSendEmail = () => {
    handleSendEmails();
    setIsModalOpenSendEmail(false);
  };
  const handleCancelSendEmail = () => {
    setIsModalOpenSendEmail(false);
  };

  const storedDateTime = moment(selectedDateTime).format("DD-MM-YYYY hh:mm A");
  const currentDateTime = moment().format("DD-MM-YYYY hh:mm A");
  const storedMoment = moment(storedDateTime, "DD-MM-YYYY hh:mm A");
  const currentMoment = moment(currentDateTime, "DD-MM-YYYY hh:mm A");
  const differenceInMillis = storedMoment.diff(currentMoment);

  // Convert milliseconds to seconds
  const differenceInSeconds = differenceInMillis / 1000;
  // console.log(differenceInSeconds);
  // useEffect(() => {
  //   dispatch(getBatchesList());
  // }, [dispatch]);

  useEffect(() => {
    const fetchLearnersByBatch = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/schedular/get-learner-by-batch/${selectedBatch}`
        );
        setFetchingLearners({
          loading: false,
          error: false,
          data: response.data,
        });
      } catch (err) {
        setFetchingLearners({
          loading: false,
          error: true,
          data: [],
        });
      }
    };

    if (selectedBatch) {
      fetchLearnersByBatch();
    }
  }, [selectedBatch]);

  const currentTime = moment().format("h:mm:ss A");

  const learnersDataForTable = fetchingLearners?.data?.map((learner) => ({
    name: learner.name,
    email: learner.email,
    phone: learner.phone,
  }));

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 50,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 50,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 50,
      render: (phone) => (phone ? phone : "N/A"),
    },
  ];

  const handleBatchChange = (value) => {
    setSelectedBatch(value);
  };

  const handleCodeClick = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setCopiedText(text);
    setTimeout(() => setCopiedText(null), 3000); // Clear copied text after 3 seconds
  };

  const handleDate = (value) => {
    setSelectedDateTime(null);
  };
  const handleDatePickerChange = (value) => {
    setSelectedDateTime(value);
  };

  const disabledDate = (current) => {
    return current && current <= moment().startOf("day");
  };

  const disabledHours = () => {
    if (selectedDateTime) {
      const currentHour = moment().hour();
      const selectedHour = selectedDateTime.hour();

      if (selectedDateTime.isSame(moment(), "day")) {
        return Array.from({ length: currentHour }, (_, i) => i);
      }
    }

    // Enable all hours for other dates
    return [];
  };

  const disabledMinutes = (selectedHour) => {
    if (selectedDateTime && selectedDateTime.hour() === selectedHour) {
      const currentMinute = moment().minute();
      const selectedMinute = selectedDateTime.minute();

      if (
        selectedDateTime.isSame(moment(), "day") &&
        selectedHour === moment().hour()
      ) {
        return Array.from({ length: currentMinute }, (_, i) => i);
      }
    }

    return [];
  };

  useEffect(() => {
    const currentDateTime = dayjs(); // Get the current date and time using moment
    setSelectedDateTime(currentDateTime);
  }, []);

  const handleSendEmails = async () => {
    if (selectedBatch) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/schedular/send_mails/`,
          {
            recipients_data: fetchingLearners?.data?.map((item) => ({
              name: item.name,
              email: item.email,
            })),
            template_id: template.id,
            subject: valueSubject2,
            scheduledFor: isScheduleMail ? selectedDateTime?.$d : new Date(),
          }
        );

        if (response.status === 200) {
          navigate("/successpage", { state: { isScheduleMail } });
        }
      } catch (error) {
        console.error("Error sending emails:", error);
      }
    } else {
      console.log("Please select a batch before sending emails.");
    }
  };

  useEffect(() => {
    if (!template.id) {
      navigate("/email");
    }
  }, [template, navigate]);

  return (
    <main className="xl:col-start-2 pt-4 xl:col-end-7 w-[98%] mx-auto">
      <div>
        <div className="p-2 font-semibold text-lg border-b border-gray-300 mb-[1rem] flex gap-2">
          <div>
            <ArrowLeftOutlined onClick={() => navigate(-1)} />
          </div>
          <div className="p-1">Send Email</div>
          <div className="ml-auto">
            <Tooltip
              title={
                !selectedDateTime && isScheduleMail
                  ? "Add Date and Time to Schedule"
                  : valueSubject2?.length === 0 || !selectedBatch
                  ? "Select batch and enter subject to send mail."
                  : ""
              }
            >
              <Button
                type="primary"
                disabled={
                  valueSubject2?.length === 0 ||
                  !selectedBatch ||
                  (!selectedDateTime && isScheduleMail)
                }
                onClick={showModalSendEmail}
              >
                {isScheduleMail ? "Send" : "Send Now"}
              </Button>
            </Tooltip>
          </div>
        </div>
        {/* <div className="mb-[1rem] ml-[2rem]">
          <div className="max-w-full mx-auto bg-white p-6 rounded shadow mb-4">
            <h3 className="text-2xl  mb-4">Adding Variables to Content</h3>
            <p className="mb-2">
              Make sure there are no spaces between the curly braces and the
              variable name.
            </p>
            <ul className="list-disk pl-6 mt-2">
              <li onClick={() => handleCodeClick("{{learnerName}}")}>
                If you want to include the Learner name, use{" "}
                <code className="bg-gray-200 p-1 rounded cursor-pointer">
                  &#123;&#123;learnerName&#125;&#125;
                </code>{" "}
                <CopyOutlined className="!text-[16px] cursor-pointer" />
                &nbsp; in your text.
              </li>
            </ul>
            <div className="mt-4 h-5">
              {copiedText && (
                <p className="text-green-600 ">Copied: {copiedText}</p>
              )}
            </div>
          </div>
        </div> */}
        <div className="mb-[1rem] px-8">
          <div className="flex gap-2 mb-[.5rem]">
            <div className="text-lg font-semibold mr-[1.8rem]">From:</div>
            <div className="text-lg">info@mail.meeraq.com</div>
          </div>
          <div className="flex gap-2">
            <div className="text-lg font-semibold">
              Subject<span className="text-red-500">*</span>:
            </div>{" "}
            <div className="text-lg">
              <Input
                placeholder="Enter subject for this email.."
                value={valueSubject2}
                onChange={(e) => setValueSubject2(e.target.value)}
                maxLength={200}
              />
            </div>
          </div>
          {isScheduleMail ? (
            <>
              <div className="flex gap-2 pt-2">
                <div className="text-lg font-semibold">
                  Scheduled For:<span className="text-red-500">*</span>:
                </div>
                <div className="text-lg">
                  <DatePicker
                    disabledDate={disabledDate}
                    showTime={{
                      format: "hh:mm A",
                      disabledHours: disabledHours,
                      disabledMinutes: disabledMinutes,
                    }}
                    format="DD-MM-YYYY hh:mm A"
                    onSelect={handleDatePickerChange}
                    value={selectedDateTime}
                    onChange={handleDate}
                  />
                </div>
                <Button
                  onClick={() => {
                    setSelectedDateTime(null);
                    setIsScheduleMail(false);
                  }}
                >
                  Cancel Schedule
                </Button>
              </div>
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  setSelectedDateTime(dayjs());
                  setIsScheduleMail(true);
                }}
                style={{ marginTop: "10px" }}
              >
                Schedule Mail
              </Button>
            </>
          )}
        </div>
        <div className="flex gap-10 px-8">
          <div className="border-2">
            <div dangerouslySetInnerHTML={{ __html: template.template_data }} />
          </div>
          <div className="flex flex-col gap-5">
            <div>
              <Select
                className="w-full"
                value={selectedBatch}
                onChange={handleBatchChange}
                placeholder="Select batch"
              >
                <Select.Option value="" disabled hidden>
                  Select Batch
                </Select.Option>
                {dataBatch?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
              <div>
                {selectedBatch ? (
                  <p>
                    You have selected:{" "}
                    {dataBatch.find((item) => item.id === selectedBatch)?.name}
                  </p>
                ) : (
                  <p>Please select a batch</p>
                )}
              </div>
            </div>
            <div className={selectedBatch ? " bg-gray-200 p-4 rounded-lg" : ""}>
              {fetchingLearners.loading === false &&
                fetchingLearners.error === false && (
                  <>
                    <p className="pl-8 mb-[1rem] font-semibold border-black border-b-2 ">
                      Total Learners: {fetchingLearners?.data?.length}
                    </p>
                    <div
                      className={
                        fetchingLearners.data?.length > 8 ? "overflow-auto" : ""
                      }
                    >
                      {learnersDataForTable?.map((learner, index) => (
                        <div className="flex gap-3">
                          <div>
                            <ul
                              style={{
                                listStyle: "disc",
                                margin: 0,
                                paddingInlineStart: "10px",
                                color: "black",
                              }}
                            >
                              <li></li>
                            </ul>
                          </div>
                          <div className="mb-[1rem]">
                            <div>{learner?.name}</div>
                            <div>{learner?.email}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Confirm Send Email To All"
        footer={false}
        open={isModalOpenSendEmail}
        onOk={handleOkSendEmail}
        onCancel={handleCancelSendEmail}
      >
        <div className="flex flex-col">
          {!isScheduleMail ? (
            <div>Are you sure you want to send this Email Immediately ?</div>
          ) : (
            <div>
              This mail is Scheduled for <strong> {selectedDateTime?.format("DD-MM-YYYY hh:mm A")} </strong>.
              Are you sure ?
            </div>
          )}

          <div className="ml-auto mt-[2rem]">
            <Button
              type="primary"
              className="mr-[1rem]"
              onClick={handleCancelSendEmail}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={handleOkSendEmail}>
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </main>
  );
};

export { FinalPreview };
