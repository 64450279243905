import { Button } from "antd";
import React from "react";
import { writeFile, utils } from "xlsx";
import { DownloadOutlined } from "@ant-design/icons";

export const DownloadSampleExcel = () => {
  const handleClick = () => {
    // create sample data
    const data = [
      {
        name: "John Doe",
        email: "johndoe@example.com",
        phone: "123-456-7890",
        batch: "IWA-120223",
      },
    ];
    // create workbook and worksheet
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(data);
    // set column headers
    ws["A1"] = { t: "s", v: "name" };
    ws["B1"] = { t: "s", v: "email" };
    ws["C1"] = { t: "s", v: "phone" };
    ws["D1"] = { t: "s", v: "batch" };

    // add worksheet to workbook
    utils.book_append_sheet(wb, ws, "Sheet1");
    // save file
    writeFile(wb, "sample.xlsx");
  };

  return (
    <div>
      <Button icon={<DownloadOutlined />} onClick={handleClick} type="text">
        <span className="">Download Sample File</span>
      </Button>
    </div>
  );
};
