import { createSlice } from "@reduxjs/toolkit";
import {
  getCoachingSessionDetails,
  setCoachingSessionDetails,
} from "./coachingSessionSlotBookingServices";

const initialState = {
  coachingSessionSlotBookingStatus: "fulfilled",
  coachingSessionSlotBookingError: null,
  deliveryReport: [],
  sessionDetails: null,
};

export const coachingSessionSlotBookingSlice = createSlice({
  name: "coachingSessionSlotBooking",
  initialState,
  reducers: {},
  extraReducers: {
    [getCoachingSessionDetails.pending]: (state) => {
      state.coachingSessionSlotBookingStatus = "pending";
    },
    [getCoachingSessionDetails.fulfilled]: (state, action) => {
      state.coachingSessionSlotBookingStatus = "fulfilled";
      state.sessionDetails = action.payload;
    },
    [getCoachingSessionDetails.rejected]: (state, action) => {
      state.coachingSessionSlotBookingStatus = "rejected";
      state.coachingSessionSlotBookingError = action.payload;
    },
    [setCoachingSessionDetails.pending]: (state) => {
      state.coachingSessionSlotBookingStatus = "pending";
    },
    [setCoachingSessionDetails.fulfilled]: (state, action) => {
      state.coachingSessionSlotBookingStatus = "fulfilled";
    },
    [setCoachingSessionDetails.rejected]: (state, action) => {
      state.coachingSessionSlotBookingStatus = "rejected";
      state.coachingSessionSlotBookingError = action.payload;
    },
  },
});

export default coachingSessionSlotBookingSlice.reducer;
