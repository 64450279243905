import React, { useState } from "react";
import { selectPeers, useHMSStore, useHMSActions } from "@100mslive/react-sdk";
import { Peer } from "./Peer";
import { useEffect, useContext } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";
import { Controllers } from "./Controllers";
import { Chat } from "./Chat";

function useBackListener(callback) {
  const navigator = useContext(UNSAFE_NavigationContext).navigator;

  useEffect(() => {
    const listener = ({ location, action }) => {
      if (action === "POP") {
        callback({ location, action });
      }
    };

    const unlisten = navigator.listen(listener);
    return unlisten;
  }, [callback, navigator]);
}

function MeetingRoom({ setRoomEnded }) {
  const peers = useHMSStore(selectPeers);
  const hmsActions = useHMSActions();
  const [isChatActive, setIsChatActive] = useState(false);

  // useBackListener(() => hmsActions.leave());

  const peersWrapperClassNames =
    "h-[calc(100vh-7.75rem)] relative grow items-center p-2 justify-center gap-2 " +
    (isChatActive ? "hidden md:flex" : "flex flex-wrap sm:flex-nowrap");

  return (
    <div className="h-[calc(100vh-4rem)] flex flex-col relative">
      <div className="flex grow bg-gray-200 gap-4">
        <div className={peersWrapperClassNames}>
          {peers.map((peer) => {
            return <Peer key={peer.id} peer={peer} noOfPeers={peers.length} />;
          })}
        </div>
        {isChatActive && (
          <Chat setIsChatActive={setIsChatActive} isChatActive={isChatActive} />
        )}
      </div>
      <Controllers
        setRoomEnded={setRoomEnded}
        setIsChatActive={setIsChatActive}
      />
    </div>
  );
}

export { MeetingRoom };
