import { createSlice } from "@reduxjs/toolkit";
import { addCoach, getCoaches, updateCoach } from "./manageCoachesServices";

const initialState = {
  manageCoachesStatus: "pending",
  allCoachesList: [],
  coachesError: null,
  addCoachStatus: "idle",
  updateCoachStatus: "idle",
};

export const manageCoaches = createSlice({
  name: "manageCoaches",
  initialState,
  reducers: {},
  extraReducers: {
    [getCoaches.pending]: (state) => {
      state.manageCoachesStatus = "pending";
    },
    [getCoaches.fulfilled]: (state, action) => {
      state.allCoachesList = action.payload;
      state.manageCoachesStatus = "fulfilled";
    },
    [getCoaches.rejected]: (state, action) => {
      state.manageCoachesStatus = "rejected";
      state.coachesError = action.payload;
    },
    [addCoach.pending]: (state) => {
      state.addCoachStatus = "pending";
    },
    [addCoach.fulfilled]: (state) => {
      state.addCoachStatus = "fulfilled";
    },
    [addCoach.rejected]: (state, action) => {
      state.addCoachStatus = "rejected";
      state.coachesError = action.payload;
    },
    [updateCoach.pending]: (state) => {
      state.updateCoachStatus = "pending";
    },
    [updateCoach.fulfilled]: (state, action) => {
      state.updateCoachStatus = "fulfilled";
    },
    [updateCoach.rejected]: (state, action) => {
      state.updateCoachStatus = "rejected";
      state.coachesError = action.payload;
    },
  },
});

export default manageCoaches.reducer;
