const isNaturalNumber = (value) => {
  // Check if the value is a positive integer
  return Number.isInteger(+value) && +value > 0;
};

const isMaxCharCrossed = (value, count) => {
  if (value.length > count) {
    return true;
  }
  return false;
};

const isMaxIntCrossed = (value, count) => {
  if (value.length > count) {
    return true;
  }
  return false;
};

const isMinIntCrossed = (value, count) => {
  if (value.length < count) {
    return true;
  }
  return false;
};

const startingWithZero = (value) => {
  if (value.startsWith("0")) {
    return true;
  }
  return false;
};

const isAlphabetsAndSpaceAndDot = (str) => {
  // Regular expression to match only alphabets, space, and dot
  const regex = /^[A-Za-z\s\.]+$/;
  // Test if the string matches the regular expression
  return regex.test(str);
};

const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export {
  startingWithZero,
  isMaxCharCrossed,
  isMaxIntCrossed,
  isNaturalNumber,
  validateEmail,
  isAlphabetsAndSpaceAndDot,
  isMinIntCrossed,
};
