import { createSlice } from "@reduxjs/toolkit";
import {
  addLearner,
  getLearners,
  updateLearner,
} from "./manageLearnersServices";

const initialState = {
  manageLearnersStatus: "pending",
  learnersList: [],
  learnerError: null,
};

export const manageLearners = createSlice({
  name: "manageLearners",
  initialState,
  reducers: {},
  extraReducers: {
    [getLearners.pending]: (state) => {
      state.manageLearnersStatus = "pending";
    },
    [getLearners.fulfilled]: (state, action) => {
      state.learnersList = action.payload;
      state.manageLearnersStatus = "fulfilled";
    },
    [getLearners.rejected]: (state, action) => {
      state.manageLearnersStatus = "rejected";
      state.learnerError = action.payload;
    },
    [addLearner.pending]: (state) => {
      state.manageLearnersStatus = "pending";
    },
    [addLearner.fulfilled]: (state) => {
      state.manageLearnersStatus = "fulfilled";
    },
    [addLearner.rejected]: (state, action) => {
      state.manageLearnersStatus = "rejected";
      state.learnerError = action.payload;
    },
    [updateLearner.pending]: (state) => {
      state.manageLearnersStatus = "pending";
    },
    [updateLearner.fulfilled]: (state) => {
      state.manageLearnersStatus = "fulfilled";
    },
    [updateLearner.rejected]: (state, action) => {
      state.manageLearnersStatus = "rejected";
      state.learnerError = action.payload;
    },
  },
});

export default manageLearners.reducer;
