import { createSlice } from "@reduxjs/toolkit";
import { deleteConfirmedSession } from "./confirmedSessionsServices";

const initialState = {
  confirmedSessionsStatus: "idle",
  confirmedSessionsError: null,
  expandedRowKeys: null,
};

export const confirmedSessionsSlice = createSlice({
  name: "confirmedSessions",
  initialState,
  reducers: {
    setExpandedRowKeys: (state, action) => {
      state.expandedRowKeys = action.payload;
    },
  },
  extraReducers: {
    [deleteConfirmedSession.pending]: (state) => {
      state.confirmedSessionsStatus = "pending";
    },
    [deleteConfirmedSession.fulfilled]: (state, action) => {
      state.confirmedSessionsStatus = "fulfilled";
    },
    [deleteConfirmedSession.rejected]: (state, action) => {
      state.confirmedSessionsStatus = "rejected";
      state.confirmedSessionsError = action.payload;
    },
  },
});

export const { setExpandedRowKeys } = confirmedSessionsSlice.actions;

export default confirmedSessionsSlice.reducer;
