import {
  selectAudioTrackByPeerID,
  selectVideoTrackByPeerID,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { useRef, useEffect } from "react";
import { MdMicOff } from "react-icons/md";

function Peer({ peer, preview }) {
  const videoRef = useRef(null);
  const hmsActions = useHMSActions();
  const videoTrack = useHMSStore(selectVideoTrackByPeerID(peer.id));
  const audioTrack = useHMSStore(selectAudioTrackByPeerID(peer.id));

  useEffect(() => {
    if (videoRef.current && videoTrack) {
      if (videoTrack.enabled) {
        hmsActions.attachVideo(videoTrack.id, videoRef.current);
      } else {
        hmsActions.detachVideo(videoTrack.id, videoRef.current);
      }
    }
  }, [videoTrack, hmsActions]);

  return (
    <div
      className={`min-w-[250px] max-w-[300px] w-[50%] relative ${
        preview ? `md:max-w-[45rem] sm:w-full grow ` : "sm:max-w-[43.75rem]"
      }  `}
    >
      {videoTrack?.enabled ? (
        <video
          className={`w-full border-[#A63295] border-[2px] rounded-[15px] overflow-hidden -scale-x-100 ${
            preview ? "aspect-[16/10] object-cover" : " "
          }`}
          ref={videoRef}
          autoPlay
          muted
          playsInline
        />
      ) : (
        <div
          className={` md:w-auto flex items-center justify-center bg-[white] border-[#A63295] border-[2px] rounded-[15px] ${
            preview
              ? "md:max-w-[45rem] sm:w-full aspect-[16/10]"
              : "aspect-[4/3]"
          }`}
        >
          <p className="min-w-[2.4em] h-auto text-3xl aspect-square p-4 text-center text-[#A63295] rounded-full bg-gray-200">
            {peer?.name?.split("")[0]?.toUpperCase()}
          </p>
        </div>
      )}
      <div className="text-center capitalize">
        {peer?.name} {peer?.isLocal ? "(You)" : ""}
      </div>
      {!peer.isLocal && !audioTrack?.enabled && (
        <span className="absolute top-2 right-2 border rounded-full p-2">
          <MdMicOff />
        </span>
      )}
    </div>
  );
}

export { Peer };
