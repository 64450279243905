import { createSlice } from "@reduxjs/toolkit";
import {
  getAdminRequests,
  getConfirmedSlotsByRequestId,
  setCoachingSessionDates,
} from "./requestSessionsServices";

const initialState = {
  defineCoachingSessionsStatus: "fulfilled",
  defineCoachingSessionsError: null,
  statusArrayForTimePicker: 0,
  confirmedSlotsByRequestId: [],
  adminRequests: [],
  makeRequestStatus: "idle",
};

export const requestSessions = createSlice({
  name: "requestSessions",
  initialState,
  reducers: {
    setStatusArrayForTimePicker: (state, action) => {
      if (action.payload === "increment") {
        state.statusArrayForTimePicker = state.statusArrayForTimePicker + 1;
      }
      if (action.payload === "decrement") {
        state.statusArrayForTimePicker = state.statusArrayForTimePicker - 1;
      }
    },
  },
  extraReducers: {
    [setCoachingSessionDates.pending]: (state) => {
      state.makeRequestStatus = "pending";
    },
    [setCoachingSessionDates.fulfilled]: (state, action) => {
      state.makeRequestStatus = "fulfilled";
    },
    [setCoachingSessionDates.rejected]: (state, action) => {
      state.makeRequestStatus = "rejected";
      state.defineCoachingSessionsError = action.payload;
    },
    [getAdminRequests.pending]: (state) => {
      state.defineCoachingSessionsStatus = "pending";
    },
    [getAdminRequests.fulfilled]: (state, action) => {
      state.adminRequests = action.payload;
      state.defineCoachingSessionsStatus = "fulfilled";
    },
    [getAdminRequests.rejected]: (state, action) => {
      state.defineCoachingSessionsStatus = "rejected";
      state.defineCoachingSessionsError = action.payload;
    },
    [getConfirmedSlotsByRequestId.pending]: (state) => {
      state.defineCoachingSessionsStatus = "pending";
    },
    [getConfirmedSlotsByRequestId.fulfilled]: (state, action) => {
      state.confirmedSlotsByRequestId = action.payload;
      state.defineCoachingSessionsStatus = "fulfilled";
    },
    [getConfirmedSlotsByRequestId.rejected]: (state, action) => {
      state.defineCoachingSessionsStatus = "rejected";
      state.defineCoachingSessionsError = action.payload;
    },
  },
});

export const { setStatusArrayForTimePicker } = requestSessions.actions;
export default requestSessions.reducer;
