import { Alert, Button, Table, Upload, Tooltip } from "antd";
import React, { useState, useMemo, useEffect } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { read, utils } from "xlsx";
import { DownloadSampleExcel } from "./DownloadSampleExcel";
import { InfoOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { CustomAlert } from "../custom-alert/CustomAlert";
import { usePostApi } from "../hooks/usePostApi";
import { useParams } from "react-router-dom";

const EXPECTED_COLUMNS = ["name", "email", "phone", "batch"];

const WRONG_FILE =
  "File that you selected is wrong. Check the sample file below for more details.";
const MISSING_DATA =
  "Name, Email, Phone or Batch is missing in coachee details. Please check and upload again.";

const Uploader = ({ handleUpload }) => {
  return (
    <Upload.Dragger
      className="w-full"
      accept=".xlsx,.xls"
      multiple={false} // Only allow one
      beforeUpload={(file) => {
        handleUpload(file);
        return false;
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload Coachee
      </p>
      <p>File formats: .xlsx or .xls </p>
      {/* <Button className="w-full">Select File</Button> */}
    </Upload.Dragger>
  );
};

export const LearnerModal = ({
  setData,
  setColumns,
  data,
  columns,
  setIsModalOpen,
  getBatches,
}) => {
  const { id: project_id } = useParams();

  const {
    isLoading: addBatchLoading,
    postData: addBatch,
    data: addBatchData,
  } = usePostApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/add_learner_to_batch/${project_id}/`
  );

  const [isFileSelected, setIsFileSelected] = useState(
    data?.length > 0 ? true : false
  );

  const handleUpload = (file) => {
    const reader = new FileReader();
    setIsFileSelected(false);
    reader.onload = (e) => {
      const binaryData = e.target.result;
      setIsFileSelected(true);

      const workbook = read(binaryData, { type: "binary" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const data = utils.sheet_to_json(worksheet, { header: 1 });

      const nonEmptyRows = data.filter((row) =>
        row.some((cell) => cell !== null && cell?.trim() !== "")
      );

      if (nonEmptyRows.length === 0) {
        // Handle case when all rows are empty
        setData([]);
        setColumns([]);
        return;
      }

      const columns = nonEmptyRows.shift().map((col) => ({
        title: col,
        dataIndex: col,
      }));

      const formattedData = nonEmptyRows.map((row) => {
        return columns.reduce((obj, column, index) => {
          obj[column.dataIndex] = row[index];
          return obj;
        }, {});
      });

      setData(formattedData);
      setColumns(columns);
    };
    reader.readAsBinaryString(file);
  };

  let error = null;
  let lineNo = null;
  if (columns.length > 0) {
    // checking if the columns are same or not
    for (let i = 0; i < columns.length; i++) {
      if (EXPECTED_COLUMNS[i] !== columns[i].title) {
        error = WRONG_FILE;
        break;
      }
    }
    if (!error) {
      for (let index in data) {
        if (
          Boolean(data[index]["name"]?.trim()) &&
          Boolean(data[index]["email"]?.trim()) &&
          Boolean(data[index]["phone"]) &&
          Boolean(data[index]["batch"]?.trim())
        ) {
          continue;
        } else {
          lineNo = +index + 1;
          error = MISSING_DATA;
          break;
        }
      }
    }
  } else {
    error = WRONG_FILE;
  }

  const handleAddBatch = (participants) => {
    addBatch({
      participants,
    });
  };

  useEffect(() => {
    if (addBatchData) {
      getBatches();
    }
  }, [addBatchData, getBatches]);

  return (
    <div>
      {isFileSelected ? (
        <>
          {error === WRONG_FILE ? (
            <>
              <Alert
                type="error"
                message={WRONG_FILE}
                showIcon
                className="mb-4"
              />
              <Uploader handleUpload={handleUpload} />
            </>
          ) : (
            <>
              <p className="px-2 font-semibold mb-4">Data: </p>
              {error === MISSING_DATA && (
                <Alert
                  className="mt-2 mb-4"
                  message={`Row no. ${lineNo} ${MISSING_DATA}`}
                  type="error"
                  showIcon
                />
              )}
              <Table
                pagination={{ pageSize: 5 }}
                className="text-black"
                dataSource={data}
                columns={columns}
              />
            </>
          )}
        </>
      ) : (
        <>
          <CustomAlert
            className="my-2 bg-sky-100"
            icon={<InfoOutlined />}
            message={
              "Download sample excel and use the same template to upload data of Participants"
            }
          />
          <Uploader handleUpload={handleUpload} />
        </>
      )}

      {/* MODAL FOOTER */}
      <div className="flex justify-end gap-4 mt-6">
        <DownloadSampleExcel />
        <Button
          onClick={() => {
            setData([]);
            setColumns([]);
            setIsModalOpen(false);
          }}
        >
          Cancel
        </Button>
        <Tooltip>
          <Button
            disabled={data.length === 0 || error}
            loading={addBatchLoading}
            type="primary"
            onClick={() => handleAddBatch(data)}
          >
            Confirm
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};
