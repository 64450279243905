import { Button, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React from "react";

export const ImageUpload = ({ image, setImage, buttonName }) => {
  const handleChange = (info) => {
    setImage(info.file.originFileObj);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div>Profile Picture</div>
      <div style={{ marginTop: 8 }}>{buttonName}</div>
    </div>
  );

  const getImageUrl = (image) => {
    if (typeof image === "string") {
      return image;
    } else {
      return URL.createObjectURL(image);
    }
  };

  return (
    <div>
      <Upload
        name="avatar"
        listType="picture-circle"
        className="avatar-uploader relative profile-upload pb-8"
        action={null}
        showUploadList={false}
        onChange={handleChange}
      >
        <>
          {image ? (
            <img
              className=""
              src={getImageUrl(image)}
              alt="avatar"
              style={{ width: "100%" }}
            />
          ) : (
            uploadButton
          )}
          <Button className="absolute -bottom-[0rem] mt-4">
            {image ? "Click to edit" : "Click to upload"}
          </Button>
        </>
      </Upload>
    </div>
  );
};
