import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getFaculties = createAsyncThunk(
  "manageFaculties/getFaculties",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/faculty/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addFaculty = createAsyncThunk(
  "manageFaculties/addFaculty",
  async ({ facultyDetails, getFaculties }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/add-faculty/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: facultyDetails,
      });
      if (response.status === 200) {
        dispatch(getFaculties());
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateFaculty = createAsyncThunk(
  "manageFaculties/updateFaculty",
  async (
    { facultyDetails, id, getFaculties },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/update-faculty/${id}/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: facultyDetails,
      });
      if (response.status === 200) {
        dispatch(getFaculties());
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
