import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Tag,
} from "antd";
import { PageContent } from "../page-content/PageContent";
import {
  DURATION_VALUES,
  SESSION_DURATION_OPTIONS,
  SESSION_TYPE,
  SESSION_TYPE_SCHEDULAR_VALUE,
} from "../../utils/constants";
import { usePostApi } from "../hooks/usePostApi";
import { useParams } from "react-router-dom";

function keyBy(array, key) {
  return array.reduce((result, item) => {
    result[item[key]] = item;
    return result;
  }, {});
}

function getFormattedProjectStructure(projectStructure) {
  let liveSessionCount = 0;
  let coachingSessionCount = 0;
  let sessionNumber;

  return projectStructure.map((item) => {
    if (item.session_type === "live_session") {
      liveSessionCount += 1;
      sessionNumber = liveSessionCount;
    } else if (item.session_type === "laser_coaching_session") {
      coachingSessionCount += 1;
      sessionNumber = coachingSessionCount;
    } else {
      sessionNumber = null;
    }
    return {
      sessionName: `${SESSION_TYPE_SCHEDULAR_VALUE[item.session_type]}  ${
        sessionNumber || ""
      } `,
      duration: DURATION_VALUES[item.duration],
    };
  });
}

export const ProjectStructure = ({ project, getProject }) => {
  const [data, setData] = useState([
    {
      key: "index",
      sessionType: "",
      duration: "",
      sessionDate: null,
    },
  ]);
  const [editProjectStructure, setEditProjectStructure] = useState(false);
  const { id } = useParams();
  const [form] = Form.useForm(); // Create a form instance

  const handleSessionTypeChange = (value, key) => {
    // Assuming you have access to the dataSource and form
    const sessions = form.getFieldValue("sessions");
    console.log();
    const dataToUpdate = { ...sessions };
    if (dataToUpdate[key] && value === "laser_coaching_session") {
      dataToUpdate[key] = {
        ...dataToUpdate[key],
        duration: "30",
        sessionType: value,
      };
    } else {
      dataToUpdate[key] = { ...dataToUpdate[key], sessionType: value };
    }

    form.setFieldsValue({ sessions: dataToUpdate });
  };

  const handleEdit = () => {
    const projectStructureData = project.project_structure.map(
      (session, index) => ({
        key: String(index),
        sessionType: session.session_type,
        duration: session.duration.toString(),
      })
    );

    // Set form values and update state
    form.setFieldsValue({ sessions: keyBy(projectStructureData, "key") });
    setData(projectStructureData);
    setEditProjectStructure(true);
  };

  const {
    data: dataProjectStructure,
    isLoading: isLoadingProjectStructure,
    // error: errorProjectStructure,
    // resetState: resetStateProjectStructure,
    postData: postDataProjectStructure,
  } = usePostApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/create_project_structure/${id}/`
  );

  const {
    data: finalizeProjectStructureData,
    isLoading: finalizeProjectStructureLoading,
    // error: errorProjectStructure,
    // resetState: resetStateProjectStructure,
    postData: finalizeProjectStructure,
  } = usePostApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/finalize-project-structure/${id}/`
  );

  const structureColumns = [
    {
      title: "Session Type",
      dataIndex: "sessionType",
      key: "sessionType",
      render: (text, record) => (
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please select session.",
            },
          ]}
          name={["sessions", record.key, "sessionType"]}
        >
          <Select
            onChange={(value) => {
              console.log({
                value,
                formValues: form.getFieldsValue(),
                key: record.key,
              });

              handleSessionTypeChange(value, record.key);
            }}
            options={SESSION_TYPE}
            placeholder="Select session type"
          ></Select>
        </Form.Item>
      ),
    },
    {
      title: "Duration (Hours)",
      dataIndex: "duration",
      key: "duration",
      render: (text, record) => (
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please select duration.",
            },
          ]}
          name={["sessions", record.key, "duration"]}
        >
          <Select
            options={SESSION_DURATION_OPTIONS}
            placeholder="Select duration"
          ></Select>
        </Form.Item>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (_, record) => (
        <Button onClick={() => handleDelete(record.key)}>Delete</Button>
      ),
    },
  ];

  const handleAddSession = () => {
    const uniqueKey = Date.now().toString();
    console.log({ data });
    const newData = [
      ...data,
      { key: uniqueKey, sessionType: "", duration: "" },
    ];
    setData(newData);
  };

  const handleDelete = (key) => {
    const newData = data.filter((item) => item.key !== key);
    setData(newData);
  };

  const onFinish = async (values) => {
    // Prepare the data to be sent to the backend
    const requestData = Object.values(values.sessions).map(
      (session, index) => ({
        session_type: session.sessionType,
        duration: session.duration,
        order: index + 1,
      })
    );

    try {
      // Send the data to the API for creating the project structure
      const response = await postDataProjectStructure(requestData);

      if (response.status === 201) {
        // Optionally, reset the form after a successful submission
        form.resetFields();
        // You can also handle any success notifications or redirects here.
      } else {
        console.error("Request failed with status code", response.status);
        // Handle errors or display error messages to the user.
      }
    } catch (error) {
      console.error("Request failed:", error);
      // Handle exceptions or display error messages to the user.
    }
  };

  const columns = [
    {
      title: "Session Type",
      key: "sessionName",
      dataIndex: "sessionName",
    },
    {
      title: "Duration",
      key: "duration",
      dataIndex: "duration",
    },
  ];

  const showForm = useMemo(() => {
    if (project) {
      return (
        project.project_structure.length === 0 ||
        (project.project_structure.length > 0 && editProjectStructure)
      );
    }
    return false;
  }, [project, editProjectStructure]);

  useEffect(() => {
    if (dataProjectStructure) {
      getProject();
    }
  }, [dataProjectStructure, getProject]);

  useEffect(() => {
    if (finalizeProjectStructureData) {
      getProject();
    }
  }, [finalizeProjectStructureData, getProject]);

  return (
    <PageContent>
      {showForm ? (
        <Form form={form} name="projectStructureForm" onFinish={onFinish}>
          <Table
            dataSource={data}
            columns={structureColumns}
            pagination={false}
          />
          <div className="flex mt-[.5rem] gap-2">
            <Button onClick={handleAddSession}>Add Session</Button>
            <Button
              loading={isLoadingProjectStructure}
              disabled={data.length === 0}
              className="ml-auto"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      ) : (
        <>
          <div className="mb-4">
            Status:{" "}
            <Tag color={project.is_project_structure_finalized ? "green" : ""}>
              {project.is_project_structure_finalized ? "Finalized" : "Pending"}
            </Tag>
          </div>
          <Table
            columns={columns}
            dataSource={getFormattedProjectStructure(project.project_structure)}
          ></Table>
          {!project.is_project_structure_finalized && (
            <div className="flex justify-end gap-4 mt-6">
              <Button
                loading={finalizeProjectStructureLoading}
                onClick={handleEdit}
              >
                Edit{" "}
              </Button>
              <Button
                loading={finalizeProjectStructureLoading}
                onClick={() => finalizeProjectStructure()}
                type="primary"
              >
                Finalize project structure{" "}
              </Button>
            </div>
          )}
        </>
      )}
    </PageContent>
  );
};
