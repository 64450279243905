import {
  selectHMSMessages,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { Button, Input, Tooltip } from "antd";
import { IoIosSend } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import React, { useRef, useState } from "react";
import { useEffect } from "react";

const Message = ({ message, reference }) => (
  <div
    className={message.senderName === "You" ? "ml-auto" : "mr-auto"}
    ref={reference}
  >
    {message.message !== "TIME_EXTENDED" &&
      message.message !== "SESSION_GOING_TO_CLOSED" && (
        <p
          className={`px-[15px] py-1 my-[4px]  ${
            message.senderName === "You"
              ? "ml-auto bg-[#94258E] text-white rounded-[50px]"
              : "mr-auto rounded-[50px] border-2 border-solid border-[#bdb7b7]"
          }`}
        >
          {message.message}
        </p>
      )}
  </div>
);

export const Chat = ({ setIsChatActive }) => {
  const [message, setMessage] = useState("");
  const allMessages = useHMSStore(selectHMSMessages); // get all messages
  const hmsActions = useHMSActions();
  const scrollRef = useRef("null");
  const sendMessage = () => {
    hmsActions.sendBroadcastMessage(message);
    setMessage("");
  };

  useEffect(() => {
    allMessages.length > 3 &&
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [allMessages]);

  return (
    <div
      className={`min-w-[18.75rem] md:w-[20rem] max-h-[calc(100vh-10rem)] m-4 rounded-xl gap-2 flex flex-col border-2 border-gray-300  pl-2 z-[1] md:static bg-[#fff] w-full `}
    >
      <div className="flex justify-between space-between border-b p-2">
        <p>In call messages</p>
        <Tooltip title={"Close"}>
          <Button
            className="inline-flex items-center justify-center"
            type="secondary"
            shape="circle"
            onClick={() => setIsChatActive((prev) => !prev)}
            icon={<AiOutlineClose />}
          />
        </Tooltip>
      </div>
      <div className="flex overflow-y-scroll flex-col p-[5px]">
        {allMessages.map((message, index) => {
          return (
            <Message
              key={message.id}
              message={message}
              reference={index === allMessages.length - 1 ? scrollRef : null}
            />
          );
        })}
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          sendMessage();
        }}
        className="py-2 flex mt-auto "
      >
        <Input
          className="inline-block"
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></Input>
        <Button
          htmlType="submit"
          className="inline-flex items-center justify-center"
          type="secondary"
          shape="circle"
          size="large"
          icon={<IoIosSend />}
        />
      </form>
    </div>
  );
};
