import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Modal, Tooltip, Alert } from "antd";
import axios from "axios";
import {
  FileAddOutlined,
  DeleteOutlined,
  EditOutlined,
  SendOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { useGetApi } from "../hooks/useGetApi";
import { Header } from "../header/Header";

const Templates1 = () => {
  const navigate = useNavigate();
  const [showButtons, setShowButtons] = useState({
    card1: false,
    card2: false,
  });
  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const {
    data: pendingScheduledMails,
    isLoading: pendingScheduledMailsLoading,
  } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/pending-scheduled-mails/${isModalOpenDelete?.id}/`
  );
  const showModalDelete = (template_id) => {
    setIsModalOpenDelete({
      id: template_id,
    });
  };
  const handleOkDelete = () => {
    handleDeleteTemplate(isModalOpenDelete.id);
  };
  const handleCancelDelete = () => {
    setIsModalOpenDelete(false);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/schedular/saved_emailsTemplates/`)
      .then((response) => {
        const data = response.data.data;
        setTemplateList(data);
      })

      .catch((error) => {
        console.error("Error fetching templates:", error);
      });
  }, []);

  const handleEditClick = (template) => {
    navigate("/templates1", {
      state: {
        templatePart: template.template_data,
        templateId: template.id,
        title: template.title,
      },
    });
  };

  const handleAddNewClick = () => {
    navigate("/templates1");
  };

  const handleDeleteTemplate = async (templateId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/schedular/delete_email_template/${templateId}/`
      );
      setTemplateList((prevTemplates) =>
        prevTemplates.filter((template) => template.id !== templateId)
      );
      setIsModalOpenDelete(false);
    } catch (error) {
      console.error("Error deleting template:", error);
    }
  };

  const handleClickSend = (template) => {
    navigate("/preview", {
      state: { ...template },
    });
  };

  const handleMouseEnter = (cardNumber) => {
    setShowButtons((prevState) => ({ ...prevState, [cardNumber]: true }));
  };

  const handleMouseLeave = (cardNumber) => {
    setShowButtons((prevState) => ({ ...prevState, [cardNumber]: false }));
  };
  const openTemplateModal = (template) => {
    setSelectedTemplate(template);
  };

  const closeModal = () => {
    setSelectedTemplate(null);
  };

  return (
    <div>
      <Header>Email Templates</Header>
      <main className="xl:col-start-2 pt-4 xl:col-end-7 w-[98%] mx-auto flex-container flex-column">
        {templateList && templateList.length > 0 ? (
          <div className="flex">
            <div className="flex flex-wrap gap-2">
              <div
                onClick={handleAddNewClick}
                className="cursor-pointer justify-center border p-5 w-[21rem] h-[10rem] bg-gray-100"
              >
                <div className="text-center">
                  <div>
                    <FileAddOutlined className="!text-[50px]" />
                  </div>
                  <div className="font-semibold text-xl mt-[1rem]">
                    Create Email Template
                  </div>
                </div>
              </div>
              {templateList.map((template, index) => (
                <div key={template?.id} className="">
                  <Card
                    className="w-[21rem] h-[10rem] border-gray-300 hover:bg-gray-100"
                    bordered={true}
                    onMouseEnter={() => handleMouseEnter(`card${index}`)}
                    onMouseLeave={() => handleMouseLeave(`card${index}`)}
                  >
                    <h3 className="font-semibold text-2xl text-center truncate w-[15rem] cursor-pointer">
                      <Tooltip title={template.title}>{template.title}</Tooltip>
                    </h3>
                    {showButtons[`card${index}`] && (
                      <div className="flex gap-2 mt-[3rem] ml-[1rem]">
                        <Tooltip title="Edit">
                          <Button
                            type="text"
                            className="rounded-md"
                            onClick={() => handleEditClick(template)}
                          >
                            <EditOutlined className="!text-[24px]" />{" "}
                          </Button>
                        </Tooltip>
                        <Tooltip title="Send">
                          <Button
                            type="text"
                            className="rounded-md"
                            onClick={() => handleClickSend(template)}
                          >
                            <SendOutlined className="!text-[24px]" />{" "}
                          </Button>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <Button
                            type="text"
                            className={"rounded-md"}
                            onClick={() => showModalDelete(template.id)}
                          >
                            <DeleteOutlined className="!text-[24px]" />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Preview">
                          <Button
                            type="text"
                            className="rounded-md"
                            onClick={() => openTemplateModal(template)}
                          >
                            <EyeOutlined className="!text-[24px]" />{" "}
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                  </Card>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="text-center mt-[8rem]">
            <div onClick={handleAddNewClick} className="cursor-pointer">
              <div>
                <FileAddOutlined className="!text-[150px]" />
              </div>
              <div className="font-semibold text-3xl mt-[2rem]">
                Create Email Template
              </div>
            </div>
          </div>
        )}
        <Modal
          title="Confirm"
          footer={false}
          open={isModalOpenDelete}
          onOk={handleOkDelete}
          onCancel={handleCancelDelete}
        >
          {pendingScheduledMails?.exists && (
            <Alert
              className="mb-4"
              type="error"
              message="This template can't be deleted, scheduled emails already exists."
            ></Alert>
          )}
          <div className="flex">
            <div>Are you sure you want to delete this template?</div>
            <div className="flex gap-2 mt-[3rem]">
              <Button type="primary" onClick={handleCancelDelete}>
                Cancel
              </Button>
              <Tooltip
                title={
                  pendingScheduledMails?.exists
                    ? "Scheduled mails already exists"
                    : ""
                }
              >
                <Button
                  loading={pendingScheduledMailsLoading}
                  disabled={pendingScheduledMails?.exists}
                  type="primary"
                  onClick={handleOkDelete}
                >
                  Delete
                </Button>
              </Tooltip>
            </div>
          </div>
        </Modal>

        <Modal
          open={selectedTemplate}
          onCancel={closeModal}
          footer={null}
          destroyOnClose={true}
          width={800}
        >
          <div className="p-4">
            <div className="p-2">
              <div
                style={{ maxHeight: "100%", overflow: "auto" }}
                dangerouslySetInnerHTML={{
                  __html: selectedTemplate?.template_data,
                }}
              />
            </div>
          </div>
        </Modal>
      </main>
    </div>
  );
};

export { Templates1 };
