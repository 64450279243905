import React from "react";
import { SmileOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

export const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleButtonClick = () => {
    navigate("/templates");
  };

  const isScheduleMail = location.state?.isScheduleMail || false;

  const success = isScheduleMail ? (
    <div>Email Scheduled Successfully</div>
  ) : (
    <div>Email Sent Successfully</div>
  );

  return (
    <main className="xl:col-start-2 pt-4 xl:col-end-7 w-[98%] mx-auto">
      <div>
        {" "}
        <Result
          status="success"
          //   icon={<SmileOutlined />}

          title={success}
          extra={
            <Button onClick={handleButtonClick} type="primary">
              Back
            </Button>
          }
        />
      </div>
    </main>
  );
};
