import React from "react";
import { PageContent } from "../../../components/page-content/PageContent";
import { useGetApi } from "../../../hooks/useGetApi";
import { useSelector } from "react-redux";
import { Button, Collapse } from "antd";
import { useMemo } from "react";
import { LoadingAndError } from "../../../components/loading-and-error/LoadingAndError";
import moment from "moment";
import { formatSlotsDateWise } from "../../../utils/formatSlotsDateWise";

export const ActiveRequest = ({ requests }) => {
  return (
    <>
      {requests?.map((item, index) => (
        <Collapse
          key={item.id}
          className="mb-4"
          size="large"
          items={[
            {
              key: "1",
              label: (
                <div className="flex gap-10">
                  <div className="capitalize">{item.request_name}</div>
                  <div className="ml-auto">
                    <div className="text-xs">Expiry Date:</div>
                    <div>
                      {moment(new Date(item.expiry_date)).format("DD-MM-YYYY")}
                    </div>
                  </div>
                </div>
              ),
              children: <RequestContent request={item} />,
            },
          ]}
        />
      ))}
    </>
  );
};

function RequestContent({ request }) {
  const { user } = useSelector((state) => state.auth);
  const {
    data: slots,
    isLoading: slotsLoading,
    error: slotsError,
  } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/request/${request?.id}/slots/?coach_id=${user.id}`
  );

  const formattedSlots = useMemo(() => {
    if (slots) {
      return formatSlotsDateWise(slots);
    } else return {};
  }, [slots]);

  return (
    <LoadingAndError
      error={slotsError}
      data={Boolean(slots)}
      loading={slotsLoading}
    >
      {slots && !slotsLoading && (
        <>
          {Object.keys(formattedSlots).map((date) => {
            return (
              <div className="mb-8">
                {date}
                <div className="flex flex-wrap">
                  {formattedSlots[date].map((slot) => {
                    return (
                      <div
                        key={slot.id}
                        className={`mt-[1rem] border rounded-2xl p-1 text-center w-[12rem]  cursor-pointer mx-1 bg-primary-4`}
                      >
                        {moment(new Date(+slot.start_time)).format("hh:mm A")} -
                        {moment(new Date(+slot.end_time)).format("hh:mm A")}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </>
      )}
    </LoadingAndError>
  );
}
