import { Menu, Tooltip } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";

import { useSelector } from "react-redux";
import {
  AccessTime,
  FactCheckOutlined,
  GroupOutlined,
  PersonOutlineOutlined,
  VideoCameraFrontOutlined,
} from "@mui/icons-material";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";

const keysInitials = [
  "profile",
  "projects",
  "sessions",
  "coachee/list",
  "help",
  "coach/dashboard",
  "slot-request",
];

const insideProject = [`projects/caas/progress/`];

const insideCoachee = [`coachee/`];

const CoachNavigation = () => {
  function getItem(label, key, icon, children, tooltip, disabled) {
    const link = disabled ? (
      <div className={disabled ? "cursor-not-allowed" : ""}>
        <Tooltip title="Admin Approval Required">{label}</Tooltip>
      </div>
    ) : (
      <NavLink to={`/${key}`}>
        <Tooltip title={tooltip}>{label}</Tooltip>
      </NavLink>
    );

    return {
      key,
      icon,
      disabled,
      children,
      label: link,
    };
  }
  const [selectedKey, setSelectedKey] = useState("coach");
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.auth);

  const userType = useMemo(() => {
    return user.user.type;
  }, [user]);

  const isApproved = user.is_approved;

  const items = [
    // getItem("Book", "book", <CalendarMonthIcon className="!text-[24px]" />),
    // getItem("Upcoming Sessions", "upcoming-session", <FieldTimeOutlined />),
    // getItem("Completed Sessions", "completed-session", <FieldTimeOutlined />),
    // getItem(
    //   "Dashboard",
    //   "coach/dashboard",
    //   <DashboardOutlinedIcon className="!text-[24px]" />,
    //   null,
    //   null,
    //   !isApproved
    // ),
    // getItem(
    //   "Profile",
    //   "profile",
    //   <PersonOutlineOutlined className="!text-[24px]" />,
    //   null,
    //   "Hello",
    //   false
    // ),
    // getItem(
    //   "Projects",
    //   "projects",
    //   <FactCheckOutlined className="!text-[24px]" />,
    //   null,
    //   null,
    //   !isApproved
    // ),
    // getItem(
    //   "Sessions",
    //   "sessions",
    //   <VideoCameraFrontOutlined className="!text-[24px]" />,
    //   null,
    //   null,
    //   !isApproved
    // ),
    // getItem(
    //   "Coachee",
    //   "coachee/list",
    //   <GroupOutlined className="!text-[24px]" />,
    //   null,
    //   null,
    //   !isApproved
    // ),
    getItem(
      "Slot Requests",
      "slot-request",
      <HelpCenterOutlinedIcon className="!text-[24px]" />,
      null,
      null,
      !isApproved
    ),
    getItem(
      "Sessions",
      "sessions",
      <VideoCameraFrontOutlined className="!text-[24px]" />,
      null,
      null,
      !isApproved
    ),
    getItem(
      "Your Slots",
      "slots",
      <AccessTime className="!text-[24px]" />,
      null,
      null,
      !isApproved
    ),
  ];

  useEffect(() => {
    let pathExists = false;
    keysInitials.forEach((key) => {
      if (pathname === `/${key}`) {
        setSelectedKey(key);
        pathExists = true;
      }
    });

    insideProject.forEach((key) => {
      if (pathname.startsWith(`/${key}`)) {
        setSelectedKey("projects");
        pathExists = true;
      }
    });

    insideCoachee.forEach((key) => {
      if (pathname.startsWith(`/${key}`)) {
        setSelectedKey("coachee/list");
        pathExists = true;
      }
    });

    if (!pathExists) {
      setSelectedKey(null);
    }
  }, [pathname, isApproved]);

  return (
    <Menu
      style={{
        border: 0,
        background: "inherit",
        color: "inherit",
      }}
      selectedKeys={[selectedKey]}
      onClick={(item) => setSelectedKey(item.key)}
      theme={"light"}
      items={items}
    />
  );
};

export { CoachNavigation };
