import { Modal } from "antd";
import React from "react";
import { ViewProfile } from "./ViewProfile";

export const CoachProfileModal = ({ setShowProfile, showProfile }) => {
  return (
    <Modal
      className="coach-profile-modal"
      width={1000}
      onCancel={() => setShowProfile(null)}
      footer={false}
      open={true}
    >
      <ViewProfile isHr={true} coach={showProfile} />
    </Modal>
  );
};
