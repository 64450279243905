import { createSlice } from "@reduxjs/toolkit";
import {
  deleteCoachConfirmedSlot,
  getBookedSessions,
  getCoachConfirmedSlots,
  updateCoachConfirmedSlot,
} from "./editCoachConfirmedSlotsServices";

const initialState = {
  editCoachConfirmedSlotsStatus: "pending",
  coachConfirmedSlots: [],
  deleteCoachConfirmedSlotError: "idle",
  updateCoachConfirmedSlotError: "idle",
  editCoachConfirmedSlotsError: null,
  fetchingBookedSessionStatus: "pending",
  bookedSessions: [],
};

export const editCoachConfirmedSlots = createSlice({
  name: "editCoachConfirmedSlots",
  initialState,
  reducers: {},
  extraReducers: {
    [getCoachConfirmedSlots.pending]: (state) => {
      state.editCoachConfirmedSlotsStatus = "pending";
    },
    [getCoachConfirmedSlots.fulfilled]: (state, action) => {
      state.editCoachConfirmedSlotsStatus = "fulfilled";
      state.coachConfirmedSlots = action.payload;
    },
    [getCoachConfirmedSlots.rejected]: (state, action) => {
      state.editCoachConfirmedSlotsStatus = "rejected";
      state.editCoachConfirmedSlotsError = action.payload;
    },
    [deleteCoachConfirmedSlot.pending]: (state) => {
      state.deleteCoachConfirmedSlotError = "pending";
    },
    [deleteCoachConfirmedSlot.fulfilled]: (state, action) => {
      state.deleteCoachConfirmedSlotError = "fulfilled";
    },
    [deleteCoachConfirmedSlot.rejected]: (state, action) => {
      state.deleteCoachConfirmedSlotError = "rejected";
      state.editCoachConfirmedSlotsError = action.payload;
    },
    [updateCoachConfirmedSlot.pending]: (state) => {
      state.updateCoachConfirmedSlotError = "pending";
    },
    [updateCoachConfirmedSlot.fulfilled]: (state, action) => {
      state.updateCoachConfirmedSlotError = "fulfilled";
    },
    [updateCoachConfirmedSlot.rejected]: (state, action) => {
      state.updateCoachConfirmedSlotError = "rejected";
      state.editCoachConfirmedSlotsError = action.payload;
    },
    [getBookedSessions.pending]: (state) => {
      state.fetchingBookedSessionStatus = "pending";
    },
    [getBookedSessions.fulfilled]: (state, action) => {
      state.fetchingBookedSessionStatus = "fulfilled";
      state.bookedSessions = action.payload;
    },
    [getBookedSessions.rejected]: (state, action) => {
      state.fetchingBookedSessionStatus = "rejected";
    },
  },
});

export default editCoachConfirmedSlots.reducer;
