import { Avatar, Button, Table } from "antd";
import React from "react";
import { Header } from "../../../components/header/Header";
import { PageContent } from "../../../components/page-content/PageContent";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { useGetApi } from "../../../components/hooks/useGetApi";
import { Navigate, useNavigate } from "react-router";
import { AppstoreOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export const PmoProjects = () => {
  const navigate = useNavigate();
  const {
    data: dataProjects,
    isLoading: isLoadingProjects,
    error: errorProjects,
    getData: getDataProjects,
  } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/schedular-projects/`
  );

  const projectColumn = [
    {
      title: "Projects Name",
      dataIndex: "projectName",
      key: "projectName",
      render: (projectName, project) => (
        <div className="flex gap-2 cursor-pointer items-center">
          <Avatar
            className="flex items-center justify-center"
            src={
              project.organisation.image_url ? (
                <img
                  style={{
                    objectFit: "contain",
                  }}
                  src={project.organisation.image_url}
                  alt="Logo"
                />
              ) : (
                <AppstoreOutlined className="text-black p-[0.5rem]" />
              )
            }
          />
          <div className="flex flex-col">
            <Link
              className="m-hyperlink-cta"
              to={`/projects/progress/${project.id}`}
            >
              {projectName}
            </Link>
          </div>
        </div>
      ),
    },

    {
      title: "Organisation",
      dataIndex: "organizationName",
      key: "organizationName",
    },
    {
      title: "HR Email",
      dataIndex: "hrEmail",
      key: "hrEmail",
    },
  ];

  const dataSource = dataProjects?.map((project) => {
    return {
      id: project.id,
      projectName: project.name,
      organizationName: project.organisation.name,
      hrEmail: project?.hr?.map((hr) => hr?.email),
      ...project,
    };
  });

  return (
    <div>
      <Header>Projects</Header>
      <PageContent>
        <div className="flex flex-col">
          <div className="ml-auto mb-[.5rem]">
            <Button onClick={() => navigate(`/pmo/project-create`)}>
              <AddCircleOutlineOutlined />
              Add Project
            </Button>
          </div>
          <Table columns={projectColumn} dataSource={dataSource} />
        </div>
      </PageContent>
    </div>
  );
};
