import { createSlice } from "@reduxjs/toolkit";
import {
  addEvent,
  deleteEvent,
  editEvent,
  getAvailableSlotsByEventId,
  getConfirmedSlotsByEventId,
  getEventsList,
} from "./manageEventsService";

const initialState = {
  manageEventsStatus: "idle",
  addEventStatus: "idle",
  editEventStatus: "idle",
  deleteEventStatus: "idle",
  confirmedSlotsByEventIdStatus: "idle",
  availableSlotsByEventIdStatus: "idle",
  confirmedSlotsByEventId: [],
  availableSlotsByEventId: [],
  eventsList: [],
  manageEventsError: null,
};

export const manageFaculties = createSlice({
  name: "manageFaculties",
  initialState,
  reducers: {},
  extraReducers: {
    [addEvent.pending]: (state) => {
      state.addEventStatus = "pending";
    },
    [addEvent.fulfilled]: (state) => {
      state.addEventStatus = "fulfilled";
    },
    [addEvent.rejected]: (state, action) => {
      state.addEventStatus = "rejected";
      state.manageEventsError = action.payload;
    },
    [getEventsList.pending]: (state) => {
      state.manageEventsStatus = "pending";
    },
    [getEventsList.fulfilled]: (state, action) => {
      state.manageEventsStatus = "fulfilled";
      state.eventsList = action.payload;
    },
    [getEventsList.rejected]: (state, action) => {
      state.manageEventsStatus = "rejected";
      state.manageEventsError = action.payload;
    },
    [editEvent.pending]: (state) => {
      state.editEventStatus = "pending";
    },
    [editEvent.fulfilled]: (state, action) => {
      state.editEventStatus = "fulfilled";
    },
    [editEvent.rejected]: (state, action) => {
      state.editEventStatus = "rejected";
      state.manageEventsError = action.payload;
    },
    [deleteEvent.pending]: (state) => {
      state.deleteEventStatus = "pending";
    },
    [deleteEvent.fulfilled]: (state, action) => {
      state.deleteEventStatus = "fulfilled";
    },
    [deleteEvent.rejected]: (state, action) => {
      state.deleteEventStatus = "rejected";
      state.manageEventsError = action.payload;
    },
    [getConfirmedSlotsByEventId.pending]: (state) => {
      state.confirmedSlotsByEventIdStatus = "pending";
    },
    [getConfirmedSlotsByEventId.fulfilled]: (state, action) => {
      state.confirmedSlotsByEventIdStatus = "fulfilled";
      state.confirmedSlotsByEventId = action.payload;
    },
    [getConfirmedSlotsByEventId.rejected]: (state, action) => {
      state.confirmedSlotsByEventIdStatus = "rejected";
      state.manageEventsError = action.payload;
    },
    [getAvailableSlotsByEventId.pending]: (state) => {
      state.availableSlotsByEventIdStatus = "pending";
    },
    [getAvailableSlotsByEventId.fulfilled]: (state, action) => {
      state.availableSlotsByEventIdStatus = "fulfilled";
      state.availableSlotsByEventId = action.payload;
    },
    [getAvailableSlotsByEventId.rejected]: (state, action) => {
      state.availableSlotsByEventIdStatus = "rejected";
      state.manageEventsError = action.payload;
    },
  },
});

export default manageFaculties.reducer;
