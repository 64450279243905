import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Modal,
  Select,
  Tooltip,
  notification as antDesignNotify,
} from "antd";
import {
  MdChatBubbleOutline,
  MdMic,
  MdMicOff,
  MdOutlineFullscreen,
  MdOutlineVideocam,
  MdOutlineVideocamOff,
} from "react-icons/md";
import { IoIosCall } from "react-icons/io";
import {
  useHMSStore,
  selectIsLocalAudioEnabled,
  selectIsLocalVideoEnabled,
  useHMSActions,
  useHMSNotifications,
} from "@100mslive/react-sdk";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export const AudioAndVideoControllers = () => {
  const hmsActions = useHMSActions();
  const videoEnabled = useHMSStore(selectIsLocalVideoEnabled);
  const audioEnabled = useHMSStore(selectIsLocalAudioEnabled);

  const toggleAudio = () => {
    if (audioEnabled) {
      hmsActions.setLocalAudioEnabled(false);
    } else {
      const constraints = {
        audio: true,
        video: true,
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((mediaStream) => {
          hmsActions.setLocalAudioEnabled(true);
        })
        .catch(() => {
          // always check for errors at the end.
          antDesignNotify.error({
            message: "Please check your browser permissions or mic settings",
          });
        });
    }
  };

  const toggleVideo = () => {
    if (videoEnabled) {
      hmsActions.setLocalVideoEnabled(false);
    } else {
      const constraints = {
        audio: true,
        video: true,
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((mediaStream) => {
          hmsActions.setLocalVideoEnabled(true);
        })
        .catch(() => {
          // always check for errors at the end.
          antDesignNotify.error({
            message: "Please check your browser permissions or camera settings",
          });
        });
    }
    hmsActions.setLocalVideoEnabled(!videoEnabled);
  };

  return (
    <div className="flex justify-center gap-4">
      <Tooltip title={audioEnabled ? "Mute" : "Unmute"}>
        <Button
          onClick={toggleAudio}
          className="inline-flex items-center justify-center"
          type="secondary"
          shape="circle"
          size="large"
          icon={audioEnabled ? <MdMic /> : <MdMicOff />}
        />
      </Tooltip>

      <Tooltip title={videoEnabled ? "Turn off camera" : "Turn on camera"}>
        <Button
          onClick={toggleVideo}
          className="inline-flex items-center justify-center"
          type="secondary"
          shape="circle"
          size="large"
          icon={videoEnabled ? <MdOutlineVideocam /> : <MdOutlineVideocamOff />}
        />
      </Tooltip>
    </div>
  );
};

export const Controllers = ({ setIsChatActive, setRoomEnded }) => {
  const [newMessages, setNewMessages] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const hmsActions = useHMSActions();

  const notification = useHMSNotifications();
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.auth);

  const confirmStatus = () => {
    hmsActions.leave();
    setRoomEnded(true);
    navigate("/confirmed-sessions");
  };

  let startTimeStamp;
  if (isLoggedIn) {
    const date = location.state.date.split("-").join("/");
    startTimeStamp = new Date(`${date} ${location.state.time}`).getTime();
  }

  function toggleFullScreen() {
    const doc = window.document;
    const docEl = doc.documentElement;

    const requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    const cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;
    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  }

  useEffect(() => {
    if (!notification) {
      return;
    }
    switch (notification.type) {
      case "NEW_MESSAGE":
        if (
          notification.data.message === "TIME_EXTENDED" ||
          notification.data.message === "SESSION_GOING_TO_CLOSED"
        ) {
          break;
        } else {
          setNewMessages((prev) => prev + 1);
          break;
        }
      default:
        break;
    }
  }, [notification]);

  return (
    <div className="h-fit flex justify-center gap-4 p-2 border-t-2 border-gray-300 ">
      <AudioAndVideoControllers />
      <Tooltip title={"Full screen"}>
        <Button
          onClick={() => {
            toggleFullScreen();
          }}
          className="inline-flex items-center justify-center"
          type="secondary"
          shape="circle"
          size="large"
          icon={<MdOutlineFullscreen />}
        />
      </Tooltip>

      <Badge count={newMessages} size="large">
        <Tooltip title="Chat">
          <Button
            onClick={() => {
              setNewMessages(0);
              setIsChatActive((prev) => !prev);
            }}
            className="inline-flex items-center justify-center"
            type="secondary"
            shape="circle"
            size="large"
            icon={<MdChatBubbleOutline />}
          />
        </Tooltip>
      </Badge>
      <Button
        danger
        className="inline-flex items-center bg-[#fa352a] text-[#fff] justify-center"
        type="secondary"
        shape="circle"
        size="large"
        title="Leave Room"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <IoIosCall />
      </Button>
      <Modal
        className="rounded-lg"
        footer={false}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setSelectedStatus();
        }}
      >
        {isLoggedIn ? (
          <>
            {new Date().getTime() > startTimeStamp ? (
              <div className="flex py-4 flex-col gap-2 items-center">
                <p className="">Please select status before you end call.</p>
                <Select
                  className=" text-center"
                  placeholder="Update Coaching Session Status"
                  value={selectedStatus}
                  onChange={(value) => setSelectedStatus(value)}
                  options={[
                    {
                      value: "Session completed",
                      label: "Session completed",
                    },
                    {
                      value: "Coachee no show",
                      label: "Coachee no show",
                    },
                  ]}
                ></Select>
                <div className="flex w-full justify-center gap-1 mt-4">
                  <Button
                    className="bg-red-400 text-white"
                    disabled={selectedStatus ? false : true}
                    type="danger"
                    onClick={() => confirmStatus()}
                  >
                    End Call
                  </Button>
                  <Button
                    type="secondary"
                    onClick={() => {
                      setIsModalOpen(false);
                      setSelectedStatus();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <p>Your session will start at {location.state.time}..</p>
                <p> Please wait for the coachee to join the session... </p>
              </>
            )}
          </>
        ) : (
          <div className="">
            <p className="mb-4">Are you sure you want to end the session?</p>
            <div className="flex gap-2 ">
              <Button
                onClick={() => {
                  hmsActions.leave();
                  setRoomEnded(true);
                }}
                className="text-red-500"
                type="danger"
              >
                Yes
              </Button>
              <Button
                type="secondary"
                onClick={() => setIsModalOpen(false)}
                className=""
              >
                No
              </Button>
            </div>
          </div>
        )}
      </Modal>

      {/* <VirtualBackground /> */}
    </div>
  );
};
