export const arrayToOptions = (arr) => {
  let res = [];
  arr?.forEach((item) => {
    if (item) {
      res?.push({
        label: item,
        value: item,
        text: item,
      });
    }
  });

  return res;
};
