import {
  selectLocalPeer,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { Button, Form, Input, Spin } from "antd";
import { useEffect } from "react";
import { useCallback } from "react";
import { AudioAndVideoControllers } from "./Controllers";
import { Peer } from "./Peer";
// import { CustomAlert } from "../custom-alert/CustomAlert";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import { useGetApi } from "../../hooks/useGetApi";
import { CustomAlert } from "../../components/custom-alert/CustomAlert";
import { usePostApi } from "../../hooks/usePostApi";

function MeetingJoinForm({ stackeholderName }) {
  const hmsActions = useHMSActions();
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);
  const localPeer = useHMSStore(selectLocalPeer);
  const { user } = useSelector((state) => state.auth);
  // const [userName, setUserName] = useState(null);
  const userName = useMemo(() => {
    return stackeholderName
      ? stackeholderName
      : user?.name
      ? user.name
      : user?.first_name;
  }, [stackeholderName, user]);

  const { roomId } = useParams();

  const getToken = useCallback(
    async (user_id) => {
      const response = await fetch(process.env.REACT_APP_GENERATE_TOKEN_API, {
        method: "POST",
        body: JSON.stringify({
          user_id,
          role: "host",
          type: "app",
          room_id: roomId,
        }),
      });
      const { token } = await response.json();
      return token;
    },
    [roomId]
  );

  const joinSession = async (token, userName, email) => {
    try {
      await hmsActions.join({
        userName: userName,
        authToken: token,
        metaData: {
          email: email,
        },
      });
    } catch (err) {
      setError("Error joining session.");
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const token = await getToken(userName);
        setError(null);
        setToken(token);
        await hmsActions.preview({
          userName: userName,
          authToken: token,
          metaData: {
            email: user?.email,
          },
        });
      } catch (err) {
        setError("Error joining session.");
        setToken(null);
      }
    })();
  }, [getToken, user, hmsActions, userName]);

  if (error) {
    return <p className="p-2">{error}</p>;
  } else {
    return (
      <div className="flex flex-col gap-2 items-center h-[calc(100vh-3.56rem)] justify-center">
        {localPeer ? (
          <>
            {user && user?.user?.type === "coach" ? (
              <JoinForAuthenticatedUsers
                joinSession={joinSession}
                token={token}
              />
            ) : (
              <JoinForUnauthenticatedUsers
                joinSession={joinSession}
                token={token}
              />
            )}
          </>
        ) : (
          <div className="py-4">
            <Spin />
          </div>
        )}
      </div>
    );
  }
}

export { MeetingJoinForm };

function JoinForAuthenticatedUsers({ token, joinSession }) {
  const localPeer = useHMSStore(selectLocalPeer);
  const { user } = useSelector((state) => state.auth);
  const { id: roomId } = useParams();
  const { data, isLoading, error } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/current-session/coach/${roomId}/${user.id}/`
  );

  return (
    <div className="md:grid md:grid-cols-3 p-4 gap-16 min-w-[18rem] w-full max-w-[70rem]">
      <div className="col-span-2 flex flex-col items-center justify-center">
        <Peer preview={true} peer={localPeer} />
        <AudioAndVideoControllers />
      </div>
      <div className="my-auto pb-4">
        {isLoading ? (
          <Spin />
        ) : error ? (
          <>
            <div className="flex flex-col">
              <CustomAlert
                className="bg-error-bg text-error border-error"
                message={"No session found!"}
                icon={<ErrorOutlineOutlined />}
              />
              <span className="font-semibold text-lg">Ready to join?</span>
              <Button className="my-4" type="primary" disabled={true}>
                Join now
              </Button>
            </div>
          </>
        ) : data ? (
          <>
            {/* when session is available */}
            <div className="flex flex-col">
              <span className="font-semibold text-lg">Ready to join?</span>
              <Button
                className="my-4"
                type="primary"
                disabled={!token}
                onClick={() =>
                  joinSession(token, user.name || user.first_name, user?.email)
                }
              >
                Join now
              </Button>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

function JoinForUnauthenticatedUsers({ joinSession, token }) {
  const localPeer = useHMSStore(selectLocalPeer);
  const { roomId } = useParams();
  const { data, isLoading, postData } = usePostApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/learner-current-session/${roomId}/`
  );

  const onFinish = (values) => {
    postData({ email: values.email });
  };

  useEffect(() => {
    if (data) {
      joinSession(token, data?.name, data.email);
    }
  }, [data, joinSession, token]);

  return (
    <div className="grid md:grid-cols-3 p-4 gap-16 min-w-[18rem] w-full max-w-[70rem]">
      <div className="col-span-2 flex flex-col items-center justify-center">
        <Peer preview={true} peer={localPeer} />
        <AudioAndVideoControllers />
      </div>
      <div className="my-auto pb-8 col-span-1 ">
        <p className="font-semibold text-lg mb-6 text-center">
          Coaching Session
        </p>
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item
            label="What's your Email?"
            name="email"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email!",
              },
              {
                required: true,
                message: "Email is required!",
              },
            ]}
          >
            <Input placeholder="Enter your email." />
          </Form.Item>
          <div className="flex justify-center">
            <Form.Item>
              <Button loading={isLoading} type="primary" htmlType="submit">
                Join Session
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
}
