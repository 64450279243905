import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getLearnerBookedSlots = createAsyncThunk(
  "viewLearnerSlots/getLearnerBookedSlots",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/get-learner-slot/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAvailableSlots = createAsyncThunk(
  "viewLearnerSlots/getAvailableSlots",
  async (details, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/get-available-learner-slots`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: details,
      });
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const confirmLearnerSlot = createAsyncThunk(
  "viewLearnerSlots/confirmLearnerSlot",
  async (
    { learnerSlotDetails, getLearnerBookedSlots },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/pick-learner-slot`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: learnerSlotDetails,
      });
      if (response.status === 200) {
        dispatch(getLearnerBookedSlots());
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteLearnerSlot = createAsyncThunk(
  "viewLearnerSlots/deleteLearnerSlot",
  async ({ id, getLearnerBookedSlots }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_BASE_URL}/delete-learner-time-day-slot/${id}/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        dispatch(getLearnerBookedSlots());
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// TODO: will be updated
export const deleteLearnerBookedSlot = createAsyncThunk(
  "manageLearners/deleteLearnerBookedSlot",
  async ({ id, getLearnerBookedSlots }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_BASE_URL}/delete-time-day-slot/${id}/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        dispatch(getLearnerBookedSlots());
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
