import React, { useEffect, useState } from "react";
import { Select, Form, Button, Tooltip } from "antd";
import moment from "moment";
import { usePostApi } from "../../hooks/usePostApi";
import { Calendar } from "react-multi-date-picker";

const generateTimeSlots = (date) => {
  const startTime = moment(`${date} 09:00 AM`, "YYYY-MM-DD hh:mm A");
  const endTime = moment(`${date} 09:00 PM`, "YYYY-MM-DD hh:mm A");
  const timeSlots = [];

  while (startTime.isBefore(endTime) || startTime.isSame(endTime)) {
    timeSlots.push({
      value: startTime.valueOf(),
      label: startTime.format("hh:mm A"),
    });
    startTime.add(30, "minutes");
  }

  return timeSlots;
};

export const CoachingSessionDates = ({
  form: requestInfoForm,
  closeModal,
  setActiveTab,
}) => {
  const {
    data: dataRequestInfo,
    // error: errorRequestInfo,
    postData: postDataRequestInfo,
    isLoading: isLoadingRequestInfo,
    // resetState: resetStateRequestInfo,
  } = usePostApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/create-coach-availibilty/`
  );
  const [values, setValues] = useState([]);

  const [slots, setSlots] = useState({});
  const handleDateSelect = (date) => {
    for (const element of date) {
      setSlots((prev) => {
        const startTime = moment(
          `${element.format("YYYY-MM-DD")} 09:00 AM`,
          "YYYY-MM-DD hh:mm A"
        );
        const endTime = moment(
          `${element.format("YYYY-MM-DD")} 09:00 PM`,
          "YYYY-MM-DD hh:mm A"
        );

        return {
          ...prev,
          [element.format("YYYY-MM-DD")]: [
            {
              start_time: startTime.valueOf(),
              end_time: endTime.valueOf(),
            },
          ],
        };
      });
    }
  };

  const requestInfoValues = requestInfoForm.getFieldsValue();

  const disableRequestInfo =
    requestInfoValues.requestExpiry === undefined ||
    requestInfoValues.requestName === undefined ||
    requestInfoValues.selectedCoach === undefined;

  let selectedDatesLength = Object.keys(slots).length;

  const handleStartTimeChange = (value, date) => {
    setSlots((prev) => ({
      ...prev,
      [date]: [{ end_time: prev[date][0]["end_time"], start_time: value }],
    }));
  };

  const handleEndTimeChange = (value, date) => {
    setSlots((prev) => ({
      ...prev,
      [date]: [{ start_time: prev[date][0]["start_time"], end_time: value }],
    }));
  };

  const clearTimeSlots = () => {
    setValues([]); // Clear the selected dates
    setSlots({}); // Clear the time slots for the selected dates
  };

  const handleDateDeselect = (date) => {
    setSlots((prev) => {
      const updatedDates = { ...prev };
      delete updatedDates[date.format("YYYY-MM-DD")];
      return updatedDates;
    });
  };

  const handleOnFinish = (value) => {
    const requestInfoValues = requestInfoForm.getFieldsValue();

    const payload = {
      request_name: requestInfoValues.requestName,
      expiry_date: requestInfoValues.requestExpiry.format("YYYY-MM-DD"),
      coach: requestInfoValues.selectedCoach,
      availability: slots,
    };
    postDataRequestInfo(payload);
  };

  useEffect(() => {
    if (dataRequestInfo) {
      setActiveTab(null);
      closeModal();
    }
  }, [dataRequestInfo, closeModal, setActiveTab]);

  return (
    <Form onFinish={handleOnFinish}>
      <div className="flex gap-4">
        <Calendar
          multiple
          value={values}
          minDate={new Date()}
          onChange={(date) => {
            let newDate = date.sort((a, b) => {
              let newA = new Date(a).getTime();
              let newB = new Date(b).getTime();
              if (newA < newB) {
                return -1;
              }
              if (newA > newB) {
                return 1;
              }
              return 0;
            });
            handleDateSelect(newDate);
            setValues(newDate);
            const deselectedDates = values.filter((d) => !newDate.includes(d));
            deselectedDates.forEach((deselectedDate) => {
              handleDateDeselect(deselectedDate);
            });
          }}
        />
        <div>
          {selectedDatesLength > 0 ? (
            <div>
              <Button className="mb-4" type="text" onClick={clearTimeSlots}>
                Refresh
              </Button>
              {Object.keys(slots).map((date) => (
                <div key={date}>
                  <label>Selected Date: {date}</label>
                  <div className="flex gap-4">
                    {slots[date].map((interval) => {
                      const timeSlots = generateTimeSlots(date);
                      return (
                        <>
                          <div>
                            <Select
                              value={interval.start_time}
                              onChange={(value) =>
                                handleStartTimeChange(value, date)
                              }
                              placeholder="Select time"
                              options={timeSlots}
                            ></Select>
                          </div>
                          <div>
                            <Select
                              value={interval.end_time}
                              onChange={(value) =>
                                handleEndTimeChange(value, date)
                              }
                              placeholder="Select time"
                              options={timeSlots}
                            ></Select>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              <strong>No Coaching Session Dates selected</strong>
              <p>
                Please select at least 1 coaching session date from the calendar
              </p>
            </div>
          )}
        </div>
      </div>
      <div class="flex justify-end space-x-2">
        <Form.Item>
          <Tooltip
            title={
              (disableRequestInfo ? disableRequestInfo : "")
                ? "Fill the data of request info."
                : "" || selectedDatesLength === 0
                ? "Select date"
                : ""
            }
          >
            <Button
              loading={isLoadingRequestInfo}
              // disabled={selectedDatesLength === 0}
              disabled={
                disableRequestInfo
                  ? disableRequestInfo
                  : "" || selectedDatesLength === 0
              }
              type="primary"
              htmlType="submit"
            >
              OK
            </Button>
          </Tooltip>
        </Form.Item>
        <Form.Item>
          <Button onClick={() => closeModal()}>Cancel</Button>
        </Form.Item>
      </div>
    </Form>
  );
};
