import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const setCoachingSessionDetails = createAsyncThunk(
  "coachingSessionSlotBooking/setCoachingSessionDetails",
  async (sessionDetails, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/add-session/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: sessionDetails,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCoachingSessionDetails = createAsyncThunk(
  "coachingSessionSlotBooking/getCoachingSessionDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/session/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
