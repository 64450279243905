export const VALIDATE_TRIGGERS = ["onChange", "onBlur"];

export const DOMAIN = [
  {
    label: "Information Technology",
    value: "Information Technology",
  },
  {
    label: "Sales",
    value: "Sales",
  },
  {
    label: "Operations",
    value: "Operations",
  },
  {
    label: "Marketing",
    value: "Marketing",
  },
  {
    label: "Finance",
    value: "Finance",
  },
  {
    label: "Customer Service",
    value: "Customer Service",
  },
  {
    label: "Engineering",
    value: "Engineering",
  },
  {
    label: "Logistics",
    value: "Logistics",
  },
  {
    label: "Analytics",
    value: "Analytics",
  },
  {
    label: "R&D",
    value: "R&D",
  },
];

export const EXPERTISE = [
  {
    label: "Information Technology",
    value: "Information Technology",
  },
  {
    label: "Manufacturing Services",
    value: "Manufacturing Services",
  },
  {
    label: "Banking Financial Service Industry [BFSI]",
    value: "Banking Financial Service Industry [BFSI]",
  },
  {
    label: "Pharmaceutical",
    value: "Pharmaceutical",
  },
  {
    label: "Healthcare",
    value: "Healthcare",
  },
  {
    label: "Retail & E-Commerce",
    value: "Retail & E-Commerce",
  },
  {
    label: "Chemical Industry",
    value: "Chemical Industry",
  },
  {
    label: "Start Up",
    value: "Start Up",
  },
  {
    label: "Fast Moving Consumer Goods[FMCG]",
    value: "Fast Moving Consumer Goods[FMCG]",
  },
  {
    label: "Agriculture and Allied Industries",
    value: "Agriculture and Allied Industries",
  },
  {
    label: "Automobiles",
    value: "Automobiles",
  },
  {
    label: "Aviation",
    value: "Aviation",
  },
  {
    label: "Biotechnology",
    value: "Biotechnology",
  },
  {
    label: "Cement Industry",
    value: "Cement Industry",
  },
  {
    label: "Consumer Durables",
    value: "Consumer Durables",
  },
  {
    label: "Defence Manufacturing",
    value: "Defence Manufacturing",
  },
  {
    label: "E-Commerce",
    value: "E-Commerce",
  },
  {
    label: "Education And Training",
    value: "Education And Training",
  },
  {
    label: "Electronics System Design & Manufacturing",
    value: "Electronics System Design & Manufacturing",
  },
  {
    label: "Engineering and Capital Goods",
    value: "Engineering and Capital Goods",
  },
  {
    label: "Financial Services",
    value: "Financial Services",
  },
  {
    label: "Gems and Jewellery",
    value: "Gems and Jewellery",
  },
  {
    label: "Infrastructure",
    value: "Infrastructure",
  },
  {
    label: "Insurance",
    value: "Insurance",
  },
  {
    label: "IT & BPM",
    value: "IT & BPM",
  },
  {
    label: "Manufacturing",
    value: "Manufacturing",
  },
  {
    label: "Media and Entertainment",
    value: "Media and Entertainment",
  },
  {
    label: "Medical Devices",
    value: "Medical Devices",
  },
  {
    label: "Metals and Mining",
    value: "Metals and Mining",
  },
  {
    label: "Oil and Gas",
    value: "Oil and Gas",
  },
  {
    label: "Ports",
    value: "Ports",
  },
  {
    label: "Power",
    value: "Power",
  },
  {
    label: "Railways",
    value: "Railways",
  },
  {
    label: "Real Estate",
    value: "Real Estate",
  },
  {
    label: "Renewable Energy",
    value: "Renewable Energy",
  },
  {
    label: "Science And Technology",
    value: "Science And Technology",
  },
  {
    label: "Retail",
    value: "Retail",
  },
  {
    label: "Roads",
    value: "Roads",
  },
  {
    label: "Services",
    value: "Services",
  },
  {
    label: "Steel",
    value: "Steel",
  },
  {
    label: "Telecommunication",
    value: "Telecommunication",
  },
  {
    label: "Textiles",
    value: "Textiles",
  },
  {
    label: "Tourism and Hospitality",
    value: "Tourism and Hospitality",
  },
];

export const ACTIVE_INACTIVE = [
  {
    label: "Active",
    value: true,
  },
  {
    label: "Inactive",
    value: false,
  },
];

export const CTT = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const INTERNAL_COACH = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const EDUCATION = [
  {
    label: "Indian Institute Of Technology, Bombay",
    value: "Indian Institute Of Technology, Bombay",
  },
  {
    label: "Indian Institute of Science (IISc) Bangalore",
    value: "Indian Institute of Science (IISc) Bangalore",
  },
  {
    label: "All India Institute of Medical Sciences (AIIMS) Delhi",
    value: "All India Institute of Medical Sciences (AIIMS) Delhi",
  },
  {
    label: "Jawaharlal Nehru University (JNU) Delhi",
    value: "Jawaharlal Nehru University (JNU) Delhi",
  },
  {
    label: "Indian Institute of Management (IIM) Ahmedabad",
    value: "Indian Institute of Management (IIM) Ahmedabad",
  },
  {
    label: "National Law School of India University (NLSIU) Bangalore",
    value: "National Law School of India University (NLSIU) Bangalore",
  },
  {
    label: "Indian Statistical Institute (ISI) Kolkata",
    value: "Indian Statistical Institute (ISI) Kolkata",
  },
  {
    label: "St. Stephen's College Delhi",
    value: "St. Stephen's College Delhi",
  },
  {
    label: "National Institute of Design (NID) Ahmedabad",
    value: "National Institute of Design (NID) Ahmedabad",
  },
  {
    label: "Indian Institute of Fashion Technology (IIFT) Delhi",
    value: "Indian Institute of Fashion Technology (IIFT) Delhi",
  },
];

export const LANGUAGE = [
  {
    label: "English",
    value: "English",
  },
  {
    label: "Hindi",
    value: "Hindi",
  },
  {
    label: "Gujrati",
    value: "Gujrati",
  },
  {
    label: "Kannada",
    value: "Kannada",
  },
  {
    label: "Marathi",
    value: "Marathi",
  },
  {
    label: "Telugu",
    value: "Telugu",
  },
  {
    label: "Malay",
    value: "Malay",
  },
  {
    label: "Tamil",
    value: "Tamil",
  },
  {
    label: "Malayalam",
    value: "Malayalam",
  },
  {
    label: "French",
    value: "French",
  },
  {
    label: "Bengali",
    value: "Bengali",
  },
  {
    label: "Maithili",
    value: "Maithili",
  },
  {
    label: "Thamizh",
    value: "Thamizh",
  },
];

export const JOB_ROLES = [
  {
    label: "Business Operational",
    value: "Business Operational",
  },
  {
    label: "Executive",
    value: "Executive",
  },
  {
    label: "Human Resource Manager",
    value: "Human Resource Manager",
  },
  {
    label: "Chief Operating Officer(COO)",
    value: "Chief Operating Officer(COO)",
  },
  {
    label: "Managing Director ",
    value: "Managing Director ",
  },
  {
    label: "President",
    value: "President",
  },
  {
    label: "Engineer",
    value: "Engineer",
  },
  {
    label: "Product Manager",
    value: "Product Manager",
  },
  {
    label: "Finance Manager",
    value: "Finance Manager",
  },
  {
    label: "Sales and Marketing",
    value: "Sales and Marketing",
  },
  {
    label: "Accountant",
    value: "Accountant",
  },
  {
    label: "CDO",
    value: "CDO",
  },
  {
    label: "CEO",
    value: "CEO",
  },
  {
    label: "Senior Manager",
    value: "Senior Manager",
  },
  {
    label: "L&D Manager",
    value: "L&D Manager",
  },
  {
    label: "Head HR",
    value: "Head HR",
  },
  {
    label: "Director",
    value: "Director",
  },
  {
    label: "VP",
    value: "VP",
  },
  {
    label: "CTO",
    value: "CTO",
  },
  {
    label: "Deputy Manager",
    value: "Deputy Manager",
  },
  {
    label: "Trainer Consultant",
    value: "Trainer Consultant",
  },
  {
    label: "Faculty",
    value: "Faculty",
  },
  {
    label: "R&D Manager",
    value: "R&D Manager",
  },
  {
    label: "General Manager",
    value: "General Manager",
  },
  {
    label: "Chief Financial Officer",
    value: "Chief Financial Officer",
  },
  {
    label: "Plant Manager",
    value: "Plant Manager",
  },
  {
    label: "Project Manager",
    value: "Project Manager",
  },
  {
    label: "Training Lead",
    value: "Training Lead",
  },
  {
    label: "Marketing Manager",
    value: "Marketing Manager",
  },
  {
    label: "Senior VP",
    value: "Senior VP",
  },
  {
    label: "Customer Service Respresentative",
    value: "Customer Service Respresentative",
  },
  {
    label: "Head Of Finance",
    value: "Head Of Finance",
  },
  {
    label: "Consultant",
    value: "Consultant",
  },
  {
    label: "CXO",
    value: "CXO",
  },
  {
    label: "Project Lead",
    value: "Project Lead",
  },
  {
    label: "Program Managers",
    value: "Program Managers",
  },
  {
    label: "Teacher's",
    value: "Teacher's",
  },
  {
    label: "Country Head-Training",
    value: "Country Head-Training",
  },
  {
    label: "Head-Emerging Business",
    value: "Head-Emerging Business",
  },
  {
    label: "Financial Roles(P&L Responsibilty)",
    value: "Financial Roles(P&L Responsibilty)",
  },
  {
    label: "Chief Human Resource Officer",
    value: "Chief Human Resource Officer",
  },
  {
    label: "Coach",
    value: "Coach",
  },
  {
    label: "Psychotherapist",
    value: "Psychotherapist",
  },
  {
    label: "Global Lead for consulting projects",
    value: "Global Lead for consulting projects",
  },
  {
    label: "Branch Manager",
    value: "Branch Manager",
  },
  {
    label: "Partner Associate",
    value: "Partner Associate",
  },
  {
    label: "Executive Director",
    value: "Executive Director",
  },
  {
    label: "Founder & Managing Director",
    value: "Founder & Managing Director",
  },
  {
    label: "Specialist Consultant",
    value: "Specialist Consultant",
  },
  {
    label: "Managing Partner",
    value: "Managing Partner",
  },
  {
    label: "Business Development",
    value: "Business Development",
  },
  {
    label: "National Sales",
    value: "National Sales",
  },
  {
    label: "HR & Training",
    value: "HR & Training",
  },
  {
    label: "Head Of Strategy & Marketing",
    value: "Head Of Strategy & Marketing",
  },
  {
    label: "Business Manager",
    value: "Business Manager",
  },
  {
    label: "Delivery Manager",
    value: "Delivery Manager",
  },
  {
    label: "Leaadership positions across Asia & Erupoe",
    value: "Leaadership positions across Asia & Erupoe",
  },
  {
    label: "Operational Sales",
    value: "Operational Sales",
  },
  {
    label: "Training",
    value: "Training",
  },
  {
    label: "Mentoring and Coaching",
    value: "Mentoring and Coaching",
  },
  {
    label: "PSD Manager",
    value: "PSD Manager",
  },
  {
    label: "Regional Champion",
    value: "Regional Champion",
  },
  {
    label: "Internal Coach and Mentor",
    value: "Internal Coach and Mentor",
  },
  {
    label: "Leading Production",
    value: "Leading Production",
  },
  {
    label: "Senior Leadership",
    value: "Senior Leadership",
  },
  {
    label: "DIR",
    value: "DIR",
  },
  {
    label: "GM",
    value: "GM",
  },
  {
    label: "Operations Manager",
    value: "Operations Manager",
  },
  {
    label: "Technical Assistant",
    value: "Technical Assistant",
  },
  {
    label: "Chief Of Staff",
    value: "Chief Of Staff",
  },
  {
    label: "Myriad Roles",
    value: "Myriad Roles",
  },
  {
    label: "Chief People Officer",
    value: "Chief People Officer",
  },
  {
    label: "Board Member",
    value: "Board Member",
  },
  {
    label: "Functional Analyst",
    value: "Functional Analyst",
  },
  {
    label: "Executive Search Leader",
    value: "Executive Search Leader",
  },
  {
    label: "Co-Founder",
    value: "Co-Founder",
  },
  {
    label: "HR OD Consultant",
    value: "HR OD Consultant",
  },
  {
    label: "Execuitve Reatil Manager",
    value: "Execuitve Reatil Manager",
  },
  {
    label: "Head Coach Rugby Union",
    value: "Head Coach Rugby Union",
  },
  {
    label: "Doctor",
    value: "Doctor",
  },
  {
    label: "Head Of Labour Realtions",
    value: "Head Of Labour Realtions",
  },
  {
    label: "Head Of Compansation & Benefits",
    value: "Head Of Compansation & Benefits",
  },
  {
    label: "Chief Business Facilitator ",
    value: "Chief Business Facilitator ",
  },
  {
    label: "Talent Management Head",
    value: "Talent Management Head",
  },
];

export const LEVEL_OPTIONS = [
  {
    label: "ACC",
    value: "ACC",
  },
  {
    label: "PCC",
    value: "PCC",
  },
  {
    label: "MCC",
    value: "MCC",
  },
  {
    label: "Not Trained",
    value: "Not Trained",
  },
  {
    label: "Trained-No Credentials",
    value: "Trained-No Credentials",
  },
];

export const FACILITATOR_LEVEL = [
  {
    label: "Junior",
    value: "Junior",
  },
  {
    label: "Mid Level",
    value: "Mid Level",
  },
  {
    label: "Senior",
    value: "Senior",
  },
  {
    label: "CXOs",
    value: "CXOs",
  },
];

export const EXPERIENCE_LEVEL = [
  {
    label: "None",
    value: "None",
  },
  {
    label: "0-5",
    value: "0-5",
  },
  {
    label: "5-10",
    value: "5-10",
  },
  {
    label: "10-15",
    value: "10-15",
  },
  {
    label: "15-20",
    value: "15-20",
  },
  {
    label: "20-30",
    value: "20-30",
  },
  {
    label: "30-40",
    value: "30-40",
  },
  {
    label: "40+",
    value: "40+",
  },
];

export const GENDER = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const CURRENCY_LIST = [
  {
    label: "(INR) Indian Rupees",
    value: "(INR) Indian Rupees",
  },
  {
    label: "(USD) U.S. Dollar",
    value: "(USD) U.S. Dollar",
  },
  {
    label: "(EUR) European Euro",
    value: "(EUR) European Euro",
  },
  {
    label: "(JPY) Japanese Yen",
    value: "(JPY) Japanese Yen",
  },
  {
    label: "(GBP) British Pound",
    value: "(GBP) British Pound",
  },
  {
    label: "(CHF) Swiss Franc",
    value: "(CHF) Swiss Franc",
  },
  {
    label: "(CAD) Canadian Dollar",
    value: "(CAD) Canadian Dollar",
  },
  {
    label: "(AUD) Australian Dollar",
    value: "(AUD) Australian Dollar",
  },
  {
    label: "(ZAR) South African",
    value: "(ZAR) South African",
  },
];

export const COACHES_STATUS = [
  "Consent Sent",
  "Consent Rejected",
  "Consent Approved",
  "HR Rejected",
  "HR Selected",
  "Contract Sent",
  "Contract Approved",
  "Learner Rejected",
  "Learner Selected",
];

export const LOCATION = [
  {
    label: "New Delhi",
    value: "New Delhi",
  },
  {
    label: "Mumbai",
    value: "Mumbai",
  },
  {
    label: "Pune",
    value: "Pune",
  },
  {
    label: "Bangalore",
    value: "Bangalore",
  },
  {
    label: "Gurgaon",
    value: "Gurgaon",
  },
  {
    label: "Coimbatore",
    value: "Coimbatore",
  },
  {
    label: "Kolkata",
    value: "Kolkata",
  },
  {
    label: "Chennai",
    value: "Chennai",
  },
  {
    label: "Singapore",
    value: "Singapore",
  },
  {
    label: "Malaysia",
    value: "Malaysia",
  },
  {
    label: "Delhi",
    value: "Delhi",
  },
  {
    label: "Australia",
    value: "Australia",
  },
  {
    label: "Hyderabad",
    value: "Hyderabad",
  },
  {
    label: "Dehradun",
    value: "Dehradun",
  },
  {
    label: "Jaipur",
    value: "Jaipur",
  },
  {
    label: "Vishakapatnam",
    value: "Vishakapatnam",
  },
  {
    label: "Thailand",
    value: "Thailand",
  },
  {
    label: "Gurugram",
    value: "Gurugram",
  },
  {
    label: "Surat",
    value: "Surat",
  },
  {
    label: "Ibadan, Nigeria",
    value: "Ibadan, Nigeria",
  },
  {
    label: "Kuala Lumpur, Malaysia",
    value: "Kuala Lumpur, Malaysia",
  },
  {
    label: "Dubai",
    value: "Dubai",
  },
  {
    label: "Sri Lanka",
    value: "Sri Lanka",
  },
  {
    label: "Lagos, Nigeria",
    value: "Lagos, Nigeria",
  },
  {
    label: "Hong Kong",
    value: "Hong Kong",
  },
  {
    label: "Fluid (Bangalore, Americas)",
    value: "Fluid (Bangalore, Americas)",
  },
  {
    label: "Toronto, Canada",
    value: "Toronto, Canada",
  },
  {
    label: "Bangkok",
    value: "Bangkok",
  },
  {
    label: "Perth, Australia",
    value: "Perth, Australia",
  },
  {
    label: "USA",
    value: "USA",
  },
  {
    label: "India",
    value: "India",
  },
  {
    label: "Malawi, Africa",
    value: "Malawi, Africa",
  },
  {
    label: "Brunei Darassulam",
    value: "Brunei Darassulam",
  },
  {
    label: "Ghaziabad",
    value: "Ghaziabad",
  },
  {
    label: "London",
    value: "London",
  },
  {
    label: "Pondicherry",
    value: "Pondicherry",
  },
  {
    label: "Indore, MP",
    value: "Indore, MP",
  },
  {
    label: "Philadelphia, USA",
    value: "Philadelphia, USA",
  },
  {
    label: "Pakistan",
    value: "Pakistan",
  },
  {
    label: "Goa",
    value: "Goa",
  },
  {
    label: "Trivandrum",
    value: "Trivandrum",
  },
  {
    label: "Na",
    value: "Na",
  },
];

export const COACHING_HOURS = [
  {
    label: "None",
    value: "None",
  },
  {
    label: "000-500",
    value: "000-500",
  },
  {
    label: "501-1000",
    value: "501-1000",
  },
  {
    label: "1001-2000",
    value: "1001-2000",
  },
  {
    label: "2001-3000",
    value: "2001-3000",
  },
  {
    label: "3001-4000",
    value: "3001-4000",
  },
  {
    label: "3001-4000",
    value: "3001-4000",
  },
  {
    label: "4001-5000",
    value: "4001-5000",
  },
  {
    label: "5001-7500",
    value: "5001-7500",
  },
  {
    label: "7501-10000",
    value: "7501-10000",
  },
  {
    label: "10000+",
    value: "10000+",
  },
];

export const COACHES_SAMPLE_DATA = [
  {
    coach_id: "000001",
    first_name: "John",
    last_name: "Doe",
    age: "32",
    gender: "Male",
    level: "ACC",
    min_fees: "1000",
    ctt_nctt: true,
    active_inactive: true,
    years_of_corporate_experience: "0-5",
    years_of_coaching_experience: "5-10",
    domain: "Sales",
    email: "johndoe@example.com",
    phone: "123-456-7890",
    job_roles: ["Chief Operating Officer(COO)", "Sr. Managing Director"],
    companies_worked_in: ["Lupin", "Accenture", "TCS", "Integrace Health"],
    language: ["English", "Hindi"],
    area_of_expertise: ["Engineering", "Retail", "Delivery"],
    location: ["New Delhi"],
    linkedin_profile_link: "https://www.linkedin.com/in/xyz/",
    coaching_hours: "000-500",
    fee_remark: "This is the sample remark",
    client_companies: "Sample Company",
    educational_qualification: "Sample Qualification",
    corporate_experience: "Sample Corporate Experience",
    coaching_experience: "Sample Coaching Experience",
    education: "Sanple Education Institution",
  },
];

export const COACHES_SAMPLE_DATA_FACILITATOR = [
  {
    first_name: "John",
    last_name: "Doe",
    age: "32",
    gender: "Male",
    level: ["Junior", "Senior"],
    years_of_corporate_experience: "0-5",
    domain: ["IT", "Sales"],
    email: "johndoe@example.com",
    phone: "123-456-7890",
    job_roles: ["Chief Operating Officer(COO)", "Sr. Managing Director"],
    companies_worked_in: ["Lupin", "Accenture", "TCS", "Integrace Health"],
    language: ["English", "Hindi"],
    area_of_expertise: ["Engineering", "Retail", "Delivery"],
    city: ["City1", "City2"],
    country: ["Country1", "Country2"],
    topic: ["Topic1", "Topic2"],
    linkedin_profile_link: "https://www.linkedin.com/in/xyz/",
    client_companies: "Sample Company",
    educational_qualification: "Sample Qualification",
    education: "Sample Education Institution",
    fees_per_hour: "1000",
    fees_per_day: "10000",
  },
];

export const SESSION_TYPE_VALUE = {
  chemistry: "Chemistry",
  tripartite: "Tripartite",
  goal_setting: "Goal Setting",
  coaching_session: "Coaching Session",
  mid_review: "Mid Review",
  end_review: "End Review",
  closure_session: "Closure Session",
  stakeholder_without_coach: "Tripartite Without Coach",
  interview: "Interview",
};

export const SCORES = {
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10",
};

export const STATUS_OPTIONS = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "archive",
    label: "Pause",
  },
  {
    value: "complete",
    label: "Complete",
  },
];

export const ACTION_STATUS_OPTIONS = [
  {
    value: "not_done",
    label: "Not done",
  },
  {
    value: "partially_done",
    label: "Partially done",
  },
  {
    value: "done",
    label: "Done",
  },
];

export const SESSIONS_WITH_STAKEHOLDERS = [
  "tripartite",
  "mid_review",
  "end_review",
  "stakeholder_without_coach",
];

export const SESSION_TYPE = [
  {
    label: "Live Session",
    value: "live_session",
  },
  {
    label: "Laser Coaching Session",
    value: "laser_coaching_session",
  },
];

export const SESSION_TYPE_SCHEDULAR_VALUE = {
  live_session: "Live Session",
  laser_coaching_session: "Laser Coaching Session",
};

export const SESSION_DURATION_OPTIONS = [
  { label: "30 mins", value: "30" },
  { label: "1 hour", value: "60" },
  { label: "1 hour 30 mins", value: "90" },
  { label: "2 hours", value: "120" },
  { label: "2 hours 30 mins", value: "150" },
  { label: "3 hours", value: "180" },
  { label: "3 hours 30 mins", value: "210" },
  { label: "4 hours", value: "240" },
  { label: "4 hours 30 mins", value: "270" },
  { label: "5 hours", value: "300" },
];

export const SESSION_STATUS_OPTIONS = [
  { label: "Coachee No Show", value: "coachee_no_show" },
  { label: "Completed", value: "completed" },
];

export const SESSION_STATUS_VALUES = {
  coachee_no_show: "Coachee No Show",
  completed: "Completed",
  pending: "Pending",
};

export const DURATION_VALUES = {
  30: "30 mins",
  60: "1 hour",
  90: "1 hour 30 mins",
  120: "2 hours",
  150: "2 hours 30 mins",
  180: "3 hours",
  210: "3 hours 30 mins",
  240: "4 hours",
  270: "4 hours 30 mins",
  300: "5 hours",
};
