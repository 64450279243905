import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const setCoachingSessionDates = createAsyncThunk(
  "requestSessions/setCoachingSessionDates",
  async (
    { requestName, coachIds, slots, setAlert, expiryDate, getAdminRequests },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const data = {
        slots,
        coach_id: coachIds,
        request_name: requestName,
        expiry_date: expiryDate,
      };
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/make-request/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: data,
      });
      if (response.status === 200) {
        dispatch(
          setAlert({
            message: "Request for coaching session is sent successfully",
            isAlert: true,
            status: "success",
          })
        );
        dispatch(getAdminRequests());
        return response.data;
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: "Request for coaching session is failed",
          isAlert: true,
          status: "failure",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const getAdminRequests = createAsyncThunk(
  "requestSessions/getAdminRequests",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/get-admin-request/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        return response.data.Data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getConfirmedSlotsByRequestId = createAsyncThunk(
  "requestSessions/getConfirmedSlotsByRequestId",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/get-confirmed-slots-by-request/${id}/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteRequest = createAsyncThunk(
  "requestSessions/deleteRequest",
  async (
    { requestId, getAdminRequests, setAlert },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_BASE_URL}/delete-request/${requestId}/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        dispatch(
          setAlert({
            message: "Request deleted successfully!",
            isAlert: true,
            status: "success",
          })
        );
        return dispatch(getAdminRequests());
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
