import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCoachConfirmedSlots = createAsyncThunk(
  "editCoachConfirmedSlots/getCoachConfirmedSlots",
  async ({ coachId, setAlert }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/get-confirmed-slots-by-coach/${coachId}/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      dispatch(
        setAlert({
          message:
            "Failed to get confirmed slots for this particular coach! Try again.",
          isAlert: true,
          status: "failure",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const deleteCoachConfirmedSlot = createAsyncThunk(
  "editCoachConfirmedSlots/deleteCoachConfirmedSlot",
  async (
    { slotId, getCoachConfirmedSlots, coachId, setAlert },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_BASE_URL}/delete-confirmed-slots-by-coach/${coachId}/${slotId}/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        dispatch(
          setAlert({
            message: "Slot deleted successfully!",
            isAlert: true,
            status: "success",
          })
        );
        dispatch(getCoachConfirmedSlots({ coachId, setAlert }));
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: "Failed to delete the slot! Try again.",
          isAlert: true,
          status: "failure",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const updateCoachConfirmedSlot = createAsyncThunk(
  "editCoachConfirmedSlots/updateCoachConfirmedSlot",
  async (
    { slotId, getCoachConfirmedSlots, setModal, data, coachId, setAlert },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/edit-confirmed-slots/${slotId}/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: data,
      });
      if (response.status === 201) {
        setModal(false);
        dispatch(
          setAlert({
            message: "Slot updated successfully!",
            isAlert: true,
            status: "success",
          })
        );
        dispatch(getCoachConfirmedSlots({ coachId, setAlert }));
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: "Failed to edit the slot! Try again.",
          isAlert: true,
          status: "failure",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const getBookedSessions = createAsyncThunk(
  "get-booked-sessions",
  async (coachId, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/booked-slot-by-coach/${coachId}/`
      ); // returns learner's confirmed slot of a coach
      return res.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
