import moment from "moment";
export const formatSlotsDateWise = (slots) => {
  const formattedSlots = {};

  slots.forEach((slot) => {
    const startTime = new Date(+slot.start_time);
    const date = moment(startTime).format("DD-MM-YYYY");

    if (!formattedSlots[date]) {
      formattedSlots[date] = [];
    }

    formattedSlots[date].push(slot);
  });

  return formattedSlots;
};
