import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCourses = createAsyncThunk(
  "manageCourses/getCourses",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/`,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCourseCategories = createAsyncThunk(
  "manageCourses/getCourseCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/course-category/`,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addCourse = createAsyncThunk(
  "manageCourses/addCourse",
  async ({ courseDetails, getCourses }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/add-courses/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: courseDetails,
      });
      if (response.status === 200) {
        dispatch(getCourses());
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCourse = createAsyncThunk(
  "manageCourses/updateCourse",
  async ({ courseDetails, id, getCourses }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/update-courses/${id}/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: courseDetails,
      });
      if (response.status === 200) {
        dispatch(getCourses());
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBatches = createAsyncThunk(
  "manageCourses/getBatches",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/batchs/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addBatch = createAsyncThunk(
  "manageCourses/addBatch",
  async ({ batchDetails, getBatches }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/add-batchs/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: batchDetails,
      });
      if (response.status === 200) {
        dispatch(getBatches());
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateBatch = createAsyncThunk(
  "manageCourses/updateBatch",
  async ({ batchDetails, getBatches, id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/update-batch/${id}/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: batchDetails,
      });
      if (response.status === 200) {
        dispatch(getBatches());
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
