import {
  CancelOutlined,
  CheckOutlined,
  Close,
  EditOutlined,
} from "@mui/icons-material";
import { Button, Modal } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Header } from "../../../components/header/Header";
import { LoadingAndError } from "../../../components/loading-and-error/LoadingAndError";
import { PageContent } from "../../../components/page-content/PageContent";
import { useDeleteApi } from "../../../hooks/useDeleteApi";
import { useGetApi } from "../../../hooks/useGetApi";
import { formatSlotsDateWise } from "../../../utils/formatSlotsDateWise";

export const YourSlots = () => {
  const { user } = useSelector((state) => state.auth);
  const [formattedSlots, setFormattedSlots] = useState({});
  const [deleteSlotModal, setDeleteSlotModal] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [slotsToDelete, setSlotsToDelete] = useState([]);

  const {
    data: slots,
    isLoading: slotsLoading,
    error: slotsError,
    getData: getSlots,
  } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/slots/upcoming/${user.id}/`
  );

  const {
    data: deleteSlotsData,
    isLoading: deleteSlotsLoading,
    // error: deleteSlotsError,
    deleteData: deleteSlots,
  } = useDeleteApi(`${process.env.REACT_APP_BASE_URL}/schedular/slots/delete/`);

  const handleDeleteSlots = (slots) => {
    deleteSlots({ slot_ids: slots.map((item) => item.id) });
  };

  const formattedSlotsToDelete = useMemo(() => {
    if (deleteSlotModal) {
      return formatSlotsDateWise(slotsToDelete);
    }
    return {};
  }, [deleteSlotModal, slotsToDelete]);

  const handleRemove = ({ date, slot }) => {
    setFormattedSlots((prev) => {
      return {
        ...prev,
        [date]: [...prev[date].filter((item) => item.id !== slot.id)],
      };
    });
    setSlotsToDelete((prev) => [...prev, slot]);
  };

  useEffect(() => {
    if (slots) {
      return setFormattedSlots(formatSlotsDateWise(slots));
    }
  }, [slots]);

  useEffect(() => {
    if (deleteSlotsData) {
      setSlotsToDelete([]);
      setDeleteSlotModal(false);
      setIsEdit(false);
      getSlots();
    }
  }, [deleteSlotsData]);

  return (
    <>
      <Header>Your slots</Header>
      <PageContent>
        <LoadingAndError
          error={slotsError}
          data={Boolean(slots)}
          loading={slotsLoading}
        >
          {slots && !slotsLoading && (
            <>
              <div className="mb-4">
                <p className="font-semibold text-lg">All confirmed slots:</p>
                <p>
                  You can edit(delete) your slots from active request. Once the
                  slot is booked by coachee, you cannot make any changes to
                  slot. To make any other changes, please reach out to{" "}
                  <a className="text-sky-500" href="mailto:pmo@meeraq.com">
                    pmo@meeraq.com
                  </a>
                </p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg">
                <div className="flex justify-between items-center py-2">
                  <p>Slots provided by you.</p>
                  {isEdit ? (
                    <div className="flex gap-2">
                      <Button
                        type="text"
                        onClick={() => {
                          setIsEdit(false);
                          setSlotsToDelete([]);
                          setFormattedSlots(formatSlotsDateWise(slots));
                        }}
                        icon={<Close />}
                      ></Button>
                      <Button
                        type="text"
                        disabled={slotsToDelete.length === 0}
                        onClick={() => setDeleteSlotModal(true)}
                        icon={<CheckOutlined />}
                      ></Button>
                    </div>
                  ) : slots?.length > 0 ? (
                    <Button
                      type="text"
                      onClick={() => setIsEdit(true)}
                      icon={<EditOutlined />}
                    ></Button>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="bg-white p-4 rounded-lg">
                  {slots?.length > 0 ? (
                    Object.keys(formattedSlots).map((date) => {
                      return (
                        <div className="mb-8">
                          {date}
                          <div className="flex flex-wrap ">
                            {formattedSlots[date].length > 0 ? (
                              formattedSlots[date].map((slot) => {
                                return (
                                  <div
                                    key={slot.id}
                                    className={`mt-[1rem] border rounded-2xl p-1 text-center w-[12rem]  cursor-pointer mx-1 ${
                                      slot.is_confirmed ? "bg-primary-4" : ""
                                    }`}
                                  >
                                    {moment(new Date(+slot.start_time)).format(
                                      "hh:mm A"
                                    )}
                                    -
                                    {moment(new Date(+slot.end_time)).format(
                                      "hh:mm A"
                                    )}
                                    {isEdit && !slot.is_confirmed && (
                                      <Close
                                        onClick={() =>
                                          handleRemove({ date, slot })
                                        }
                                        className="!text-sm ml-4 cursor-pointer"
                                      />
                                    )}
                                  </div>
                                );
                              })
                            ) : (
                              <p className="mt-4 text-gray-400">
                                No slots left.
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p>No slots available!</p>
                  )}
                </div>
              </div>
            </>
          )}
          {deleteSlotModal && (
            <Modal
              title="Delete slots"
              open={true}
              onOk={() => handleDeleteSlots(slotsToDelete)}
              onCancel={() => {
                setDeleteSlotModal(false);
              }}
            >
              <p className="mb-4">
                Are you sure you want to delete the following slots?
              </p>
              {Object.keys(formattedSlotsToDelete).map((date) => {
                return (
                  <div className="mb-8">
                    {date}
                    <div className="flex flex-wrap ">
                      {formattedSlotsToDelete[date].length > 0 ? (
                        formattedSlotsToDelete[date].map((slot) => {
                          return (
                            <div
                              key={slot.id}
                              className={`mt-[1rem] border rounded-2xl p-1 text-center w-[12rem]  cursor-pointer mx-1`}
                            >
                              {moment(new Date(+slot.start_time)).format(
                                "hh:mm A"
                              )}
                              -
                              {moment(new Date(+slot.end_time)).format(
                                "hh:mm A"
                              )}
                            </div>
                          );
                        })
                      ) : (
                        <p className="mt-4 text-gray-400">No slots.</p>
                      )}
                    </div>
                  </div>
                );
              })}
            </Modal>
          )}
        </LoadingAndError>
      </PageContent>
    </>
  );
};
