import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Input, Table, Tag, Tooltip } from "antd"; // Import Tooltip component from Ant Design
import moment from "moment";
import { Button, Modal, List } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { Header } from "../header/Header";
import { PageContent } from "../page-content/PageContent";
import { Search } from "@mui/icons-material";

const getEmailData = (setEmailData) => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/schedular/emails-data/`)
    .then((response) => {
      setEmailData(response.data);
    })
    .catch((error) => {
      console.error("Error fetching email data:", error);
      setEmailData([]);
    });
};

const EmailData = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [emailData, setEmailData] = useState([]);
  const [modalVisible, setModalVisible] = useState(null);
  const [cancelScehduleMail, setCancelScehduleMail] = useState(null);

  const closeModal = () => {
    setModalVisible(false);
  };

  const closeCancelScheduleMail = () => {
    setCancelScehduleMail(null);
  };

  const handleCancelScheduleMail = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/schedular/cancel-schedule-email/${cancelScehduleMail.id}`
      );
      getEmailData(setEmailData);
      setCancelScehduleMail(null);
    } catch (err) {
      console.log("error", err);
    }
  };

  const columns = [
    {
      title: "Sno",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Recipients",
      dataIndex: "recipients",
      key: "recipients",
      render: (recipients) => `${Object.keys(recipients).length} recipients`,
    },
    {
      title: "Scheduled For",
      dataIndex: "scheduled_for",
      key: "scheduled_for",
      render: (scheduled_for) =>
        moment(scheduled_for).format("DD/MM/YYYY h:mm A"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color, text;
        switch (status) {
          case "cancelled":
            color = "red";
            text = "Cancelled";
            break;
          case "completed":
            color = "green";
            text = "Sent";
            break;
          case "pending":
            color = "blue";
            text = "Scheduled";
            break;
          default:
            color = "default";
            text = "Unknown";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: "Template",
      dataIndex: "template",
      key: "template",
      render: (_, rowData) =>
        rowData?.template?.title ? rowData?.template?.title : <Tag>N/A</Tag>,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (subject) => (
        <Tooltip title={subject}>
          {subject?.length <= 10 ? subject : subject?.slice(0, 10) + "..."}
        </Tooltip>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-2 flex-nowrap">
          <Tooltip title="View more details.">
            <Button
              type="text"
              icon={<EyeOutlined />}
              onClick={() => setModalVisible(record)}
            ></Button>
          </Tooltip>
          {record.status === "pending" && (
            <Button
              type="primary"
              // icon={<EyeOutlined />}
              onClick={() => setCancelScehduleMail(record)}
            >
              Cancel schedule
            </Button>
          )}
        </div>
      ),
    },
  ];

  const filteredEmailsData = useMemo(() => {
    if (emailData) {
      return emailData.filter((email) => {
        const subject = email?.subject || "";
        const template = email?.template?.title || "";
        const status =
          email?.status === "cancelled"
            ? "Cancelled"
            : email?.status === "completed"
            ? "Sent"
            : email?.status === "pending"
            ? "Scheduled"
            : "Unknown";

        return (
          subject?.toLowerCase()?.includes(searchTerm?.trim()?.toLowerCase()) ||
          template
            ?.toLowerCase()
            ?.includes(searchTerm?.trim()?.toLowerCase()) ||
          status?.toLowerCase()?.includes(searchTerm?.trim()?.toLowerCase())
        );
      });
    }
    return [];
  }, [emailData, searchTerm]);

  useEffect(() => {
    getEmailData(setEmailData);
  }, []);

  return (
    <div>
      <Header>Mails Sent</Header>
      <PageContent>
        <Input
          className="mb-4 w-auto min-w-[20rem]"
          prefix={<Search />}
          type="text"
          placeholder="Search in template, status or subject..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Table dataSource={filteredEmailsData || []} columns={columns} />
        <Modal
          title="Email Details"
          open={modalVisible}
          onCancel={closeModal}
          footer={null}
          className="w-96" // Set the width of the modal using Tailwind CSS
        >
          {modalVisible && <ViewEmailContent email={modalVisible} />}
        </Modal>
        <Modal
          title="Cancel schedule email"
          open={cancelScehduleMail}
          onCancel={closeCancelScheduleMail}
          onOk={handleCancelScheduleMail}
          className="w-96" // Set the width of the modal using Tailwind CSS
        >
          <p>
            Are you sure you want to cancel the schedule mail with subject:{" "}
            {cancelScehduleMail?.subject}?
          </p>
        </Modal>
      </PageContent>
    </div>
  );
};

export default EmailData;

function ViewEmailContent({ email }) {
  const [activeTabKey, setActiveTabKey] = useState("recipients");
  const tabs = [
    {
      key: "recipients",
      label: `Recipients`,
    },
    {
      key: "preview_content",
      label: "Content",
    },
  ];

  return (
    <>
      <div className="flex mt-2">
        {tabs.map((tab) => {
          return (
            <div
              className={`flex items-center gap-4 p-2 px-4 cursor-pointer  ${
                activeTabKey === tab.key
                  ? "bg-primary-4 border-b-2 border-primary-1"
                  : "bg-white border-b-2"
              } `}
              onClick={() => setActiveTabKey(tab.key)}
            >
              <div
                className={`${
                  activeTabKey === tab.key ? "text-primary-2" : ""
                }`}
              >
                {tab?.label}
              </div>
            </div>
          );
        })}
      </div>
      <div className="my-6">
        {activeTabKey === "recipients" ? (
          <ul className="list-disc pl-4">
            {email?.recipients?.map((item, index) => (
              <li key={item.email} className="">
                {item.name} : {item.email}
              </li>
            ))}
          </ul>
        ) : activeTabKey === "preview_content" ? (
          <>
            <div
              style={{ maxHeight: "100%", overflow: "auto" }}
              dangerouslySetInnerHTML={{
                __html: email?.template?.template_data,
              }}
            />{" "}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
