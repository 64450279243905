import { Route, Routes, useLocation } from "react-router";
import "./App.css";
import { Layout } from "antd";
import { Navbar } from "./components/navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { PmoNavigation } from "./components/navigation/PmoNavigation";
import { CoachNavigation } from "./components/navigation/CoachNavigation";
import { Sidebar } from "./components/sidebar/Sidebar";
import { PmoProjects } from "./pages/pmo/Projects/PmoProjects";
import { Login } from "./pages/login/Login";
import { Participant } from "./pages/participant/Participant";
import { AllSessions } from "./pages/sessions/AllSessions";
import { ProjectCreate } from "./pages/pmo/project-create/ProjectCreate";
import { getSession } from "./redux/services/authService";
import { useEffect } from "react";
import { InsideProject } from "./pages/pmo/inside-project/InsideProject";
import { RequestAvailability } from "./pages/pmo/request-availability/RequestAvailability";
import { CoachingSessionDates } from "./components/coaching-session-dates/CoachingSessionDates";
import { RequestInfo } from "./components/request-info/RequestInfo";
import { AuthenticatedRoutes } from "./components/authenticated-routes/AuthenticatedRoutes";
import { Templates1 } from "./components/email/Templates1";
import { Templates } from "./components/templates1/Templates";
import { PreviewPage } from "./components/preview/Preview";
import { SuccessTestPage } from "./components/success-page/SuccessTestPage";
import { FinalPreview } from "./components/succes-preview/SuccesPreview";
import { SuccessPage } from "./components/success-page/SuccessPage";
import EmailData from "./components/email-data/EmailData";
import { Navigation } from "./components/navigation/Navigation";
import { CoachRequestPage } from "./components/coach-request/CoachRequestPage";
import { ParticipantBookingSession } from "./pages/participant-booking-session/ParticipantBookingSession";
import { VideoCall } from "./pages/video-call/VideoCall";
import { HMSRoomProvider } from "@100mslive/react-sdk";
import { YourSlots } from "./pages/coach/your-slots/YourSlots";
import { Coaches } from "./pages/pmo/coaches/Coaches";
import { ForgotPassword } from "./pages/forgot-password/ForgotPassword";
import { ResetPassword } from "./pages/pmo/reset-password/ResetPassword";
import { ViewFacilitator } from "./components/facilitator/ViewFacilitator";
import { AddFacilitator } from "./components/facilitator/AddFacilitator";

function App() {
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const { pathname } = useLocation();
  const dispatch = useDispatch();

  let custom_menu;
  if (isAuthenticated) {
    if (user.user.type === "pmo") {
      custom_menu = <PmoNavigation />;
    } else if (user.user.type === "coach") {
      custom_menu = <CoachNavigation />;
    } else {
      custom_menu = <Navigation />;
    }
  }

  useEffect(() => {
    dispatch(getSession());
  }, [dispatch]);

  return (
    <div className="">
      <Layout>
        {isAuthenticated &&
          !pathname.startsWith("/e/call") &&
          !pathname.startsWith("/create-password") &&
          !pathname.startsWith("/coaching/book/") &&
          !pathname.startsWith("/coaching/join/") && (
            <Sidebar menu={custom_menu} />
          )}
        <Layout.Content className="content">
          {pathname !== "/" && <Navbar menu={custom_menu} />}
          <div
            className="relative min-h-[calc(100vh-3.56rem)]"
            style={
              isAuthenticated && !pathname.startsWith("/e/call")
                ? {}
                : pathname.startsWith("/coaching/book/") ||
                  pathname.startsWith("/coaching/join/")
                ? {}
                : {
                    // padding: "16px",
                    height: "100vh",
                    overflowY: "auto",
                  }
            }
          >
            {process.env.REACT_APP_ENVIRONMENT === "qa" && (
              <span className="bg-red-500 text-white p-2 rounded absolute w-[4rem] flex left-[50%] right-[50%]">
                Testing{" "}
              </span>
            )}
            <Routes>
              <Route index element={<Login />} />{" "}
              <Route path={`/forgot-password`} element={<ForgotPassword />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route
                path="/coaching/book/:bookingLinkId"
                element={<ParticipantBookingSession />}
              />{" "}
              <Route
                path="/coaching/join/:roomId"
                element={
                  <HMSRoomProvider>
                    <VideoCall />
                  </HMSRoomProvider>
                }
              />
              <Route element={<AuthenticatedRoutes />}>
                <Route path="/pmo/project-create" element={<ProjectCreate />} />
                <Route
                  path="/projects/progress/:id"
                  element={<InsideProject />}
                />
                <Route path={"/projects"} element={<PmoProjects />} />
                <Route path="/participants" element={<Participant />} />
                <Route path="/coaches" element={<Coaches />} />

                <Route path="/sessions" element={<AllSessions />} />
                <Route path="/templates" element={<Templates1 />} />
                <Route path="/templates1" element={<Templates />} />
                <Route path="/preview" element={<PreviewPage />} />
                <Route path="/successtestpage" element={<SuccessTestPage />} />
                <Route path="/successpreview" element={<FinalPreview />} />
                <Route path="/successpage" element={<SuccessPage />} />
                <Route path="/emailsdata" element={<EmailData />} />
                <Route
                  path="/CoachingSessionDates"
                  element={<CoachingSessionDates />}
                />
                <Route path="/RequestInfo" element={<RequestInfo />} />
                {/* <Route path="/slot-request" element={<SlotRequests />} /> */}
                <Route path="/slot-request" element={<CoachRequestPage />} />
                <Route
                  path="/request-availability"
                  element={<RequestAvailability />}
                />
                <Route path="/slots" element={<YourSlots />} />
                <Route path="/facilitator" element={<ViewFacilitator />} />
                <Route path="/add/facilitator" element={<AddFacilitator />} />
              </Route>
              <Route path="*" element={<>Page not found</>} />
            </Routes>
          </div>
        </Layout.Content>
      </Layout>
    </div>
  );
}

export default App;
