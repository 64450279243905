import moment from "moment";
import React, { useMemo, useState } from "react";
import { useGetApi } from "../hooks/useGetApi";
import { PageContent } from "../page-content/PageContent";
import { Avatar, Button, Collapse, Modal, Tag } from "antd";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { LoadingAndError } from "../loading-and-error/LoadingAndError";
import { PmoRequestContent } from "../active-requests/ActiveRequests";
import { formatDate } from "../../utils/convertSlotToString";

export const ExpiredRequests = () => {
  const {
    data,
    isLoading,
    error,
    getData: getDataRequestAvailibilty,
  } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/schedular-availabilities/`
  );

  const currentDate = moment();
  const filteredData = data?.filter((item) =>
    moment(item.expiry_date).isSameOrBefore(currentDate, "day")
  );
  

  return (
    <div>
      {filteredData?.length > 0 ? (
        filteredData?.map((item) => (
          <Collapse
            key={item.id}
            className="mb-4"
            size="large"
            items={[
              {
                key: "1",
                label: (
                  <div className="grid grid-cols-4 gap-5">
                    <div>
                      <div>Request Name:</div>
                      <div>{item?.request_name}</div>
                    </div>
                    <div>
                      <div>Assigned Coaches: </div>
                      <div>{item.coach.length}</div>
                    </div>
                    <div>
                      <div>Action by: </div>
                      <div>{item?.provided_by?.length}</div>
                    </div>
                    <div>
                      <div>Expiry Date:</div>
                      <div>{formatDate(item?.expiry_date)}</div>
                    </div>
                    {/* <div>
                      <DeleteOutlined />
                    </div>
                    <div>
                      <DownloadOutlined />
                    </div> */}
                  </div>
                ),
                children: <PmoRequestContent request={item} />,
              },
            ]}
          />
        ))
      ) : (
        <p className="mt-4 text-gray-500">No requests available</p>
      )}
    </div>
  );
};
