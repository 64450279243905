import React, { useEffect, useMemo, useState } from "react";
import { Header } from "../header/Header";
import { PageContent } from "../page-content/PageContent";
import { SlotRequests } from "../../pages/coach/slot-request/SlotRequests";
import { ActiveRequest } from "../../pages/coach/active-request/ActiveRequest";
import { useGetApi } from "../../hooks/useGetApi";
import { useSelector } from "react-redux";
import { LoadingAndError } from "../loading-and-error/LoadingAndError";
import { Badge } from "antd";

export const CoachRequestPage = () => {
  const { user } = useSelector((state) => state.auth);
  const [activeTab, setActiveTab] = useState(null);

  const {
    data: requestsData,
    isLoading: requestsLoading,
    error: requestsError,
    getData: getRequests,
  } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/schedular/requests/${user.id}/`
  );

  const tabs = useMemo(() => {
    if (requestsData && !requestsLoading) {
      return [
        {
          key: "new",
          label: `New Request`,
          requestsCount: requestsData?.new?.length || 0,
          children:
            requestsData?.new?.length > 0 ? (
              <SlotRequests
                setActiveTab={setActiveTab}
                getRequests={getRequests}
                requests={requestsData?.new}
              />
            ) : (
              <></>
            ),
        },
        {
          key: "active",
          label: `Active Request `,
          requestsCount: requestsData?.active?.length || 0,
          children: <ActiveRequest requests={requestsData?.active || []} />,
        },
      ];
    } else return [];
  }, [requestsData, getRequests, requestsLoading]);

  const currentTab = useMemo(() => {
    if (tabs && activeTab) {
      return tabs.find((item) => item.key === activeTab.key);
    }
  }, [tabs, activeTab]);

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(tabs[0]);
    }
  }, [tabs, activeTab]);

  return (
    <div>
      <Header>Slot Requests</Header>
      <PageContent>
        <LoadingAndError
          error={requestsError}
          data={Boolean(requestsData)}
          loading={requestsLoading}
        >
          {requestsData && !requestsLoading && (
            <>
              <div className="flex mb-4">
                {tabs.map((tab) => {
                  return (
                    <div
                      className={`flex items-center gap-4 p-2 px-4 cursor-pointer  ${
                        activeTab?.key === tab.key
                          ? "bg-primary-4 border-b-2 border-primary-1"
                          : "bg-white border-b-2"
                      } `}
                      onClick={() => setActiveTab(tab)}
                    >
                      <div
                        className={`${
                          activeTab?.key === tab.key ? "text-primary-2" : ""
                        }`}
                      >
                        {tab?.label}
                      </div>
                      <Badge
                        style={{
                          color: `${
                            activeTab?.key === tab.key ? "#7E39A4" : "black"
                          }`,
                        }}
                        color={`${
                          activeTab?.key === tab.key ? "#F9F0FF" : "#f4f4f4"
                        }`}
                        showZero
                        count={tab.requestsCount}
                        className="mr-2"
                      ></Badge>
                    </div>
                  );
                })}
              </div>
              {currentTab?.requestsCount > 0 ? (
                currentTab?.children
              ) : (
                <p className="mt-4">No requests available</p>
              )}
            </>
          )}
        </LoadingAndError>
      </PageContent>
    </div>
  );
};
