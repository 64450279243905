import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCoachSlots = createAsyncThunk(
  "viewCoachSlots/getCoachSlots",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/time-day-slot/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addCoachSlot = createAsyncThunk(
  "viewCoachSlots/addCoachSlot",
  async (
    { coachSlotDetails, getCoachSlots },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/confirm-day-time-slot/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
        data: coachSlotDetails,
      });
      if (response.status === 200) {
        dispatch(getCoachSlots());
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCoachSlot = createAsyncThunk(
  "manageLearners/deleteCoachSlot",
  async ({ id, getCoachSlots }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_BASE_URL}/delete-time-day-slot/${id}/`,
        headers: { authorization: `Token ${sessionStorage.getItem("token")}` },
      });
      if (response.status === 200) {
        dispatch(getCoachSlots());
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
